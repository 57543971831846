import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
  Button as MaterialButton,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
} from 'react-router-dom';
import eventsAPI, { Event, EventDetails } from '../../api/events';
import { Role } from '../../api/user/Role';
import {
  Button,
  ConfirmButton,
  PageLoader,
  RouteACL,
  SnackMessage,
  TextField,
} from '../../components/common';
import AddToCalendarButton from '../../components/common/add-to-calendar-button';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import AcceptanceToggle from '../../components/sessions/acceptance-toggle';
import PageTabs from '../../components/sessions/page-tabs';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import {
  SessionContext,
  SessionProvider,
} from '../../contexts/session-context';
import { UserContext } from '../../contexts/user-context';
import { ProtectedRoute } from '../../router';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { isUrl } from '../../utils/String/Url';
import { BR_TEXT, CANCELLATION_REASON } from '../../utils/form';
import { checkDynamicPath, isMobile } from '../../utils/functions';
import SessionAgendaRequestPage from './session-agenda-request-page';
import SessionDetailsPage from './session-details-page';
import SessionFounderNotesPage from './session-founder-notes-page';
import SessionLeadMentorPage from './session-lead-mentor-page';
import SessionLinksPage from './session-links-page';
import SessionLogPage from './session-log-page';
import SessionMentorAssessmentPage from './session-mentor-assessment-page';
import SessionNotesPage from './session-notes-page';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  contentWrapper: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  content: {
    maxWidth: '100%',
    flexGrow: 1,
    padding: 40,
    boxSizing: 'border-box',
  },
  inputDescription: {
    marginTop: 15,
  },
});

const getEventDetails = (
  status: string,
  session: Event,
  reason?: string,
): EventDetails => {
  let currentDescription;
  if (reason) {
    currentDescription = `${CANCELLATION_REASON}${reason}${BR_TEXT}${session?.description}`;
  } else {
    currentDescription = session.description;
  }
  return {
    description: currentDescription,
    end: session.end,
    followupReminder: session?.followupReminder,
    id: session.id,
    start: session.start,
    status: status,
    summary: session.summary,
    tenantEventChannelId: session.tenantEventChannelId,
    ventureId: session.ventureId,
    ventureName: session.ventureName,
    emailList: session.emailList,
    physicalLocation: session.physicalLocation,
  };
};

function SessionPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const { sessionId } = useParams<{ sessionId: Event['id'] }>();
  const location = useLocation<{ prevPath?: string }>();
  const { rb } = useResourceBundles();
  const [prevPath] = useState(location.state?.prevPath);
  const { enqueueSnackbar } = useSnackbar();
  const {
    session,
    scheduled,
    isSessionLoading,
    sessionIssues,
    loadSession,
    replaceSession,
    setIsCancelEvent,
    loadSessionIssues,
    founders,
    mentors,
  } = useContext(SessionContext);
  const [isInviting, setIsInviting] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const { tokenData, channels, hasAccessToAction, hasRole } =
    useContext(UserContext);
  const [reason, setReason] = useState('');
  const history = useHistory();

  const assignmentStatus = useMemo(() => {
    if (hasRole(Role.Founder)) {
      return (
        founders?.find((f) => f.id === tokenData?.identityid)
          ?.assignmentStatus || null
      );
    } else if (hasRole(Role.Mentor)) {
      return (
        mentors?.find((m) => m.id === tokenData?.identityid)
          ?.assignmentStatus || null
      );
    } else {
      return null;
    }
  }, [founders, hasRole, mentors, tokenData?.identityid]);

  const isMentorNotParticipant = useMemo(() => {
    const isMentor = hasRole(Role.Mentor);

    if (!isMentor) {
      return false;
    }

    const identityId = tokenData?.identityid;

    if (!identityId) {
      return true;
    }

    return mentors.every((mentor) => mentor.id !== identityId);
  }, [hasRole, mentors, tokenData?.identityid]);

  const { showJoinButton, showInviteAllButton } = useMemo(() => {
    const hasAccessJoin = hasAccessToAction('session.details.join');
    const hasAccessInvite = hasAccessToAction('session.details.inviteAll');
    const isMentor = hasRole(Role.Mentor);
    const isCreated = session?.status === 'CREATED';
    return {
      showJoinButton: isMentor ? hasAccessJoin && !isCreated : hasAccessJoin,
      showInviteAllButton: isMentor
        ? hasAccessInvite && isCreated
        : hasAccessInvite,
    };
  }, [hasAccessToAction, hasRole, session]);

  const linkActions = useMemo(
    () => [
      {
        id: 'agenda',
        label: 'Agenda',
        href: Pages.SESSIONS_AGENDA_REQUEST,
        access: 'session.details.agenda',
      },
      {
        id: 'lead-mentor-report',
        label: `Lead ${rb('mentor-u')} Report`,
        href: Pages.SESSIONS_LEAD_MENTOR,
        access: 'session.details.leadMentorReport',
      },
      {
        id: 'mentor-assessment',
        label: `${rb('mentor-u')} Assessment`,
        href: Pages.SESSIONS_MENTOR_ASSESSMENT,
        access: 'session.details.mentorAssessment',
      },
      {
        id: 'founder-notes',
        label: 'Founder Notes',
        href: Pages.SESSIONS_FOUNDER_SESSION_NOTES,
        access: 'session.details.founderNotes',
      },
    ],
    [rb],
  );

  const joinLink = useMemo(() => {
    const tenantEventChannelId = session?.tenantEventChannelId;
    const channel =
      tenantEventChannelId &&
      channels?.find((c) => c.id === tenantEventChannelId);
    const channelLocation = channel?.channelLocation || '';

    return isUrl(channelLocation) ? channelLocation : null;
  }, [session, channels]);

  const { title, isReportPage } = useMemo(() => {
    const reportPages = [
      {
        title: `Lead ${rb('mentor-u')} Report`,
        path: Pages.SESSIONS_LEAD_MENTOR,
      },
      {
        title: `${rb('mentor-u')} Assessment`,
        path: Pages.SESSIONS_MENTOR_ASSESSMENT,
      },
      {
        title: 'Founder Notes',
        path: Pages.SESSIONS_FOUNDER_SESSION_NOTES,
      },
      {
        title: 'Agenda',
        path: Pages.SESSIONS_AGENDA_REQUEST,
      },
    ];

    const targetReportPage = reportPages.find((page) =>
      checkDynamicPath(location.pathname, page.path),
    );

    return targetReportPage
      ? {
          title: targetReportPage.title,
          isReportPage: true,
        }
      : {
          title: 'Session',
          isReportPage: false,
        };
  }, [location.pathname, rb]);

  const handleSendInvites = useCallback(
    async (cb: () => any) => {
      try {
        setIsInviting(true);
        const updatedSession = await eventsAPI.sendInvites(sessionId);
        replaceSession(updatedSession);
        enqueueSnackbar('The invitations were sent successfully', {
          variant: 'success',
        });
        setIsInviting(false);
        cb();
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        setIsInviting(false);
        enqueueSnackbar(
          'An error occurred while sending invitations. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        cb();
      }
    },
    [enqueueSnackbar, replaceSession, sessionId],
  );

  const handleCancelSession = useCallback(
    async (cb: () => any) => {
      if (!session) {
        return;
      }
      try {
        setIsCancelled(true);
        await eventsAPI.update(
          sessionId,
          getEventDetails('ARCHIVED', session, reason),
        );
        await eventsAPI.cancelEvent(sessionId);
        await eventsAPI.deleteEvent(sessionId);
        enqueueSnackbar('The meeting was successfully cancelled.', {
          variant: 'success',
        });
        cb();
        history.push(Pages.SESSIONS);
        setIsCancelled(false);
      } catch (error: any) {
        const messageError = error.response?.data?.message;

        enqueueSnackbar(
          'An error occurred at the cancel of the session. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCancelled(false);
        cb();
      }
    },
    [enqueueSnackbar, history, reason, session, sessionId],
  );

  // load session's info
  useEffect(() => {
    if (!session) {
      loadSession(sessionId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, !session, loadSession]);

  // load session's issues
  useEffect(() => {
    if (!sessionIssues) {
      loadSessionIssues(sessionId);
    }
  }, [sessionIssues, loadSessionIssues, sessionId]);

  return (
    <BaseLayout
      user={user}
      fullHeight
      key={
        isReportPage
          ? `session-report-${location.pathname.split('/').pop()}`
          : 'session'
      }>
      {isSessionLoading ? (
        <PageLoader />
      ) : (
        <>
          <Header
            title={title}
            backLink={
              <Tooltip title={isReportPage ? 'Back' : 'Back to Sessions'}>
                <IconButton
                  component={Link}
                  to={
                    isReportPage
                      ? getRoutePath(Pages.SESSIONS_DETAILS, {
                          sessionId,
                        })
                      : prevPath || Pages.SESSIONS
                  }
                  data-testid='button-back-sessions'>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            }
            isMergedActions
            additionalContent={
              isMobile() &&
              hasAccessToAction('session.details.acceptance') &&
              !isMentorNotParticipant && (
                <AcceptanceToggle
                  session={session}
                  assignmentStatus={assignmentStatus}
                />
              )
            }
            actions={
              isMentorNotParticipant
                ? []
                : [
                    {
                      key: 'invite-all',
                      label: 'Invite All',
                      hidden: !showInviteAllButton,
                      component: (
                        <ConfirmButton
                          title='Session'
                          loading={isInviting || isCancelled}
                          body='You are about to send a significant number of invites... Are you ready to notify everyone about this session?'
                          successProps={{
                            btnLabel: 'Yes',
                            onSuccess: handleSendInvites,
                          }}
                          cancelProps={{
                            btnLabel: 'No',
                          }}
                          buttonRenderer={({ onClick }) => (
                            <Button
                              onClick={onClick}
                              variant='contained'
                              data-testid='button-invite-all'
                              disabled={
                                !scheduled || isInviting || isCancelled
                              }>
                              {isInviting ? (
                                <CircularProgress size={24} color='inherit' />
                              ) : (
                                'Invite All'
                              )}
                            </Button>
                          )}
                        />
                      ),
                    },
                    {
                      key: 'join',
                      label: 'Join',
                      hidden: !showJoinButton,
                      disabled: !joinLink,
                      component: (
                        <Button
                          component='a'
                          href={joinLink || ''}
                          target='_blank'
                          disabled={!joinLink}
                          variant='contained'
                          data-testid='button-join'>
                          Join
                        </Button>
                      ),
                    },
                    ...linkActions.map(({ id, label, href, access }) => ({
                      key: id,
                      label,
                      hidden: !hasAccessToAction(access),
                      component: (
                        <MaterialButton
                          component={Link}
                          to={getRoutePath(href, { sessionId })}
                          variant='outlined'
                          data-testid={`button-${id}`}>
                          {label}
                        </MaterialButton>
                      ),
                    })),
                    ...(session?.id
                      ? [
                          {
                            key: 'add-to-calendar',
                            label: 'Add to Calendar',
                            hidden: !hasAccessToAction(
                              'session.details.addToCalendar',
                            ),
                            component: (
                              <AddToCalendarButton
                                eventId={session.id}
                                eventName={session.summary}
                              />
                            ),
                          },
                        ]
                      : []),
                    {
                      key: 'cancel-event',
                      label: 'Cancel Event',
                      hidden: !hasAccessToAction('session.details.cancel'),
                      component: (
                        <ConfirmButton
                          title='Do you really want to cancel this session?'
                          loading={isInviting || isCancelled}
                          body='We will send a cancellation note to all session participants and move this session to Archive. Do you want to proceed?'
                          successProps={{
                            btnLabel: 'Yes',
                            onSuccess: handleCancelSession,
                          }}
                          cancelProps={{
                            btnLabel: 'No',
                          }}
                          buttonRenderer={({ onClick }) => (
                            <Button
                              onClick={() => {
                                setIsCancelEvent(true);
                                onClick();
                              }}
                              variant='outlined'
                              data-testid='button-cancel-event'
                              disabled={
                                !scheduled || isInviting || isCancelled
                              }>
                              {isInviting ? (
                                <CircularProgress size={24} color='inherit' />
                              ) : (
                                'Cancel Event'
                              )}
                            </Button>
                          )}>
                          <TextField
                            small
                            name='reason'
                            label='Reason'
                            data-testid='modal-reason-input'
                            value={reason}
                            onChange={(e) => setReason(e.target.value)}
                            className={classes.inputDescription}
                          />
                        </ConfirmButton>
                      ),
                    },
                  ]
            }
          />
          <Paper className={classes.container} elevation={1}>
            {!isReportPage && (
              <PageTabs sessionId={sessionId}>
                {!isMobile() &&
                  hasAccessToAction('session.details.acceptance') &&
                  !isMentorNotParticipant && (
                    <AcceptanceToggle
                      session={session}
                      assignmentStatus={assignmentStatus}
                    />
                  )}
              </PageTabs>
            )}
            <div className={classes.contentWrapper}>
              <div className={classes.content}>
                <Router />
              </div>
            </div>
          </Paper>
        </>
      )}
    </BaseLayout>
  );
}

function withSessionProvider<P>(Component: React.FunctionComponent<P>) {
  return (props: P) => (
    <SessionProvider>
      <Component {...props} />
    </SessionProvider>
  );
}

export default withSessionProvider(SessionPage);

const Router = memo(() => {
  return (
    <Switch>
      <Route
        exact
        path={Pages.SESSIONS_DETAILS}
        component={ProtectedRoute(SessionDetailsPage)}
      />
      <Route exact path={Pages.SESSIONS_NOTES} component={SessionNotesPage} />
      <Route exact path={Pages.SESSION_LOG} component={SessionLogPage} />
      <Route exact path={Pages.SESSIONS_LINKS} component={SessionLinksPage} />
      <RouteACL
        exact
        path={Pages.SESSIONS_AGENDA_REQUEST}
        component={SessionAgendaRequestPage}
        rule='session.details.agenda'
        redirectUrl={Pages.SESSIONS}
      />
      <RouteACL
        exact
        path={Pages.SESSIONS_LEAD_MENTOR}
        component={SessionLeadMentorPage}
        rule='session.details.leadMentorReport'
        redirectUrl={Pages.SESSIONS}
      />
      <RouteACL
        exact
        path={Pages.SESSIONS_MENTOR_ASSESSMENT}
        component={SessionMentorAssessmentPage}
        rule='session.details.mentorAssessment'
        redirectUrl={Pages.SESSIONS}
      />
      <RouteACL
        exact
        path={Pages.SESSIONS_FOUNDER_SESSION_NOTES}
        component={SessionFounderNotesPage}
        rule='session.details.founderNotes'
        redirectUrl={Pages.SESSIONS}
      />

      <Redirect to={Pages.SESSIONS_DETAILS} />
    </Switch>
  );
});
