import { Address, Name } from '../types';
import { Natural } from '../utils/Num/Natural';
import { Sentence } from '../utils/String/Sentence';
import { Zip } from '../utils/String/Zip';
import { PastStringDate } from '../utils/date/PastStringDate';
import { StringDate } from '../utils/date/StringDate';
import { CohortId } from './Cohort/types/Cohort';
import { ExportJob } from './exports';
import { axiosRequest } from './instance';
import { MentorId } from './mentors';
import { Specialization } from './specializations';
import { TenantId } from './tenants/Tenant';
import { JWTToken } from './user/JWTToken';
import { VentureQuarterRecord } from './ventures/quarterlyrecords/types/VentureQuarterRecord';
import { Status, Venture, VentureId } from './ventures/types/Venture';
import { VenturesAdditionalInfo } from './ventures/types/VenturesAdditionalInfo';

export interface VentureTag {
  id: string;
  name: string;
  code: string;
  type: string;
  description: string;
}

export interface VentureAssignedTag {
  id: string;
  ventureId: string;
  tagId: string;
}

export interface VentureRecord {
  id: string;
  ventureId: string;
  creationDate: string;
  type: string;
  contents: string;
  recordDate: string;
  valid?: boolean;
  comment: string;
}

export interface VentureNewRecord {
  ventureId: string;
  type: string;
  contents: string;
  creationDate: string;
  recordDate: string;
}

export interface VentureSpecialization {
  id: string;
  ventureId: string;
  specializationId: string;
  valid: boolean;
}

export interface VentureNote {
  id: string;
  ventureId: string;
  creationDate: StringDate;
  contents: string;
  attachmentRefs: string | null;
  isLeadMentorNote?: boolean;
}

export interface VentureLog {
  id: string;
  ventureId: string;
  creationDate: string;
  contents: string;
}
export interface VentureNewNote {
  contents: string;
  ventureId: string;
  attachmentRefs?: string | null;
  creationDate?: StringDate;
}

export interface VentureMentor {
  id: string;
  type: string;
  isPrimary: boolean;
  ventureId: VentureId;
  mentorId: MentorId;
  valid: boolean;
}

export interface VentureFounder {
  id: string;
  ventureId: string;
  founderId: string;
  valid: boolean;
}

export interface VentureDetails {
  ventureNotes?: Array<VentureNote | VentureNewNote>;
  ventureRecords?: Array<VentureRecord | VentureNewRecord>;
  status: Status;
  ventureName: Name;
  url: Sentence<1024> | null;
  dateOfEnrollment: PastStringDate;
  dateOfCreation: PastStringDate | null;
  country: Sentence<1024> | null;
  state: Sentence<1024> | null;
  city: Sentence<1024> | null;
  zip: Zip | null;
  address: Address | null;
  numOfEmployees: Natural | null;
  description: Sentence<250> | null;
  isTech: boolean;
  venturesAdditionalInfo?: VenturesAdditionalInfo;
  dateOfExit: string;
  exitReason: string;
  tags?: string[] | null;
  cohortId: CohortId | null;
}

export interface VentureFounderAssignment {
  venture: Venture;
  numOfAssignments: number;
}

export interface SelectVenture {
  id: VentureId;
  ventureName: string;
}

export interface AdvancedFilters {
  tenantId: TenantId;
  status?: string | null;
  mentorsAssigned?: number | null;
  moreThanMentorsAssigned?: number | null;
  noSessions?: boolean | null;
  withWizard?: boolean | null;
  withIssues?: boolean | null;
  mentorName?: string | null;
  cohortId?: string | null;
  offset?: number;
  limit?: number;
}

function normalizeVenture(venture: Venture): Venture {
  return venture;
}

function normalizeVentureDetails({
  status,
  ventureName,
  url,
  dateOfEnrollment,
  dateOfCreation,
  country,
  state,
  city,
  zip,
  address,
  numOfEmployees,
  description,
  isTech,
  venturesAdditionalInfo,
  dateOfExit,
  exitReason,
  tags,
  cohortId,
}: VentureDetails): VentureDetails {
  return {
    status,
    ventureName,
    url,
    dateOfEnrollment,
    dateOfCreation,
    country,
    state,
    city,
    zip,
    address,
    numOfEmployees,
    description,
    isTech,
    venturesAdditionalInfo,
    dateOfExit,
    exitReason,
    tags,
    cohortId,
  };
}

const venturesAPI = {
  async search(searchString: string) {
    const res = await axiosRequest.get<Venture[]>({
      url: `/ventures/search/${searchString}`,
      credentials: true,
    });
    return res.data.map(normalizeVenture);
  },
  async mentorRelatedSearch(searchString: string) {
    const res = await axiosRequest.get<Venture[]>({
      url: `/ventures/search/mentor-related/${searchString}`,
      credentials: true,
    });
    return res.data.map(normalizeVenture);
  },
  async getVentures(page = 0) {
    const res = await axiosRequest.get<Venture[]>({
      url: `/ventures/pages/${page}`,
      credentials: true,
    });
    return res.data;
  },
  async getVenturesAdvancedFilters(body: AdvancedFilters, page: number) {
    const res = await axiosRequest.post<Venture[]>({
      url: `/ventures/advanced-filters/pages/${page}`,
      credentials: true,
      data: body,
    });
    return res.data;
  },
  async getNotAssigned(page: number) {
    const res = await axiosRequest.get<Venture[]>({
      url: `/ventures/notassigned/pages/${page}`,
      credentials: true,
    });
    return res.data;
  },
  async getVotedByMentor(id: MentorId, page: number) {
    const res = await axiosRequest.get<Venture[]>({
      url: `/ventures/mentors/${id}/votes/pages/${page}`,
      credentials: true,
    });
    return res.data;
  },
  async getVenturesByName(venturename: string) {
    try {
      const res = await axiosRequest.get<Venture[]>({
        url: `/ventures/venturename/${venturename}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getVenturesByStatus(status: string, page = 0) {
    const res = await axiosRequest.get<Venture[]>({
      url: `/ventures/statuses/${status}/pages/${page}`,
      credentials: true,
    });
    return res.data;
  },
  async getVenturesByAssignment(assignment: string, page = 0) {
    let url = `/ventures/assignments/${assignment}/pages/${page}`;

    if (
      ['notassigned', 'noevents', 'schedulingwizards', 'issues'].includes(
        assignment,
      )
    ) {
      url = `/ventures/${assignment}/pages/${page}`;
    }
    const res = await axiosRequest.get<Venture[]>({
      url,
      credentials: true,
    });
    return res.data;
  },
  async getVenturesMentorRelated() {
    const res = await axiosRequest.get<Venture[]>({
      url: '/ventures/noevents/mentor-related',
      credentials: true,
    });
    return res.data;
  },
  async getVenturesWithNoWizards() {
    const res = await axiosRequest.get<Venture[]>({
      url: '/ventures/noevents/nowizards',
      credentials: true,
    });
    return res.data;
  },
  async searchVenture(query: string) {
    const res = await axiosRequest.get<Venture[]>({
      url: `/ventures/venturename/${query}`,
      credentials: true,
    });
    return res.data;
  },
  async getMentors(ventureId: VentureId) {
    try {
      const res = await axiosRequest.get<VentureMentor[]>({
        url: `/ventures/${ventureId}/mentors`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getVenturesByMentorId(mentorId: string) {
    try {
      const res = await axiosRequest.get<VentureMentor[]>({
        url: `/ventures/mentors/${mentorId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getVenturesDetailsByMentorId(mentorId: MentorId) {
    try {
      const res = await axiosRequest.get<Venture[]>({
        url: `/ventures/mentors/${mentorId}/details`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getVenturesDetailsByCohortId(cohortId: CohortId, page: number) {
    try {
      const res = await axiosRequest.get<Venture[]>({
        url: `/ventures/cohorts/${cohortId}/pages/${page}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getVenturesDetailsByCohortIdAndStatus(
    cohortId: CohortId,
    status: Status,
    page: number,
  ) {
    try {
      const res = await axiosRequest.get<Venture[]>({
        url: `/ventures/cohorts/${cohortId}/statuses/${status}/pages/${page}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getVenturesDetailsByFounderId(founderId: string) {
    try {
      const res = await axiosRequest.get<Venture[]>({
        url: `/ventures/founders/${founderId}/details`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getVenturesByFounderId(founderId: string) {
    try {
      const res = await axiosRequest.get<Venture[]>({
        url: `/ventures/founders/${founderId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getVentureSpecializations(ventureId: Venture['id']) {
    try {
      const res = await axiosRequest.get<Specialization[]>({
        url: `/ventures/${ventureId}/specializations`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async setVentureSpecialization(
    ventureId: Venture['id'],
    specializationId: string,
  ) {
    const res = await axiosRequest.post<VentureSpecialization>({
      url: `/ventures/${ventureId}/specializations/${specializationId}`,
      credentials: true,
    });
    return res.data;
  },
  async deleteVentureSpecialization(
    ventureId: Venture['id'],
    specializationId: string,
  ) {
    const res = await axiosRequest.delete<VentureSpecialization>({
      url: `/ventures/${ventureId}/specializations/${specializationId}`,
      credentials: true,
    });
    return res.data;
  },
  async assignMentor(ventureId: string, mentorId: string) {
    const res = await axiosRequest.post<Venture[]>({
      url: `/ventures/${ventureId}/mentors/${mentorId}`,
      credentials: true,
    });
    return res.data;
  },
  async removeMentorAssignment(ventureId: string, mentorId: string) {
    const res = await axiosRequest.delete<VentureMentor[]>({
      url: `/ventures/${ventureId}/mentors/${mentorId}`,
      credentials: true,
    });
    return res.data;
  },
  async assignFounder(ventureId: string, founderId: string) {
    const res = await axiosRequest.post<VentureFounder>({
      url: `/ventures/${ventureId}/founders/${founderId}`,
      credentials: true,
    });
    return res.data;
  },
  async removeFounderAssignment(ventureId: string, founderId: string) {
    const res = await axiosRequest.delete<VentureFounder>({
      url: `/ventures/${ventureId}/founders/${founderId}`,
      credentials: true,
    });
    return res.data;
  },
  async updateMentorPrimaryFlag(
    ventureId: string,
    mentorId: string,
    state: boolean,
  ) {
    const res = await axiosRequest.put<VentureMentor>({
      url: `/ventures/${ventureId}/mentors/${mentorId}/${state}`,
      credentials: true,
    });
    return res.data;
  },
  async create(ventureDetails: VentureDetails) {
    const res = await axiosRequest.post<Venture>({
      url: '/ventures',
      data: {
        ...ventureDetails,
      },
      credentials: true,
    });
    return normalizeVenture(res.data);
  },
  async update(ventureId: string, ventureDetails: VentureDetails) {
    const res = await axiosRequest.put<Venture>({
      url: '/ventures',
      data: { ...normalizeVentureDetails(ventureDetails), id: ventureId },
      credentials: true,
    });
    return normalizeVenture(res.data);
  },
  async getVenture(ventureId: Venture['id']) {
    const res = await axiosRequest.get<Venture>({
      url: `/ventures/${ventureId}`,
      credentials: true,
    });
    return normalizeVenture(res.data);
  },
  async getFounders(ventureId: Venture['id']) {
    try {
      const res = await axiosRequest.get<VentureFounder[]>({
        url: `/ventures/${ventureId}/founders`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async checkExportVentures(jobId: ExportJob['jobId']) {
    try {
      const res = await axiosRequest.get<ExportJob>({
        url: `/export/ventures/${jobId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return false;
      }
      throw e;
    }
  },
  async exportVentures() {
    const res = await axiosRequest.post<ExportJob>({
      url: '/export/ventures',
      credentials: true,
    });
    return res.data;
  },
  async import(file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);
    try {
      const res = await axiosRequest.post<string>({
        url: '/import/ventures',
        data: formData,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async attachFileToNote(ventureId: string, file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/files/ventures/${ventureId}/venturenotes`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
  async attachFileToReport(ventureId: string, tenantId: string, file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/reports/files/tenants/${tenantId}/ventures/${ventureId}`,
      data: formData,
    });
    return res.data;
  },
  async getRecommendedVenturesByFounders() {
    try {
      const res = await axiosRequest.get<VentureFounderAssignment[]>({
        url: '/ventures/assignments/founders/sort',
        credentials: true,
      });
      return res.data.map((entity) => ({
        ...entity.venture,
        assigned: entity.numOfAssignments,
      }));
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getNotes(ventureId: string) {
    try {
      const res = await axiosRequest.get<VentureNote[]>({
        url: `/ventures/${ventureId}/notes`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createNote(note: VentureNewNote) {
    try {
      const res = await axiosRequest.post<VentureNote>({
        url: '/ventures/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeNote(ventureId: string, noteId: string) {
    try {
      const res = await axiosRequest.delete<VentureNote>({
        url: `/ventures/${ventureId}/notes/${noteId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updateNote(note: VentureNote) {
    try {
      const res = await axiosRequest.put<VentureNote>({
        url: '/ventures/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getRecords(ventureId: string) {
    try {
      const res = await axiosRequest.get<VentureRecord[]>({
        url: `/ventures/${ventureId}/records`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createRecord(record: VentureNewRecord) {
    try {
      const res = await axiosRequest.post<VentureRecord>({
        url: '/ventures/records',
        data: record,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeRecord(ventureId: string, recordId: string) {
    try {
      const res = await axiosRequest.delete<VentureRecord>({
        url: `/ventures/${ventureId}/records/${recordId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updateRecord(record: VentureRecord) {
    try {
      const res = await axiosRequest.put<VentureRecord>({
        url: '/ventures/records',
        data: record,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getLogs(ventureId: string) {
    try {
      const res = await axiosRequest.get<VentureLog[]>({
        url: `/ventures/${ventureId}/log`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async removeLog(ventureId: string, logId: string) {
    try {
      const res = await axiosRequest.delete<VentureLog>({
        url: `/ventures/${ventureId}/log/${logId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async sendVentureUpdateInvites(venturesIds: string[]) {
    try {
      const res = await axiosRequest.post<string>({
        url: '/ventures/invites',
        data: venturesIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getVentureToken(key: string) {
    try {
      const res = await axiosRequest.post<{ jwttoken: JWTToken }>({
        url: '/otl',
        data: key,
      });
      return res.data.jwttoken;
    } catch (e: any) {
      throw e;
    }
  },
  async loadVentureTags() {
    try {
      const res = await axiosRequest.get<VentureTag[]>({
        url: '/tags/ventures',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getVentureTagsById(ventureId: Venture['id']) {
    try {
      const res = await axiosRequest.get<VentureAssignedTag[]>({
        url: `/ventures/${ventureId}/tags`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async setVentureTags(ventureId: string, ventureTagId: string) {
    const res = await axiosRequest.post<VentureAssignedTag>({
      url: `/ventures/${ventureId}/tags/${ventureTagId}`,
      credentials: true,
    });
    return res.data;
  },
  async deleteVentureTags(ventureId: string, ventureTagId: string) {
    const res = await axiosRequest.delete<VentureAssignedTag>({
      url: `/ventures/${ventureId}/tags/${ventureTagId}`,
      credentials: true,
    });
    return res.data;
  },
  async sendVentureQuarterlyReport(venturesIds: string[]) {
    try {
      const res = await axiosRequest.post<string>({
        url: '/ventures/quarterlyrecords/invites',
        data: venturesIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getQuarterlyRecordByKey(key: string): Promise<VentureQuarterRecord> {
    try {
      const res = await axiosRequest.get<VentureQuarterRecord>({
        url: `/ventures/quarterlyrecords/${key}`,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getQuarterlyRecordByVentureId(
    ventureId: string,
  ): Promise<VentureQuarterRecord> {
    try {
      const res = await axiosRequest.get<VentureQuarterRecord>({
        url: `/ventures/${ventureId}/quarterlyrecords/recent`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async sendVentureQuarterReport(report: VentureQuarterRecord) {
    try {
      const res = await axiosRequest.post<VentureQuarterRecord>({
        url: '/ventures/quarterlyrecords',
        data: report,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getQuarterlyRecords(ventureId: string) {
    try {
      const res = await axiosRequest.get<VentureQuarterRecord[]>({
        url: `/ventures/${ventureId}/quarterlyrecords/submitted`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createPublicNote(
    tenantId: TenantId,
    ventureId: VentureId,
    note: VentureNewNote,
  ) {
    try {
      const res = await axiosRequest.post<VentureNote>({
        url: `/public/tenants/${tenantId}/ventures/${ventureId}/notes`,
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async attachPublicFileToNote(
    tenantId: TenantId,
    ventureId: string,
    file: File,
  ) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/public/tenants/${tenantId}/files/ventures/${ventureId}/venturenotes`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
};

export default venturesAPI;
