import {
  Button as MaterialButton,
  Checkbox,
  CircularProgress,
  IconButton,
  InputAdornment,
  Link as MaterialLink,
  makeStyles,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { ThumbUp } from '@material-ui/icons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import BarChartIcon from '@material-ui/icons/BarChart';
import EditIcon from '@material-ui/icons/Edit';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SearchIcon from '@material-ui/icons/Search';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import Visibility from '@material-ui/icons/Visibility';
import WarningIcon from '@material-ui/icons/Warning';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import React, {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useHistory, useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import _pick from 'lodash/pick';
import _uniq from 'lodash/uniq';
import { getActiveCohorts } from '../../api/Cohort';
import { CohortId } from '../../api/Cohort/types/Cohort';
import mentorsAPI, { Mentor, MentorId } from '../../api/mentors';
import { Role } from '../../api/user/Role';
import venturesAPI from '../../api/ventures';
import { Venture, VentureId } from '../../api/ventures/types/Venture';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { useAuth } from '../../hooks';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { fullName } from '../../utils/String';
import { urlfy } from '../../utils/String/Url';
import { massAsyncRequest } from '../../utils/api';
import { formatDate } from '../../utils/date';
import {
  decodeQuery,
  encodeQuery,
  getColorByUnicode,
  getColorStatus,
  isMobile,
  isTablet,
} from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { unreachableError } from '../../utils/unreachableError';
import { TestId } from '../Testing/TestId';
import {
  AlertState,
  Avatar,
  ButtonsChangeStatusVenture,
  ConfirmButton,
  Link,
  Modal,
  Select,
  SnackMessage,
  StatusBadge,
  Text,
  TextField,
} from '../common';
import { Like } from '../common/ActionIcons/Like';
import { Dot } from '../common/Dot';
import { Inline } from '../common/Inline';
import { TooltipMenu } from '../common/TooltipMenu';
import SelectMentorPopover from '../common/select-mentor-popover';
import {
  TableCell,
  TableFooter,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from '../common/table';
import AssignVenturesForm from '../forms/assign-ventures';
import { ReportLinkDialog } from '../ventures/ReportLinkDialog';
import VentureIssueTableInfo from '../ventures/issue-table-info';
import MentorTableInfo from '../ventures/mentor-table-info';

interface FilterTypes {
  search: string;
  assigned: 'none' | '0' | '1' | '2' | '3';
  status: 'none' | 'Active' | 'Archived' | 'Applicant' | 'Rejected';
  sessions: 'none' | 'noevents' | 'schedulingwizards' | 'issues';
  mentor: string;
  cohort: CohortId;
  mentorFilter:
    | 'all'
    | 'my-ventures'
    | 'applicants'
    | 'recruiting'
    | 'interested'
    | 'active'
    | 'archived'
    | 'rejected';
}

type FilterType = keyof FilterTypes;

type FilterValueType<K extends keyof FilterTypes = keyof FilterTypes> = {
  [k in keyof FilterTypes]: {
    key: k;
    value: FilterTypes[k];
  };
}[K];

type CohortOption = { label: string; value: CohortId | null };

const useStyles = makeStyles({
  container: {
    padding: 0,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 16px',
    height: 64,
    boxSizing: 'border-box',
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  filterSelect: {
    width: 160,
  },
  filterActions: {
    display: 'flex',
    alignItems: 'center',
    margin: '-1px 0',
  },
  filterActionsAssignArchived: {
    marginLeft: 11,
  },
  filterActionsAssignUnArchive: {
    marginLeft: 2,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  nameColumn: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  assignedColumn: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 10px 0',
    boxSizing: 'border-box',
    textAlign: 'right',
  },
  showInterestColumn: {
    textAlign: 'center',
  },
  appliedOnColumn: {
    minWidth: 100,
    justifyContent: 'start',
  },
  founderColumn: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  urlColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  inactiveDaysColumn: {
    paddingRight: 20,
    boxSizing: 'border-box',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  searchInput: {
    width: '100%',
    maxWidth: 320,
  },
  mentorInput: {
    width: 180,
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  containerRemoveFilter: {
    marginLeft: 10,
  },
  iconRemoveFilter: {
    width: 20,
  },
  warningIcon: {
    color: COLORS.COLOR_ADDITIONAL_ORANGE,
    cursor: 'pointer',
  },
  ventureName: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  swIcon: {
    marginLeft: 10,
  },
  ventureIssues: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: 49,
  },
  issueIcon: {
    width: 42,
    display: 'flex',
  },
  likedIconWrapper: {
    width: 24,
    height: 24,
    borderRadius: '100%',
    backgroundColor: COLORS.COLOR_BLUE_BASE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    opacity: 0.7,
  },
  likedIcon: {
    width: 16,
    height: 16,
    cursor: 'pointer',
    transform: 'rotateY(180deg)',
  },
  showInterest: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
});

function parseFilterFromURL(search: string): FilterValueType | undefined {
  const disabledQs = ['page'];
  const parsedFilter = parseQuery(search);
  disabledQs.forEach((key) => delete parsedFilter[key]);

  const filterName = Object.keys(parsedFilter)[0] as
    | FilterValueType['key']
    | undefined;

  if (!filterName) return undefined;

  const filterValue = parsedFilter[filterName];

  return typeof filterValue === 'string'
    ? ({ key: filterName, value: filterValue } as FilterValueType)
    : undefined;
}

function parseFiltersFromURL(search: string): FilterTypes {
  const keys: FilterType[] = [
    'search',
    'status',
    'assigned',
    'sessions',
    'mentor',
    'mentorFilter',
    'cohort',
  ];
  const parsedFilter = _pick(parseQuery(search), keys);
  const parsedFilterKeys = Object.keys(parsedFilter);
  const filters = parsedFilterKeys.reduce((acc: FilterTypes, k) => {
    const key = k as FilterType;
    const value = parsedFilter[key];
    if (typeof value === 'string') {
      // @ts-ignore
      acc[key] = value;
    }
    return acc;
  }, {} as FilterTypes);

  return filters;
}

const getFilterAlias = (filter: FilterType) => {
  const aliases = {
    search: 'Search',
    status: 'Status',
    assigned: 'Assignment',
    sessions: 'Sessions',
    mentor: 'Mentor',
    mentorFilter: 'Status',
    cohort: 'Group',
  };

  return aliases[filter];
};

function getPageFromURL(search: string) {
  const parsedQuery = _pick(parseQuery(search), ['page']);
  return parsedQuery.page ? Number(parsedQuery.page) : 1;
}

function VenturesTable() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const { hasAccessToAction, hasRole, identityid, user } =
    useContext(UserContext);
  const { rb } = useResourceBundles();
  const { tokenData } = useAuth();
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const isMentor = hasRole(Role.Mentor);

  const filters: {
    [k in FilterType]: Array<{
      label: string;
      value: FilterTypes[k];
    }>;
  } = useMemo(
    () => ({
      search: [],
      status: [
        {
          label: 'All Statuses',
          value: 'none',
        },
        {
          label: 'Active',
          value: 'Active',
        },
        {
          label: 'Archived',
          value: 'Archived',
        },
        {
          label: 'Applicant',
          value: 'Applicant',
        },
        {
          label: 'Rejected',
          value: 'Rejected',
        },
      ],
      assigned: [
        {
          label: 'Any assigned',
          value: 'none',
        },
        {
          label: 'Not assigned',
          value: '0',
        },
        {
          label: `One ${rb('mentor')}`,
          value: '1',
        },
        {
          label: `Two ${rb('mentors')}`,
          value: '2',
        },
        {
          label: `3+ ${rb('mentors')}`,
          value: '3',
        },
      ],
      sessions: [
        {
          label: 'All',
          value: 'none',
        },
        {
          label: 'No sessions',
          value: 'noevents',
        },
        {
          label: 'With Wizard',
          value: 'schedulingwizards',
        },
        {
          label: 'With Issues',
          value: 'issues',
        },
      ],
      mentor: [],
      mentorFilter: [
        {
          label: 'All Statuses',
          value: 'all',
        },
        {
          label: 'My Ventures',
          value: 'my-ventures',
        },
        {
          label: 'My Likes',
          value: 'interested',
        },
        {
          label: `Need ${rb('mentors-u')}`,
          value: 'recruiting',
        },
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Archived',
          value: 'archived',
        },
        {
          label: 'Applicant',
          value: 'applicants',
        },
        {
          label: 'Rejected',
          value: 'rejected',
        },
      ],
      cohort: [],
    }),
    [rb],
  );

  const filterValue = useMemo(
    () =>
      parseFilterFromURL(location.search) ??
      (isMentor
        ? ({ key: 'mentorFilter', value: 'my-ventures' } as FilterValueType)
        : undefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.search],
  );
  const filtersFromURL = useMemo(
    () => parseFiltersFromURL(location.search),
    [location.search],
  );

  const [ventures, setVentures] = useState<Venture[]>([]);
  const [mentor, setMentor] = useState<Mentor | undefined>(undefined);
  const [cohorts, setCohorts] = useState<Array<CohortOption>>([]);
  const [selectedVentureIds, setSelectedVentureIds] = useState<Venture['id'][]>(
    [],
  );
  const [loadedMentorsById, setLoadedMentorsById] = useState<{
    [x: string]: Mentor[];
  }>({});
  // TODO: use it when sort will be implemented
  // const [sortBy, setSortBy] = useState<SortByValueType>();
  const [searchValue, setSearchValue] = useState(filtersFromURL.search || '');
  const page = useMemo(
    () => getPageFromURL(location.search),
    [location.search],
  );
  const [isLoading, setIsLoading] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadError, setLoadError] = useState();
  const [isEmptyState, setIsEmptyState] = useState(false);
  const perPage = useMemo(
    () =>
      !filtersFromURL.search &&
      filtersFromURL.mentorFilter !== 'my-ventures' &&
      !hasRole(Role.Founder)
        ? 10
        : undefined,
    [filtersFromURL, hasRole],
  );
  const [isAssigningVenture, setIsAssigningVenture] = useState<{
    [x: string]: boolean;
  }>({});
  const [isSendingProfileUpdate, setIsSendingProfileUpdate] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const debouncedTimer = useRef<number>();

  const venturesRequest = useCallback(
    async (filters: FilterTypes, page: number) => {
      if (!user?.id) {
        throw new Error('User is not defined');
      }

      if (hasRole(Role.Founder) && identityid) {
        return venturesAPI.getVenturesDetailsByFounderId(identityid);
      } else if (filters.search) {
        return await venturesAPI.search(filters.search);
      } else if (filters.mentorFilter) {
        switch (filters.mentorFilter) {
          case 'all': {
            return await venturesAPI.getVentures(page);
          }
          case 'my-ventures': {
            return await venturesAPI.getVenturesDetailsByMentorId(
              tokenData?.identityid as MentorId,
            );
          }
          case 'applicants':
            return await venturesAPI.getVenturesByStatus('Applicant', page);
          case 'active':
            return await venturesAPI.getVenturesByStatus('Active', page);
          case 'rejected':
            return await venturesAPI.getVenturesByStatus('Rejected', page);
          case 'archived':
            return await venturesAPI.getVenturesByStatus('Archived', page);
          case 'recruiting':
            return await venturesAPI.getNotAssigned(page);
          case 'interested':
            return await venturesAPI.getVotedByMentor(
              tokenData?.identityid as MentorId,
              page,
            );
          default: {
            return unreachableError(filters.mentorFilter);
          }
        }
      } else if (!isEmpty(filters)) {
        const mentorsAssigned =
          !filters.assigned ||
          filters.assigned === 'none' ||
          filters.assigned === '3'
            ? -1
            : +filters.assigned;
        const status = filters.status === 'none' ? null : filters.status;
        const cohortId = filters.cohort ?? null;
        const sessions = {
          noSessions: filters.sessions === 'noevents',
          withWizard: filters.sessions === 'schedulingwizards',
          withIssues: filters.sessions === 'issues',
        };
        const moreThanMentorsAssigned = filters.assigned === '3' ? 2 : -1;
        const mentor = filters.mentor
          ? (decodeQuery(filters.mentor) as { id: string })
          : null;
        const mentorName = mentor?.id ? mentor.id : null;
        return await venturesAPI.getVenturesAdvancedFilters(
          {
            tenantId: user.id,
            mentorsAssigned,
            moreThanMentorsAssigned,
            cohortId,
            status,
            mentorName,
            ...sessions,
          },
          page,
        );
      } else {
        return venturesAPI.getVentures(page);
      }
    },
    [hasRole, identityid, tokenData?.identityid, user],
  );

  function handleVote(id: VentureId) {
    if (!mentor) {
      return;
    }

    const venture = ventures.find((v) => v.id === id);

    if (!venture) return;

    if (
      venture.mentorsVoted?.includes(
        fullName(mentor.firstName, mentor.lastName),
      )
    ) {
      mentorsAPI.unvoteVenture(mentor.id, id).then(() =>
        setVentures((ventures) =>
          ventures.map((v) =>
            v.id === id
              ? {
                  ...v,
                  mentorsVoted:
                    v.mentorsVoted?.filter(
                      (m) => m !== fullName(mentor.firstName, mentor.lastName),
                    ) ?? null,
                }
              : v,
          ),
        ),
      );
    } else {
      mentorsAPI.voteVenture(mentor.id, mentor.tenantId, id).then(() =>
        setVentures((ventures) =>
          ventures.map((v) =>
            v.id === id
              ? {
                  ...v,
                  mentorsVoted: [
                    ...(v.mentorsVoted ?? []),
                    fullName(mentor.firstName, mentor.lastName),
                  ],
                }
              : v,
          ),
        ),
      );
    }
  }

  // TODO: use it when sort will be implemented
  // const sortedList = useMemo(() => {
  //   if (sortBy) {
  //     const sorted = _sortBy(ventures, [sortBy?.field]);
  //     return sortBy.direction === 'desc' ? sorted.reverse() : sorted;
  //   }
  //   return ventures;
  // }, [ventures, sortBy]);

  const showClearFilter = useMemo(() => {
    return !isEmpty(filtersFromURL) || !!searchValue;
  }, [filtersFromURL, searchValue]);

  const statusSelectedVentures = useMemo(() => {
    if (!selectedVentureIds) {
      return undefined;
    }
    const selectedVentures = ventures.filter((venture) =>
      selectedVentureIds.includes(venture.id),
    );

    const activeSelectedVentures = selectedVentures.filter(
      (venture) => venture.status === 'Active',
    );

    const archivedSelectedVentures = selectedVentures.filter(
      (venture) => venture.status === 'Archived',
    );

    let disabledButton;
    if (
      activeSelectedVentures.length > 0 &&
      archivedSelectedVentures.length === 0
    ) {
      disabledButton = 'active';
    }
    if (
      archivedSelectedVentures.length > 0 &&
      activeSelectedVentures.length === 0
    ) {
      disabledButton = 'archived';
    }

    return {
      activeSelectedVentures,
      archivedSelectedVentures,
      disabledButton,
    };
  }, [selectedVentureIds, ventures]);

  const handleClearFilters = useCallback(() => {
    if (searchValue) {
      setSearchValue('');
    }
    history.replace(location.pathname);
  }, [history, location, searchValue]);

  const loadVentures = async (filters: FilterTypes, currentPage: number) => {
    try {
      setVentures([]);
      setSelectedVentureIds([]);
      setIsLoading(true);
      setIsEmptyState(false);
      if (!filters.search && searchValue.length > 2) {
        setSearchValue('');
      }
      const loadedVentures = await venturesRequest(filters, currentPage - 1);
      if (loadedVentures.length === 0) {
        throw new Error('404');
      }
      setVentures(loadedVentures);
      setIsLoading(false);

      if (loadedVentures.length === 10 && !!perPage) {
        setIsNextPageLoading(true);
        try {
          const nextVentures = await venturesRequest(filters, currentPage);
          setIsNextPageLoading(false);
          setHasNextPage(nextVentures.length > 0);
        } catch (e: any) {
          setIsNextPageLoading(false);
          setHasNextPage(false);
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e: any) {
      if (e?.response?.status === 404 || e?.message === '404') {
        setIsLoading(false);
        return setIsEmptyState(true);
      }
      const errorMessage =
        e?.response?.data?.message || 'Internal server error';
      setIsLoading(false);
      setLoadError(errorMessage);
    }
  };

  const loadMentor = async (id: MentorId) => {
    try {
      const loadedMentor = await mentorsAPI.getMentor(id);
      setMentor(loadedMentor);
    } catch (e: any) {
      if (e.response.status === 401) {
        console.error(e);
      } else {
        enqueueSnackbar(`Could not load ${rb('mentor')} details`, {
          variant: 'error',
        });
      }
    }
  };

  const handleFilterUpdate = useCallback(
    (filter: Partial<FilterValueType>) => {
      if (!filter.key) {
        return;
      } else if (filter.key === 'search' || filter.key === 'mentorFilter') {
        history.replace(
          `${location.pathname}?${stringifyQuery({
            [filter.key]: filter.value,
          })}`,
        );
      } else if (filter.value === 'none' || !filter.value) {
        history.replace(
          `${location.pathname}?${stringifyQuery({
            ...filtersFromURL,
            [filter.key]: undefined,
          })}`,
        );
      } else {
        history.replace(
          `${location.pathname}?${stringifyQuery({
            ...filtersFromURL,
            [filter.key]: filter.value,
            search: undefined,
            mentorFilter: undefined,
          })}`,
        );
      }
    },
    [filtersFromURL, history, location.pathname],
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debouncedTimer.current);
      setSearchValue(e.target.value);

      debouncedTimer.current = setTimeout(() => {
        if (e.target.value.trim().length > 2) {
          handleFilterUpdate({ key: 'search', value: e.target.value.trim() });
        } else {
          handleFilterUpdate({ key: 'search', value: '' });
        }
      }, 800) as unknown as number;
    },
    [handleFilterUpdate],
  );

  // TODO: use it when sort will be implemented
  // const handleSortTable = useCallback(
  //   (field: string, direction: 'asc' | 'desc') => {
  //     console.log('sort');
  //     setSortBy({ field, direction });
  //   },
  //   []
  // );

  const handleMentorsLoaded = useCallback(
    (ventureId: Venture['id']) => (loadedMentors: Mentor[]) => {
      setVentures((prevVentures) =>
        prevVentures.map((venture) =>
          venture.id === ventureId
            ? { ...venture, assigned: loadedMentors.length }
            : venture,
        ),
      );
      setLoadedMentorsById((prevState) => ({
        ...prevState,
        [ventureId]: loadedMentors,
      }));
    },
    [],
  );

  const handlePageChange = useCallback(
    (nextPage) => {
      const query = stringifyQuery({
        page: nextPage > 1 ? nextPage : undefined,
        ...(!isEmpty(filtersFromURL)
          ? filtersFromURL.cohort
            ? { cohort: filtersFromURL.cohort }
            : filtersFromURL
          : {}),
      });

      if (query) {
        history.replace(`${location.pathname}?${query}`);
      } else {
        history.replace(location.pathname);
      }
    },
    [filtersFromURL, history, location.pathname],
  );

  const handleAllRowSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        const venturesIds = ventures.map((venture) => venture.id);
        return setSelectedVentureIds([...venturesIds]);
      }
      return setSelectedVentureIds([]);
    },
    [ventures],
  );

  const handleRowSelect = useCallback(
    (checked: boolean, ventureId: Venture['id']) => {
      if (checked) {
        return setSelectedVentureIds(_uniq([...selectedVentureIds, ventureId]));
      }
      return setSelectedVentureIds(
        selectedVentureIds.filter((id) => id !== ventureId),
      );
    },
    [selectedVentureIds],
  );

  const handleRowClick = useCallback(
    ({ rowData }) => {
      const ventureId = rowData.id;
      handleRowSelect(!selectedVentureIds.includes(ventureId), ventureId);
    },
    [selectedVentureIds, handleRowSelect],
  );

  const handleAssingSuccessForm = useCallback(
    (ventureId: Venture['id'], handleModalClose: () => any) =>
      async ({
        assigned,
        removed,
        all,
      }: {
        assigned: string[];
        removed: string[];
        all: Mentor[];
      }) => {
        if (!assigned.length && !removed.length) {
          return handleModalClose();
        }

        try {
          setIsAssigningVenture((prev) => ({ ...prev, [ventureId]: true }));
          const assignedRequests = assigned.map(
            (mentorId) => () => venturesAPI.assignMentor(ventureId, mentorId),
          );
          const removedRequests = removed.map(
            (mentorId) => () =>
              venturesAPI.removeMentorAssignment(ventureId, mentorId),
          );
          await Promise.all([
            massAsyncRequest(assignedRequests),
            massAsyncRequest(removedRequests),
          ]);
          handleMentorsLoaded(ventureId)(all);
          handleModalClose();
          setIsAssigningVenture((prev) => ({ ...prev, [ventureId]: false }));
        } catch (e: any) {
          console.error('error', e);
        }
      },
    [handleMentorsLoaded],
  );

  const handleSendVentureUpdateProfiles = async (onClose: () => {}) => {
    try {
      setIsSendingProfileUpdate(true);
      await venturesAPI.sendVentureUpdateInvites(selectedVentureIds);
      enqueueSnackbar('The profile update requests were successfully sent', {
        variant: 'success',
      });
      setIsSendingProfileUpdate(false);
      onClose();
      setSelectedVentureIds([]);
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setIsSendingProfileUpdate(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  const handleSendVentureUpdate = async (
    onClose: () => any,
    ventureId: string,
  ) => {
    try {
      setIsSendingProfileUpdate(true);
      await venturesAPI.sendVentureUpdateInvites([ventureId]);
      enqueueSnackbar('The profile update request was successfully sent', {
        variant: 'success',
      });
      setIsSendingProfileUpdate(false);
      onClose();
    } catch (e) {
      const messageError = (e as AxiosError).response?.data?.message;

      setIsSendingProfileUpdate(false);
      enqueueSnackbar(
        'An error occurred while sending profile update request. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );
      throw e;
    }
  };

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const getDataTestId = (data: string, isActive: boolean): string => {
    return `${data}${isActive ? '-active' : ''}`;
  };

  useEffect(() => {
    loadVentures(filtersFromURL, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersFromURL, page]);

  useEffect(() => {
    if (isMentor) {
      loadMentor(tokenData?.identityid as MentorId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMentor, tokenData?.identityid]);

  useEffect(() => {
    getActiveCohorts()
      .then((vs) =>
        vs.map((v) => ({
          value: v.id,
          label: v.name,
        })),
      )
      .then((vs) => setCohorts([{ label: 'All groups', value: null }, ...vs]))
      .catch(() =>
        enqueueSnackbar('Could not load groups details', { variant: 'error' }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statusFilterValue =
    filtersFromURL.status && filtersFromURL.status !== 'none'
      ? filtersFromURL.status
      : filters.status[0].value;

  return (
    <Paper className={classes.container} data-testid='ventures-table'>
      {isTablet() && hasAccessToAction('venture.filters') && isMentor && (
        <div className={classes.filterContainer}>
          <div className={classes.filterList}>
            <div className={classes.filterItem}>
              <TestId testId={'my-ventures-filter'}>
                <Select<FilterTypes['mentorFilter']>
                  className={cn(
                    classes.filterSelect,
                    CLASS_TRACKING.FILTER_ELEMENT,
                  )}
                  value={
                    filtersFromURL.mentorFilter ||
                    filters['mentorFilter'][0].value
                  }
                  options={filters['mentorFilter']}
                  onSelect={(item) =>
                    handleFilterUpdate({
                      key: 'mentorFilter',
                      value: item?.value,
                    })
                  }
                  isActive={
                    filterValue?.key === 'mentorFilter' &&
                    filterValue.value !== filters['mentorFilter'][0].value
                  }
                />
              </TestId>
            </div>
          </div>
        </div>
      )}
      {!isTablet() && hasAccessToAction('venture.filters') && (
        <div className={classes.filterContainer}>
          <div className={classes.filterList}>
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'venture-filter-search',
                !!searchValue,
              )}>
              <TextField
                className={cn(
                  classes.searchInput,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                value={searchValue}
                onChange={handleSearch}
                placeholder='Search'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                small
              />
            </div>
            {!isMentor && (
              <div
                className={classes.filterItem}
                data-testid={getDataTestId(
                  'venture-filter-status',
                  statusFilterValue !== filters.status[0].value,
                )}>
                <TestId testId={'venture-status-filter'}>
                  <Select
                    className={cn(
                      classes.filterSelect,
                      CLASS_TRACKING.FILTER_ELEMENT,
                    )}
                    value={statusFilterValue}
                    options={filters.status}
                    onSelect={(item) => {
                      handleFilterUpdate({
                        key: 'status',
                        value: item?.value,
                      });
                    }}
                    isActive={statusFilterValue !== filters.status[0].value}
                  />
                </TestId>
              </div>
            )}
            {isMentor && (
              <div className={classes.filterItem}>
                <TestId testId={'my-ventures-filter'}>
                  <Select<FilterTypes['mentorFilter']>
                    className={cn(
                      classes.filterSelect,
                      CLASS_TRACKING.FILTER_ELEMENT,
                    )}
                    value={
                      filtersFromURL.mentorFilter ||
                      filters['mentorFilter'][0].value
                    }
                    options={filters['mentorFilter']}
                    onSelect={(item) =>
                      handleFilterUpdate({
                        key: 'mentorFilter',
                        value: item?.value,
                      })
                    }
                    isActive={
                      filtersFromURL.mentorFilter &&
                      filtersFromURL.mentorFilter !==
                        filters['mentorFilter'][0].value
                    }
                  />
                </TestId>
              </div>
            )}
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'venture-filter-assigned',
                filtersFromURL.assigned &&
                  filtersFromURL.assigned !== filters.assigned[0].value,
              )}>
              <Select
                className={cn(
                  classes.filterSelect,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                value={filtersFromURL.assigned || filters.assigned[0].value}
                options={filters.assigned}
                onSelect={(item) =>
                  handleFilterUpdate({ key: 'assigned', value: item?.value })
                }
                isActive={
                  filtersFromURL.assigned &&
                  filtersFromURL.assigned !== filters.assigned[0].value
                }
              />
            </div>
            {!isMentor && (
              <div
                className={classes.filterItem}
                data-testid={getDataTestId(
                  'venture-filter-sessions',
                  filtersFromURL.sessions &&
                    filtersFromURL.sessions !== filters.sessions[0].value,
                )}>
                <Select
                  className={cn(
                    classes.filterSelect,
                    CLASS_TRACKING.FILTER_ELEMENT,
                  )}
                  value={filtersFromURL.sessions || filters.sessions[0].value}
                  options={filters.sessions}
                  onSelect={(item) =>
                    handleFilterUpdate({ key: 'sessions', value: item?.value })
                  }
                  isActive={
                    filtersFromURL.sessions &&
                    filtersFromURL.sessions !== filters.sessions[0].value
                  }
                />
              </div>
            )}
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'venture-filter-mentor',
                !!filtersFromURL.mentor,
              )}>
              <SelectMentorPopover
                className={cn(
                  classes.mentorInput,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                onChange={(val) =>
                  handleFilterUpdate({
                    key: 'mentor',
                    value: encodeQuery(val),
                  })
                }
                value={
                  filtersFromURL.mentor
                    ? decodeQuery(filtersFromURL.mentor)
                    : undefined
                }
                isActive={!!filtersFromURL.mentor}
                small
              />
            </div>
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'venture-filter-cohorts',
                filtersFromURL.cohort &&
                  filtersFromURL.cohort !== cohorts[0]?.value,
              )}>
              <Select
                className={cn(classes.filterSelect)}
                value={filtersFromURL.cohort || cohorts[0]?.value || null}
                options={cohorts}
                onSelect={(item) => {
                  handleFilterUpdate({
                    key: 'cohort',
                    value: item?.value as CohortId,
                  });
                }}
                isActive={
                  filtersFromURL.cohort &&
                  filtersFromURL.cohort !== cohorts[0]?.value
                }
              />
            </div>
            {showClearFilter && (
              <Tooltip title='Clear all filters'>
                <IconButton
                  data-testid='button-remove-filter'
                  className={classes.containerRemoveFilter}
                  onClick={() => handleClearFilters()}>
                  <img
                    src='/filter.svg'
                    alt='Remove filter'
                    className={classes.iconRemoveFilter}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {selectedVentureIds.length > 0 && (
            <div className={classes.filterActions}>
              <Text variant='normal'>{selectedVentureIds.length} selected</Text>
              {hasAccessToAction('venture.details.update') && (
                <Modal
                  title='Archive ventures'
                  caption={`Do you really want to archive ${
                    statusSelectedVentures?.activeSelectedVentures.length
                  } ${
                    statusSelectedVentures?.activeSelectedVentures.length === 1
                      ? 'venture'
                      : 'ventures'
                  }?`}
                  contentRenderer={({ handleClose }) => (
                    <ButtonsChangeStatusVenture
                      onCancel={handleClose}
                      selectedValue={
                        statusSelectedVentures?.activeSelectedVentures
                      }
                      status='Archived'
                      updateTable={() => loadVentures(filtersFromURL, page)}
                    />
                  )}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Archive'>
                      <IconButton
                        className={cn(
                          classes.filterActionsAssignArchived,
                          CLASS_TRACKING.INTERNAL_ACTION,
                        )}
                        onClick={onClick}
                        disabled={
                          statusSelectedVentures?.disabledButton === 'archived'
                        }>
                        <ArchiveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  width={350}
                />
              )}
              {hasAccessToAction('venture.details.update') && (
                <Modal
                  title='Un-Archive ventures'
                  caption={`Do you really want to un-archive ${
                    statusSelectedVentures?.archivedSelectedVentures.length
                  } ${
                    statusSelectedVentures?.activeSelectedVentures.length === 1
                      ? 'venture'
                      : 'ventures'
                  }?`}
                  contentRenderer={({ handleClose }) => (
                    <ButtonsChangeStatusVenture
                      onCancel={handleClose}
                      selectedValue={
                        statusSelectedVentures?.archivedSelectedVentures
                      }
                      status='Active'
                      updateTable={() => loadVentures(filtersFromURL, page)}
                    />
                  )}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Un-Archive'>
                      <IconButton
                        className={cn(
                          classes.filterActionsAssignUnArchive,
                          CLASS_TRACKING.INTERNAL_ACTION,
                        )}
                        onClick={onClick}
                        disabled={
                          statusSelectedVentures?.disabledButton === 'active'
                        }>
                        <UnarchiveIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  width={350}
                />
              )}
              {hasAccessToAction('venture.details.update') && (
                <ConfirmButton
                  title='Send Mass Profile Update Requests'
                  loading={isSendingProfileUpdate}
                  body='Your are about to send a number of temporary links to selected participants, so that they update their profiles. Each link can work only 1 time, and is available for 48 hours only.'
                  successProps={{
                    btnLabel: 'Apply',
                    onSuccess: handleSendVentureUpdateProfiles,
                  }}
                  cancelProps={{
                    btnLabel: 'Cancel',
                  }}
                  buttonRenderer={({ onClick }) => (
                    <Tooltip title='Send a link to request a profile update'>
                      <IconButton onClick={onClick}>
                        <AccountCircleIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                />
              )}
              {hasAccessToAction('venture.details.update') && (
                <ReportLinkDialog ventureIds={selectedVentureIds}>
                  {({ open }) => {
                    return (
                      <Tooltip
                        title='Send a link to request a quarterly report'
                        placement='bottom-end'>
                        <IconButton onClick={open}>
                          <BarChartIcon />
                        </IconButton>
                      </Tooltip>
                    );
                  }}
                </ReportLinkDialog>
              )}
            </div>
          )}
        </div>
      )}

      <div ref={containerRef}>
        {ventures.length > 0 ? (
          <div>
            <Table
              onRowClick={
                hasAccessToAction('venture.details.update')
                  ? handleRowClick
                  : () => {}
              }
              gridClassName={classes.table}
              headerHeight={56}
              height={64 * (ventures.length > 10 ? ventures.length : 10) + 56}
              rowHeight={64}
              rowCount={ventures.length}
              rowGetter={({ index }) => ventures[index]}
              rowClassName={classes.row}
              headerRowRenderer={(headRowProps) => (
                <TableHeadRow {...headRowProps} />
              )}
              rowRenderer={(rowProps) => (
                <TableRow
                  data-testid={`venture-table-row-${rowProps.index}`}
                  selected={selectedVentureIds.includes(rowProps.rowData.id)}
                  {...rowProps}
                />
              )}
              width={containerWidth || 500}>
              <Column
                dataKey='id'
                className={classes.column}
                width={
                  !isTablet() && hasAccessToAction('venture.details.update')
                    ? 72
                    : 30
                }
                minWidth={
                  !isTablet() && hasAccessToAction('venture.details.update')
                    ? 72
                    : 30
                }
                headerRenderer={() => (
                  <div>
                    {!isTablet() &&
                      hasAccessToAction('venture.details.update') && (
                        <Checkbox
                          data-testid='venture-table-row-checkbox'
                          checked={
                            selectedVentureIds.length === ventures.length
                          }
                          color='primary'
                          indeterminate={
                            selectedVentureIds.length > 0 &&
                            selectedVentureIds.length !== ventures.length
                          }
                          onChange={(e) => handleAllRowSelect(e.target.checked)}
                        />
                      )}
                  </div>
                )}
                cellRenderer={({ cellData }) => (
                  <div>
                    {!isTablet() &&
                      hasAccessToAction('venture.details.update') && (
                        <Checkbox
                          checked={selectedVentureIds.includes(cellData)}
                          color='primary'
                          onChange={(e) =>
                            handleRowSelect(e.target.checked, cellData)
                          }
                        />
                      )}
                  </div>
                )}
              />
              <Column
                dataKey='ventureName'
                className={cn(classes.column, classes.nameColumn)}
                headerClassName={classes.nameColumn}
                width={204}
                minWidth={isTablet() ? 100 : 204}
                maxWidth={473}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Name</TableHeadCell>}
                cellRenderer={({ cellData, rowData }) => (
                  <div className={classes.ventureName}>
                    <TableCell truncated>
                      <MaterialLink
                        data-testid='venture-table-name'
                        onClick={stopPropagation}
                        component={Link}
                        to={{
                          pathname: getRoutePath(Pages.VENTURE_DETAILS, {
                            ventureId: rowData.id,
                          }),
                          state: {
                            prevPath: currentPath,
                          },
                        }}>
                        {cellData}
                      </MaterialLink>
                    </TableCell>
                    {!(hasRole(Role.Founder) && isMobile()) &&
                      rowData.hasActiveWizards && (
                        <Tooltip title='Scheduling Wizard is Active'>
                          <div>
                            <Avatar
                              name='sw'
                              size='32'
                              color={COLORS.COLOR_GRAY_LIGHTENED_20}
                              className={classes.swIcon}
                            />
                          </div>
                        </Tooltip>
                      )}
                  </div>
                )}
              />
              {!isMobile() && (
                <Column
                  dataKey='interested'
                  className={cn(classes.column, classes.nameColumn)}
                  headerClassName={classes.nameColumn}
                  width={60}
                  maxWidth={60}
                  minWidth={60}
                  flexGrow={1}
                  cellRenderer={({ rowData }: { rowData: Venture }) => {
                    return (
                      <TableCell truncated>
                        {rowData.mentorsVoted?.length ? (
                          <Inline gap={5} align={'center'}>
                            <TooltipMenu
                              items={rowData.mentorsVoted.map((v) => (
                                <Text variant='normal' key={v}>
                                  {v}
                                </Text>
                              ))}>
                              <span className={classes.likedIconWrapper}>
                                <ThumbUp className={classes.likedIcon} />
                              </span>
                            </TooltipMenu>
                            <Text variant='normal'>
                              {rowData.mentorsVoted.length}
                            </Text>
                          </Inline>
                        ) : null}
                      </TableCell>
                    );
                  }}
                />
              )}
              <Column
                dataKey='status'
                className={classes.column}
                width={90}
                maxWidth={90}
                minWidth={90}
                flexGrow={1}
                headerRenderer={() => <TableHeadCell>Status</TableHeadCell>}
                cellRenderer={({ cellData }) => {
                  const currentVariant = getColorStatus(cellData);
                  return (
                    <StatusBadge status={cellData} variant={currentVariant} />
                  );
                }}
              />
              {!isMobile() && (
                <Column
                  dataKey='cohortId'
                  className={classes.column}
                  width={90}
                  maxWidth={90}
                  minWidth={90}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Group</TableHeadCell>}
                  cellRenderer={({ cellData }) => {
                    const cohort = cohorts.find(
                      (i) => i.value && i.value === cellData,
                    );
                    return (
                      cohort && (
                        <Tooltip
                          title={cohort.label}
                          enterTouchDelay={0}
                          placement={'top'}>
                          <div>
                            <Dot color={getColorByUnicode(cohort.label)} />
                          </div>
                        </Tooltip>
                      )
                    );
                  }}
                />
              )}
              {!isMobile() &&
                (filterValue?.key === 'status' &&
                filterValue?.value === 'Applicant' ? (
                  <Column
                    dataKey='dateOfCreation'
                    className={cn(classes.column, classes.appliedOnColumn)}
                    headerClassName={classes.appliedOnColumn}
                    width={120}
                    minWidth={100}
                    headerRenderer={() => (
                      <TestId testId={'applied-on-column'}>
                        <TableHeadCell>Applied On</TableHeadCell>
                      </TestId>
                    )}
                    cellRenderer={({
                      cellData,
                      rowData,
                    }: {
                      cellData?: string;
                      rowData: any;
                    }) => (
                      <TableCell>
                        <Typography variant={'body2'}>
                          {cellData ? formatDate(cellData) : '-'}
                        </Typography>
                      </TableCell>
                    )}
                  />
                ) : (
                  <Column
                    dataKey='assigned'
                    className={cn(classes.column, classes.assignedColumn)}
                    headerClassName={classes.assignedColumn}
                    width={70}
                    minWidth={70}
                    headerRenderer={() => (
                      <TableHeadCell>{rb('mentors-u')}</TableHeadCell>
                    )}
                    cellRenderer={({ cellData, rowData }) => (
                      <TestId testId={'assigned-column'}>
                        <TableCell>
                          <div className={classes.ventureIssues}>
                            {Array.isArray(rowData.tags) && (
                              <div className={classes.issueIcon}>
                                <VentureIssueTableInfo
                                  ventureId={rowData.id}
                                  ventureTags={rowData.tags}>
                                  <WarningIcon
                                    className={classes.warningIcon}
                                    data-testid='venture-table-issue-icon'
                                  />
                                </VentureIssueTableInfo>
                              </div>
                            )}
                            {cellData ? (
                              <MentorTableInfo
                                ventureId={rowData.id}
                                initialMentors={loadedMentorsById[rowData.id]}
                                onLoaded={handleMentorsLoaded(rowData.id)}>
                                <MaterialLink
                                  onClick={stopPropagation}
                                  component={Link}
                                  to={
                                    hasRole(Role.Founder)
                                      ? `${getRoutePath(
                                          Pages.MENTORS,
                                        )}?filter=${encodeQuery({
                                          venture: rowData.id,
                                        })}`
                                      : getRoutePath(Pages.MENTORS_VENTURES, {
                                          ventureFilter: encodeQuery({
                                            ventureName: rowData.ventureName,
                                            id: rowData.id,
                                          }),
                                        })
                                  }>
                                  {cellData}
                                </MaterialLink>
                              </MentorTableInfo>
                            ) : (
                              <div>–</div>
                            )}
                          </div>
                        </TableCell>
                      </TestId>
                    )}
                  />
                ))}
              {mentor && (
                <Column
                  dataKey='id'
                  className={cn(classes.column, classes.showInterestColumn)}
                  headerClassName={classes.showInterestColumn}
                  width={isMobile() ? 60 : 110}
                  minWidth={isMobile() ? 60 : 110}
                  headerRenderer={() => (
                    <TableHeadCell>Show Interest</TableHeadCell>
                  )}
                  cellRenderer={({ rowData }: { rowData: Venture }) => (
                    <TestId testId={'show-interest-column'}>
                      <TableCell className={classes.showInterest}>
                        <Like
                          liked={
                            rowData.mentorsVoted?.includes(
                              fullName(mentor.firstName, mentor.lastName),
                            ) ?? false
                          }
                          onChange={() => handleVote(rowData.id)}
                        />
                      </TableCell>
                    </TestId>
                  )}
                />
              )}
              {!isMobile() && !hasRole(Role.Founder) && (
                <Column
                  dataKey='primaryFounder'
                  className={cn(classes.column, classes.founderColumn)}
                  headerClassName={classes.founderColumn}
                  width={184}
                  minWidth={isTablet() ? 50 : 184}
                  maxWidth={288}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Founder</TableHeadCell>}
                  cellRenderer={({ cellData, rowData }) => (
                    <TableCell truncated>
                      {cellData ? (
                        <MaterialLink
                          onClick={stopPropagation}
                          component={Link}
                          to={{
                            pathname: getRoutePath(Pages.FOUNDER_DETAILS, {
                              founderId: rowData.primaryFounderId,
                            }),
                            state: {
                              prevPath: currentPath,
                            },
                          }}>
                          {cellData}
                        </MaterialLink>
                      ) : (
                        <div>–</div>
                      )}
                    </TableCell>
                  )}
                />
              )}
              {!isTablet() ? (
                filtersFromURL.sessions === 'noevents' ? (
                  <Column
                    dataKey='inactiveDays'
                    className={cn(classes.column, classes.inactiveDaysColumn)}
                    headerClassName={classes.inactiveDaysColumn}
                    width={180}
                    minWidth={180}
                    maxWidth={3}
                    headerRenderer={() => (
                      <TableHeadCell>Days Since Met</TableHeadCell>
                    )}
                    cellRenderer={({ cellData }: { cellData?: number }) => {
                      const hasValue = cellData !== undefined && cellData >= 0;

                      return (
                        <TableCell onClick={stopPropagation} truncated>
                          <Text>{hasValue ? cellData : 'Haven’t met yet'}</Text>
                        </TableCell>
                      );
                    }}
                  />
                ) : (
                  <Column
                    dataKey='url'
                    className={cn(classes.column, classes.urlColumn)}
                    headerClassName={classes.urlColumn}
                    width={180}
                    minWidth={180}
                    maxWidth={3}
                    flexGrow={1}
                    headerRenderer={() => (
                      <TableHeadCell>Website</TableHeadCell>
                    )}
                    cellRenderer={({
                      cellData,
                    }: {
                      cellData?: string | null;
                    }) => (
                      <TableCell onClick={stopPropagation} truncated>
                        {cellData ? (
                          <MaterialLink href={urlfy(cellData)} target='_blank'>
                            {cellData}
                          </MaterialLink>
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    )}
                  />
                )
              ) : null}
              {!isTablet() && (
                <Column
                  dataKey='actions'
                  className={cn(classes.column, classes.justifyContentEnd)}
                  minWidth={240}
                  width={240}
                  headerRenderer={() => <div />}
                  cellRenderer={({ rowData }) => (
                    <TableCell onClick={stopPropagation}>
                      <div className={classes.actionsCell}>
                        {hasAccessToAction('session.create') && (
                          <Tooltip title='Create Session'>
                            <IconButton
                              data-testid='icon-button-create-session'
                              component={Link}
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              to={{
                                pathname: getRoutePath(Pages.NEW_SESSIONS),
                                state: {
                                  prevPath: currentPath,
                                  ventureId: rowData.id,
                                },
                              }}>
                              <EventAvailableIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {hasAccessToAction('venture.details.update') && (
                          <Modal
                            title='New assignment'
                            caption={`${rowData.ventureName}`}
                            contentRenderer={({ handleClose }) => (
                              <AssignVenturesForm
                                ventureId={rowData.id}
                                loading={isAssigningVenture[rowData.id]}
                                onLoaded={handleMentorsLoaded(rowData.id)}
                                mentors={loadedMentorsById[rowData.id]}
                                onSuccess={handleAssingSuccessForm(
                                  rowData.id,
                                  handleClose,
                                )}
                                onCancel={handleClose}
                              />
                            )}
                            buttonRenderer={({ onClick }) => (
                              <Tooltip title='Assign'>
                                <IconButton
                                  data-testid='icon-button-assign'
                                  onClick={onClick}
                                  className={CLASS_TRACKING.LIST_QUICK_ACTION}>
                                  <HowToRegIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            width={720}
                          />
                        )}
                        {hasAccessToAction(
                          'venture.details.additional.update',
                        ) || hasAccessToAction('venture.additional.update') ? (
                          <Tooltip title='Edit'>
                            <IconButton
                              data-testid='icon-button-edit'
                              component={Link}
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              to={{
                                pathname: getRoutePath(Pages.VENTURE_DETAILS, {
                                  ventureId: rowData.id,
                                }),
                                state: {
                                  prevPath: currentPath,
                                },
                              }}>
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                        ) : (
                          <Tooltip title='View'>
                            <IconButton
                              data-testid='icon-button-view'
                              component={Link}
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              to={{
                                pathname: getRoutePath(Pages.VENTURE_DETAILS, {
                                  ventureId: rowData.id,
                                }),
                                state: {
                                  prevPath: currentPath,
                                },
                              }}>
                              <Visibility />
                            </IconButton>
                          </Tooltip>
                        )}
                        {!isMentor && (
                          <Tooltip title='Notes'>
                            <IconButton
                              data-testid='icon-button-notes'
                              className={CLASS_TRACKING.LIST_QUICK_ACTION}
                              component={Link}
                              to={getRoutePath(Pages.VENTURE_NOTES, {
                                ventureId: rowData.id,
                              })}>
                              <PlaylistAddIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {hasAccessToAction('venture.updateProfile') && (
                          <ConfirmButton
                            title='Send Single Profile Update Request'
                            loading={isSendingProfileUpdate}
                            body='Your are about to send a temporary link to allow participants to update their profile. The link can work only 1 time, and is available for 48 hours only.'
                            successProps={{
                              btnLabel: 'Ok',
                              onSuccess: (onClose) =>
                                handleSendVentureUpdate(onClose, rowData.id),
                            }}
                            cancelProps={{
                              btnLabel: 'Cancel',
                            }}
                            buttonRenderer={({ onClick }) => (
                              <Tooltip title='Send a link to request a profile update'>
                                <IconButton onClick={onClick}>
                                  <AccountCircleIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          />
                        )}
                        {hasAccessToAction('venture.updateProfile') && (
                          <ReportLinkDialog ventureIds={[rowData.id]}>
                            {({ open }) => (
                              <Tooltip
                                title='Send a link to request a quarterly report'
                                placement='bottom-end'>
                                <IconButton onClick={open}>
                                  <BarChartIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </ReportLinkDialog>
                        )}
                      </div>
                    </TableCell>
                  )}
                />
              )}
            </Table>
          </div>
        ) : (
          <>
            {isEmptyState ? (
              <div className={classes.emptyBlock}>
                <div className={classes.emptyState}>
                  <Text variant='normal' testid='venture-table-no-items'>
                    {isEmpty(filtersFromURL) && hasRole(Role.Founder)
                      ? 'There are currently no active ventures assigned to you. It could be simply a work in progress. If this is unexpected, please reach out to your Program Administrator to clarify.'
                      : 'This panel contains a list of ventures'}
                    {!isEmpty(filtersFromURL) &&
                      (() => {
                        const filterItems = Object.entries(filtersFromURL);
                        return (
                          <>
                            {' '}
                            filtered by{' '}
                            {filterItems.map(([key, value], index) => {
                              const getReadableValue = (
                                key: FilterType,
                                value: string,
                              ) => {
                                switch (key) {
                                  case 'cohort':
                                    return (
                                      cohorts.find(
                                        (item) => item.value === value,
                                      )?.label ?? value
                                    );
                                  case 'mentor':
                                    const mentor = decodeQuery(value) as {
                                      id: string;
                                    };
                                    const mentorName = mentor?.id
                                      ? mentor.id
                                      : '';
                                    return mentorName;
                                  default:
                                    return value;
                                }
                              };
                              const filterAlias = getFilterAlias(
                                key as FilterType,
                              );
                              const filterReadableValue = getReadableValue(
                                key as FilterType,
                                value,
                              );
                              return (
                                <Text variant='normal' bold key={index}>
                                  {filterAlias}: {filterReadableValue}
                                  {index === filterItems.length - 1 ? '' : ', '}
                                </Text>
                              );
                            })}
                          </>
                        );
                      })()}
                    <br />
                    {hasAccessToAction('venture.create') &&
                      'We were not able to find information you requested, but feel free to add something new!'}
                  </Text>
                  {hasAccessToAction('venture.create') && (
                    <MaterialButton
                      component={Link}
                      to={Pages.NEW_VENTURE}
                      className={classes.emptyStateBtn}
                      startIcon={<AddIcon />}
                      variant='contained'
                      color='primary'>
                      Add Venture
                    </MaterialButton>
                  )}
                </div>
              </div>
            ) : (
              <div className={classes.emptyBlock}>
                {isLoading && <CircularProgress size={36} color='primary' />}
                {!isLoading && !!loadError && (
                  <AlertState type='error'>{loadError}</AlertState>
                )}
              </div>
            )}
          </>
        )}
        {!!perPage && (
          <TableFooter
            page={page}
            onPageChange={handlePageChange}
            disabled={isLoading}
            isLoading={isNextPageLoading}
            hasNextPage={ventures.length > 0 && hasNextPage}
          />
        )}
      </div>
    </Paper>
  );
}

export default VenturesTable;
