import { makeStyles } from '@material-ui/core';
import cn from 'classnames';

const useStyles = makeStyles((theme) => ({
  formGroup: {
    display: 'flex',
    width: 'calc(100% + 16px)',
    marginLeft: -16,

    '& > *': {
      margin: '0 0 0 16px !important',
      width: '100%',
    },

    '& + &': {
      marginTop: '32px',
    },
  },
  formButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    columnGap: '15px',
  },
  justifyLeft: {
    justifyContent: 'flex-start',
  },
  justifyRight: {
    justifyContent: 'flex-end',
  },

  justifyBetween: {
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
  },
  checkboxGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  mobileFormGroup: {
    flexDirection: 'column',
    rowGap: 32,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row !important',
      rowGap: 0,
      width: 'calc(100% + 16px)',
    },
  },
  columnFormGroup: {
    flexDirection: 'column',
  },
}));

export function FormGroup({
  children,
  mobile,
  column,
  className,
  id,
}: {
  children?: React.ReactNode;
  mobile?: boolean;
  column?: boolean;
  className?: string;
  id?: string;
}) {
  const classes = useStyles();

  return (
    <div
      id={id}
      className={cn(classes.formGroup, className, {
        [classes.mobileFormGroup]: mobile,
        [classes.columnFormGroup]: column,
      })}>
      {children}
    </div>
  );
}

export function FormButtons({
  className,
  children,
  justify = 'right',
}: {
  className?: string;
  children?: React.ReactNode;
  justify?: 'left' | 'right' | 'space-between' | 'center';
}) {
  const classes = useStyles();

  return (
    <div
      className={cn(
        classes.formButtons,
        className,
        classes[getJustifications(justify)],
      )}>
      {children}
    </div>
  );
}

export function CheckboxGroup({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) {
  const classes = useStyles();
  return <div className={cn(classes.checkboxGroup, className)}>{children}</div>;
}

function getJustifications(
  v: 'left' | 'right' | 'space-between' | 'center',
): 'justifyRight' | 'justifyBetween' | 'justifyLeft' | 'center' {
  switch (v) {
    case 'space-between':
      return 'justifyBetween';
    case 'right':
      return 'justifyRight';
    case 'left':
      return 'justifyLeft';
    case 'center':
      return 'center';
  }
}
