import { StringDate } from '../utils/date/StringDate';
import { axiosRequest } from './instance';

export enum NotificationType {
  NEW_SESSION_NOTE = 'NEW_SESSION_NOTE',
  NEW_SESSION_CREATED = 'NEW_SESSION_CREATED',
  NEW_WIZARD_SCHEDULED = 'NEW_WIZARD_SCHEDULED',
  SESSION_AGENDA_UPDATE = 'SESSION_AGENDA_UPDATE',
  SESSION_UPDATE = 'SESSION_UPDATE',
  SESSION_CANCELLATION = 'SESSION_CANCELLATION',
  NEW_VENTURE_NOTE = 'NEW_VENTURE_NOTE',
  MENTOR_ASSIGNMENT = 'MENTOR_ASSIGNMENT',
  MENTOR_REMOVAL = 'MENTOR_REMOVAL',
  NEW_VENTURE_APPLICATION = 'NEW_VENTURE_APPLICATION',
  NEW_MENTOR_APPLICATION = 'NEW_MENTOR_APPLICATION',
  GATHERING_INVITE_FAILURE = 'GATHERING_INVITE_FAILURE',
}

export enum NotificationStatus {
  NEW = 'NEW',
  READ = 'READ',
}

export interface Notification {
  id: string;
  userId: string;
  ttl: string;
  type: NotificationType;
  status: NotificationStatus;
  reference: string;
  message: string;
  created: StringDate;
}

const notificationsAPI = {
  async getNotifications(): Promise<Notification[]> {
    try {
      const res = await axiosRequest.get<Notification[]>({
        url: '/notifications',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getNotificationsAvailability() {
    try {
      const res = await axiosRequest.get<boolean>({
        url: '/notifications/new/availability',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async deleteNotifications() {
    try {
      const res = await axiosRequest.delete<boolean>({
        url: '/notifications',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async deleteNotification(id: string) {
    try {
      const res = await axiosRequest.delete<boolean>({
        url: `/notifications/${id}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
};

export default notificationsAPI;
