import { CircularProgress, makeStyles, Paper } from '@material-ui/core';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { FC, useEffect, useState } from 'react';
import notificationsApi, { Notification } from '../../../api/notifications';
import { COLORS } from '../../../theme/variables';
import { Button, Text } from '../../common';
import NotificationCard from './notification-card';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
    maxHeight: '50vh',
    padding: 0,
    boxSizing: 'border-box',
    overflow: 'auto',

    '&::-webkit-scrollbar': {
      width: 12,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,
      borderRadius: 8,

      '&:hover': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
      },
    },

    '&::-webkit-scrollbar-track': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_45,
      borderRadius: 8,
    },
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
    padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
  },
  cardsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  cardsContainer: {
    padding: `${theme.spacing(2)}px 0`,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    width: '100%',
  },
  stickyBottom: {
    position: 'sticky',
    bottom: 0,
    backgroundColor: COLORS.COLOR_ADDITIONAL_WHITE,
  },
  resetButton: {
    paddingTop: 4,
    paddingBottom: 4,
    borderRadius: 0,
  },
}));

const NotificationsList: FC = () => {
  const classes = useStyles();

  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleReset = async () => {
    try {
      setIsLoading(true);
      await notificationsApi.deleteNotifications();
      setNotifications([]);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        setIsLoading(true);
        const newNotifications = await notificationsApi.getNotifications();
        setNotifications(newNotifications);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchNotifications();
  }, []);

  return (
    <Paper className={classes.paper}>
      {isLoading ? (
        <div className={classes.emptyContainer}>
          <CircularProgress size={24} color='primary' />
        </div>
      ) : !notifications.length ? (
        <div className={classes.emptyContainer}>
          <NotificationsOffIcon color='disabled' fontSize='large' />
          <Text variant='normal' color={COLORS.COLOR_TEXT_LIGHTENED_20}>
            You have no notifications
          </Text>
        </div>
      ) : (
        <div className={classes.cardsWrapper}>
          <div className={classes.cardsContainer}>
            {notifications.map((notification) => (
              <NotificationCard
                key={notification.id}
                notification={notification}
                onRemove={() => {
                  setNotifications(
                    notifications.filter((n) => n.id !== notification.id),
                  );
                }}
              />
            ))}
          </div>
          <div className={classes.stickyBottom}>
            <Button
              variant='contained'
              onClick={handleReset}
              fullWidth
              className={classes.resetButton}>
              Reset All
            </Button>
          </div>
        </div>
      )}
    </Paper>
  );
};

export default NotificationsList;
