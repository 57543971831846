import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import HelpIcon from '@material-ui/icons/Help';
import classNames from 'classnames';
import { COLORS } from '../../theme/variables';

export interface SelectOptions {
  label: string;
  value?: string;
  isCategory?: boolean;
}

export interface Props {
  onChange: (event: any) => void;
  onBlur?: (event: any) => void;
  className?: string;
  value?: string;
  options: SelectOptions[];
  label?: string;
  readOnly?: boolean;
  testid?: string;
  error?: boolean;
  disabled?: boolean;
  tooltip?: string;
}

const useStyles = makeStyles({
  formControl: {
    margin: 0,
    minWidth: 120,
  },
  errorLabel: {
    color: COLORS.COLOR_RED_BASE,
  },
  tooltip: {
    position: 'absolute',
    top: '50%',
    right: '32px',
    transform: 'translateY(-50%)',
  },
  category: {
    fontSize: 16,
    color: COLORS.COLOR_TEXT_BASE,
    fontWeight: 700,
    background: COLORS.COLOR_ADDITIONAL_WHITE,
  },
});

export function CustomSelect({
  onChange,
  onBlur = () => {},
  value,
  options,
  label,
  className,
  readOnly = false,
  testid,
  error = false,
  disabled = false,
  tooltip,
}: Props) {
  const classes = useStyles();

  return (
    <FormControl variant='outlined' className={classes.formControl}>
      <InputLabel
        className={classNames({
          [classes.errorLabel]: error,
        })}
        id={`select-label-${label}`}>
        {label}
      </InputLabel>
      <Select
        error={error}
        data-testid={testid}
        labelId={`select-label-${label}`}
        readOnly={readOnly}
        disabled={disabled}
        id={`select-${label}`}
        className={className}
        label={label}
        value={value}
        onChange={onChange}
        IconComponent={readOnly ? () => null : undefined}
        onBlur={onBlur}
        MenuProps={{ disableScrollLock: true }}
        aria-invalid={error}>
        {options &&
          options.length > 0 &&
          options.map((option) => {
            if (option.isCategory) {
              return (
                <ListSubheader className={classes.category}>
                  {option.label}
                </ListSubheader>
              );
            } else {
              return (
                <MenuItem
                  style={{ maxWidth: '650px' }}
                  key={option.value}
                  value={option.value}
                  data-type={'select-option'}
                  data-test={`select-option-${option.label}`}
                  data-testid={`select-option-${option.value}`}>
                  {option.label}
                </MenuItem>
              );
            }
          })}
      </Select>
      {tooltip && (
        <Tooltip title={tooltip} className={classes.tooltip}>
          <IconButton>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
    </FormControl>
  );
}

export default CustomSelect;
