import { Badge, Fade, IconButton, makeStyles, Popper } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import notificationsApi from '../../../api/notifications';
import NotificationsList from './notifications-list';

const useStyles = makeStyles(() => ({
  popper: {
    width: 300,
    maxWidth: '90%',
  },
}));

function NotificationBell() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<
    (EventTarget & HTMLButtonElement) | null
  >(null);
  const [hasNotifications, setHasNotifications] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(true);

  const popupRef = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);
  const id = open ? 'spring-popper' : undefined;

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setHasNotifications(false);
  };

  useEffect(() => {
    if (Boolean(anchorEl)) {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          (popupRef.current &&
            popupRef.current.contains(event.target as Node)) ||
          (anchorEl && anchorEl.contains(event.target as Node))
        ) {
          return;
        }
        setAnchorEl(null);
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [anchorEl]);

  useEffect(() => {
    const checkNotificationsAvailability = async () => {
      try {
        const notificationsAvailability =
          await notificationsApi.getNotificationsAvailability();
        setHasNotifications(notificationsAvailability);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    const time = 5 * 60 * 1000; // 5 minutes
    const checkNotificationsInterval = setInterval(
      checkNotificationsAvailability,
      time,
    );
    checkNotificationsAvailability();

    return () => clearInterval(checkNotificationsInterval);
  }, []);

  return (
    <>
      <IconButton
        disabled={isLoading}
        aria-describedby={id}
        type='button'
        onClick={handleClick}>
        <Badge variant={hasNotifications ? 'dot' : undefined} color='error'>
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        disablePortal
        placement='bottom-end'
        className={classes.popper}
        ref={popupRef}>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps}>
            <NotificationsList />
          </Fade>
        )}
      </Popper>
    </>
  );
}

export default NotificationBell;
