import { CircularProgress, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import foundersAPI, { Founder } from '../../api/founders';
import { getAnonymously } from '../../api/tenants/QuestionTemplates';
import { Question } from '../../api/tenants/QuestionTemplates/Question';
import { Role } from '../../api/user/Role';
import venturesAPI from '../../api/ventures';
import * as Answers from '../../api/ventures/quarterlyrecords/customanswers';
import { Create } from '../../api/ventures/quarterlyrecords/customanswers/Answer';
import { VentureQuarterRecord } from '../../api/ventures/quarterlyrecords/types/VentureQuarterRecord';
import { VentureId } from '../../api/ventures/types/Venture';
import { SnackMessage, Text } from '../../components/common';
import ReportVentureQuarterForm, {
  FormValues,
  FormInitialValues,
} from '../../components/forms/report-venture-quarter-form';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { isNonEmptyArray } from '../../utils/Array/NonEmptyArray';

enum VentureQuarterReportStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  NO_FOUNDERS = 'NO_FOUNDERS',
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '560px',
    maxWidth: '100%',
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    width: 300,
    maxWidth: '100%',
    padding: '32px',
    boxSizing: 'border-box',
  },

  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  errorText: {
    textAlign: 'center',
  },
}));

interface Data {
  report: VentureQuarterRecord;
  questions: Question[];
}

const formatValuesRequest = (
  values: FormValues,
  details: Data,
): {
  record: VentureQuarterRecord;
  answers: Create[];
} => {
  return {
    record: {
      ...details.report,
      submitter: values.submitter,
      fte: Number(values.fte),
      revenue: Number(values.revenue),
      equityRaised: Number(values.equityRaised),
      equityComments: values.equityComments,
      grantsRaised: Number(values.grantsRaised),
      grantsComments: values.grantsComments,
      crowdFunding: Number(values.crowdFunding),
      crowdComments: values.crowdComments,
      loans: Number(values.loans),
      loansComments: values.loansComments,
      other: Number(values.other),
      otherComments: values.otherComments,
      rating: Number(values.rating) - 1,
    },
    answers: details.questions
      .map((q) => [q, values.questions[q.id as string]] as const)
      .filter((v): v is [Question, string | number] => v[1] !== undefined)
      .map(([q, v]): [Question, string] => [q, v.toString()])
      .map(
        ([q, v]): Create => ({
          answer: v,
          description: q.description,
          ventureQuarterlyReportRecordId: details.report.id,
        }),
      ),
  };
};

const getInitialValues = (
  { report, questions }: Data,
  defaultEmail?: string,
): FormInitialValues => {
  let rating = report.rating;

  if (report.status === 'SUBMITTED' && typeof rating === 'number') {
    rating = rating + 1;
  }

  return {
    ventureName: String(report.ventureName || ''),
    submitter: defaultEmail || String(report.submitter || ''),
    fte: String(report.fte || ''),
    revenue: String(report.revenue || ''),
    equityRaised: String(report.equityRaised || ''),
    equityComments: String(report.equityComments || ''),
    grantsRaised: String(report.grantsRaised || ''),
    grantsComments: String(report.grantsComments || ''),
    crowdFunding: String(report.crowdFunding || ''),
    crowdComments: String(report.crowdComments || ''),
    loans: String(report.loans || ''),
    loansComments: String(report.loansComments || ''),
    other: String(report.other || ''),
    otherComments: String(report.otherComments || ''),
    rating: String(rating || ''),
    questions: questions.reduce((acc: Record<string, undefined>, q) => {
      acc[q.id] = undefined;
      return acc;
    }, {}),
  };
};

const errorMessage =
  'Unfortunately an error occurred while loading the report. Please try again in a couple of minutes. If the problem persists please share the URL with the support team at support@tractionfive.com';

function VentureQuarterlyReportFormPage() {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { ventureId } = useParams<{ ventureId: VentureId }>();
  const { hasRole, tokenData } = useContext(UserContext);

  const [details, setDetails] = useState<Data>();
  const [statusForm, setStatusForm] = useState<VentureQuarterReportStatus>(
    VentureQuarterReportStatus.LOADING,
  );
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const [assignedFounders, setAssignedFounders] = useState<Founder[]>([]);

  const initialValues = useMemo(() => {
    if (details) {
      const submitterEmail = hasRole(Role.Founder)
        ? tokenData?.email ?? ''
        : '';
      return getInitialValues(details, submitterEmail);
    }
    return undefined;
  }, [details, hasRole, tokenData?.email]);

  const emailOptions = useMemo(() => {
    return (hasRole(Role.Admin) || hasRole(Role.Manager)) &&
      assignedFounders &&
      assignedFounders?.length > 0
      ? assignedFounders.map((founder) => {
          return {
            value: founder.email,
            label: [
              founder.firstName,
              founder.lastName,
              `(${founder.email})`,
            ].join(' '),
          };
        })
      : undefined;
  }, [assignedFounders, hasRole]);

  const handleSubmit = useCallback(
    async (_values: FormValues) => {
      if (!details) {
        return;
      }

      const values = formatValuesRequest(_values, details);

      try {
        setIsLoadingSaveForm(true);
        await venturesAPI.sendVentureQuarterReport(values.record);
        // Should not use Promise.all, as answers must be submitted after the report submit
        await (isNonEmptyArray(values.answers)
          ? Answers.create(values.answers)
          : Promise.resolve([]));

        enqueueSnackbar('Venture Quarterly Report submitted successfully', {
          variant: 'success',
        });
        history.push(
          getRoutePath(Pages.VENTURE_DETAILS, {
            ventureId,
          }),
        );
        setIsLoadingSaveForm(false);
      } catch (e: any) {
        setIsLoadingSaveForm(false);
        const messageError =
          e.response?.data?.message || 'Internal server error';
        enqueueSnackbar('An error occurred while saving your report', {
          variant: 'error',
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          style: { whiteSpace: 'pre-line' },
        });
      }
    },
    [details, enqueueSnackbar, history, ventureId],
  );

  useEffect(() => {
    const loadDetails = async (ventureId: VentureId) => {
      try {
        setStatusForm(VentureQuarterReportStatus.LOADING);

        const report = await venturesAPI.getQuarterlyRecordByVentureId(
          ventureId,
        );
        const questions = await getAnonymously(report.tenantId);

        if (hasRole(Role.Admin) || hasRole(Role.Manager)) {
          const loadedFounders =
            await foundersAPI.getFoundersDetailsByVentureId(ventureId);
          setAssignedFounders(loadedFounders);
        }

        setDetails({ report, questions });
        setStatusForm(VentureQuarterReportStatus.LOADED);
      } catch (e: any) {
        enqueueSnackbar(errorMessage, {
          variant: 'error',
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage:
                e.response?.data?.message || 'Internal server error',
            }),
          style: { whiteSpace: 'pre-line' },
        });
      }
    };

    loadDetails(ventureId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ventureId]);

  if (statusForm === VentureQuarterReportStatus.LOADING || !details) {
    return (
      <div className={classes.stateContainer}>
        <CircularProgress size={54} color='primary' />
      </div>
    );
  }

  if (statusForm === VentureQuarterReportStatus.NO_FOUNDERS) {
    return (
      <div
        data-testid={`report-page-${statusForm}`}
        className={classes.stateContainer}>
        <div className={classes.block}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.errorText}>
            <Text variant='normal'>
              You cannot submit Venture Quarterly Report for this venture since
              no Founders are assigned.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.formContainer}>
      <ReportVentureQuarterForm
        initialValues={initialValues}
        onSubmit={handleSubmit}
        loading={isLoadingSaveForm}
        reportDate={{
          quarter: details.report.quarterNumber,
          year: details.report.year,
        }}
        questions={details.questions}
        emailOptions={emailOptions}
        isStickySubmit
        hasBeforeUnload
      />
    </div>
  );
}

export default VentureQuarterlyReportFormPage;
