import {
  CircularProgress,
  IconButton,
  Paper,
  Tooltip,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { makeStyles } from '@material-ui/styles';
import { add } from 'date-fns';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import eventsAPI from '../../api/events';
import { Founder } from '../../api/founders';
import { Mentor } from '../../api/mentors';
import {
  Button,
  Dialog,
  FormButtons,
  SnackMessage,
  Text,
} from '../../components/common';
import SessionDetailsForm, {
  ParsedFormValues,
} from '../../components/forms/session-details';
import BaseLayout from '../../components/layout/base-layout';
import { Header } from '../../components/layout/main-layout';
import PageTabs from '../../components/sessions/page-tabs';
import { IssueContext } from '../../contexts/issue-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { ProtectedRouteProps } from '../../router/type';
import { massAsyncRequest } from '../../utils/api';
import { formatDateTime, formatDateToRFC } from '../../utils/date';
import { TYPE_ISSUE } from '../../utils/form';
import { getMessangeCreateIssue } from '../../utils/functions';

type IssueType = 'NO_AGENDA' | 'NO_REPORT' | 'NO_NOTES' | 'UNCONFIRMED';
const sessionStatusArchived = 'ARCHIVED';

const useStyles = makeStyles({
  container: {
    flexGrow: 1,
    padding: 0,
  },
  content: {
    padding: 40,
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
});

export interface ValueSubmit {
  parsedValues: ParsedFormValues;
  founders: Founder[];
  mentors: Mentor[];
}

function NewSessionPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const location = useLocation<{ prevPath?: string }>();
  const [prevPath] = useState(location.state?.prevPath);
  const { enqueueSnackbar } = useSnackbar();
  const { channels, currentEmail, hasAccessToAction } = useContext(UserContext);
  const { issue: issueFullParams } = useContext(IssueContext);

  const history = useHistory();
  const [isCreating, setIsCreating] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [prevValue, setPrevValue] = useState<ValueSubmit>();

  const handleSubmitForm = useCallback(
    async (
      parsedValues: ParsedFormValues,
      founders: Founder[],
      mentors: Mentor[],
    ) => {
      try {
        const values = parsedValues.values;
        const isStatusArchived = values.status === sessionStatusArchived;
        const issues = isStatusArchived ? [] : parsedValues.issues;

        setIsCreating(true);
        const {
          eventId,
          eventChannelId,
          scheduled,
          suggestedEventDateTime,
          event: createdSession,
        } = await eventsAPI.create({
          ...values,
        });

        const selectedChannel = channels?.find(
          (channel) => channel.id === eventChannelId,
        );

        if (eventId) {
          const addFoundersRequests = founders.map(
            (founder) => () =>
              eventsAPI.addFounder(createdSession.id, founder.id),
          );

          const addMentorsRequests = mentors.map(
            (mentor) => () => eventsAPI.addMentor(createdSession.id, mentor.id),
          );

          const addIssueRequests = issues.map(
            (issueId) => () =>
              eventsAPI.setTags({
                eventId,
                creationDate: formatDateToRFC(new Date()),
                tagId: issueId,
              }),
          );

          const notesAddIssueRequest = issues.map((issueId) => {
            const codeIssue = issueFullParams?.find(
              ({ id }) => id === issueId,
            )?.code;
            if (codeIssue && TYPE_ISSUE.includes(codeIssue)) {
              const contents = getMessangeCreateIssue(
                codeIssue as IssueType,
                currentEmail || '',
              );
              return () => eventsAPI.createNote({ contents, eventId });
            }
            return () => {};
          });

          await Promise.all([
            massAsyncRequest(addFoundersRequests),
            massAsyncRequest(addMentorsRequests),
            massAsyncRequest(addIssueRequests),
            massAsyncRequest(notesAddIssueRequest),
          ]);

          history.push(
            getRoutePath(Pages.SESSIONS_DETAILS, {
              sessionId: createdSession.id,
            }),
          );
        }

        const preName = selectedChannel?.channelName || '';
        const name =
          preName.length > 30 ? `${preName.slice(0, 30)}...` : preName;
        const message = [
          'Your request to schedule a session was completed successfully.',
          `${
            name
              ? `The channel that will be used for this session is ${name}`
              : ''
          }`,
        ]
          .join('\n')
          .trim();

        if (scheduled) {
          enqueueSnackbar(message, {
            variant: 'success',
            style: { whiteSpace: 'pre-line' },
          });
        } else {
          setIsCreating(false);

          const startDate = formatDateToRFC(new Date(suggestedEventDateTime));
          const endDate = formatDateToRFC(
            add(new Date(suggestedEventDateTime), {
              hours: 1,
              minutes: 30,
            }),
          );

          const newValue = {
            values: { ...parsedValues.values, start: startDate, end: endDate },
            issues: [],
          };

          setPrevValue({
            parsedValues: newValue,
            founders,
            mentors,
          });
          setOpenModal(true);
        }
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the session. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );
        setIsCreating(false);
        throw e;
      }
    },
    [history, enqueueSnackbar, channels, issueFullParams, currentEmail],
  );

  const resendingForm = () => {
    if (prevValue) {
      handleSubmitForm(
        prevValue.parsedValues,
        prevValue.founders,
        prevValue.mentors,
      );
    }
  };

  return (
    <BaseLayout user={user} fullHeight>
      <Header
        title='New Session'
        backLink={
          <Tooltip title='Back to Sessions'>
            <IconButton
              data-testid='button-back-sessions'
              component={Link}
              to={prevPath || Pages.SESSIONS}>
              <ArrowBackIcon />
            </IconButton>
          </Tooltip>
        }
      />
      <Paper className={classes.container} elevation={1}>
        <PageTabs />
        <div className={classes.content}>
          <SessionDetailsForm
            loading={isCreating}
            onSubmit={handleSubmitForm}
            defaultLocation={user.defaultPhysicalLocation ?? undefined}
            timeZone={user.timeZone}
            isEditable={hasAccessToAction('session.create')}
          />
        </div>
      </Paper>
      <Dialog
        open={openModal}
        setOpen={setOpenModal}
        title='Suggested time'
        width={500}
        contentRenderer={({ handleClose }) => (
          <div>
            <Text variant='normal'>
              We were not able to schedule the event at the time you requested,
              but we suggest to start this event at the next available slot
              which is{' '}
              {prevValue?.parsedValues?.values?.start &&
                formatDateTime(prevValue?.parsedValues?.values?.start)}
              . Apply recommended changes?
            </Text>

            <FormButtons className={classes.formButtons}>
              <Button onClick={handleClose} variant='outlined'>
                Cancel
              </Button>
              <Button
                disabled={isCreating}
                data-testid='mentor-assignment-apply'
                onClick={resendingForm}>
                {isCreating ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Apply'
                )}
              </Button>
            </FormButtons>
          </div>
        )}
      />
    </BaseLayout>
  );
}

export default NewSessionPage;
