import {
  ClickAwayListener,
  Grow,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {
  FC,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isMobile } from '../../utils/functions';
import { Button } from '../common';

export interface Action {
  key: string;
  label: string;
  component: ReactNode;
  hidden?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles({
  actionsContainer: {
    display: 'flex',
    columnGap: '16px',
  },
  hidden: {
    position: 'absolute',
    top: -9999,
    left: -9999,
    visibility: 'hidden',
    width: 0,
    height: 0,
  },
  menuPaper: {
    padding: 0,
  },
  moreButton: {
    minWidth: 0,
    padding: '0 8px',
  },
});

const MobileActions: FC<{ actions: Action[] }> = ({ actions }) => {
  const classes = useStyles();
  const anchorRef = useRef<HTMLButtonElement>(null);
  const actionsRef = useRef<HTMLDivElement[]>([]);
  const [showMenu, setShowMenu] = useState(false);

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  return (
    <>
      <IconButton onClick={() => setShowMenu(true)} ref={anchorRef}>
        <MoreVertIcon />
      </IconButton>
      <div className={classes.hidden} id='actions-container'>
        {actions.map((action) => {
          return (
            <div
              key={action.key}
              id={`action-${action.key}`}
              ref={(el) => el && actionsRef.current?.push(el)}>
              {action.component}
            </div>
          );
        })}
      </div>
      {anchorRef.current && (
        <Menu
          id='actions-menu'
          anchorEl={anchorRef.current}
          keepMounted
          open={showMenu}
          onClose={handleCloseMenu}>
          {actions.map((action) => {
            return (
              <MenuItem
                disabled={action.disabled}
                onClick={() => {
                  const target = actionsRef.current.find(
                    (ref) => ref.id === `action-${action.key}`,
                  ) as HTMLDivElement;
                  const element = target.children[0] as HTMLElement;

                  if (!element) {
                    return;
                  }

                  const button = element.querySelector('button');

                  if (button) {
                    button.click();
                  } else {
                    element.click();
                  }

                  handleCloseMenu();
                }}
                key={action.key}>
                {action.label}
              </MenuItem>
            );
          })}
        </Menu>
      )}
    </>
  );
};

const DesktopActions: FC<{ actions: Action[] }> = ({ actions }) => {
  const classes = useStyles();

  return (
    <div className={classes.actionsContainer}>
      {actions.map((action) => action.component)}
    </div>
  );
};

const DesktopMergedActions: FC<{ actions: Action[] }> = ({ actions }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const actionsRef = useRef<HTMLDivElement[]>([]);

  const { mainAction, secondaryActions } = useMemo(() => {
    const mainAction = actions[0];
    const secondaryActions = actions.slice(1);

    return { mainAction, secondaryActions };
  }, [actions]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent<Document>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }

    setOpen(false);
  };

  const onActionClick = useCallback((actionKey: string) => {
    const target = actionsRef.current.find(
      (ref) => ref.id === `action-${actionKey}`,
    ) as HTMLDivElement;
    const element = target.children[0] as HTMLElement;

    if (!element) {
      return;
    }

    const button = element.querySelector('button');

    if (button) {
      button.click();
    } else {
      element.click();
    }

    setOpen(false);
  }, []);

  if (actions.length < 2) {
    return <DesktopActions actions={actions} />;
  }

  return (
    <div className={classes.actionsContainer}>
      <div className={classes.hidden} id='actions-container'>
        {secondaryActions.map((action) => {
          return (
            <div
              key={action.key}
              id={`action-${action.key}`}
              ref={(el) => el && actionsRef.current?.push(el)}>
              {action.component}
            </div>
          );
        })}
      </div>
      {mainAction.component}
      <Button
        variant='outlined'
        color='primary'
        ref={anchorRef}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-haspopup='true'
        className={classes.moreButton}
        onClick={handleToggle}>
        <MoreHorizIcon />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement='bottom-end'
        style={{ zIndex: 1 }}
        disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper className={classes.menuPaper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id='split-button-menu'>
                  {secondaryActions.map((action) => (
                    <MenuItem
                      key={action.key}
                      disabled={action.disabled}
                      onClick={() => onActionClick(action.key)}>
                      {action.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

const Actions: FC<{ actions: Action[]; isMerged?: boolean }> = ({
  actions: initialActions,
  isMerged,
}) => {
  const actions = useMemo(
    () => initialActions.filter((action) => !action.hidden),
    [initialActions],
  );

  if (!actions.length) {
    return null;
  }

  return isMobile() ? (
    <MobileActions actions={actions} />
  ) : isMerged && actions.length > 2 ? (
    <DesktopMergedActions actions={actions} />
  ) : (
    <DesktopActions actions={actions} />
  );
};

export default Actions;
