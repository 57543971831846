import { IconButton, makeStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { FC, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import notificationsAPI, {
  Notification,
  NotificationStatus,
  NotificationType,
} from '../../../api/notifications';
import { UserContext } from '../../../contexts/user-context';
import { getRoutePath, Pages } from '../../../router/constants';
import { COLORS } from '../../../theme/variables';
import { formatDate } from '../../../utils/date';
import { Text } from '../../common';
import NotificationIcon from './notification-icon';

interface NotificationCardProps {
  notification: Notification;
  onRemove?: () => void;
}

const useStyles = makeStyles((theme) => ({
  notificationCard: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '24px 1fr',
    gap: theme.spacing(1),
    width: '100%',
    padding: `${theme.spacing(1)}px ${theme.spacing(4)}px ${theme.spacing(
      1,
    )}px ${theme.spacing(2)}px`,
    boxSizing: 'border-box',
    cursor: 'default',
    transition: 'background-color 0.2s ease',

    '&:hover': {
      backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_35,
    },
  },
  clickable: {
    cursor: 'pointer',
  },
  activeNotificationCard: {
    backgroundColor: COLORS.COLOR_BLUE_LIGHTENED_45,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
  message: {
    display: '-webkit-box',
    lineHeight: 1.2,
    overflow: 'hidden',
    wordBreak: 'break-word',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
  },
  created: {
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    fontSize: 11,
    lineHeight: '1.2',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 8,
  },
}));

const NotificationCard: FC<NotificationCardProps> = ({
  notification,
  onRemove,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { hasAccessToAction } = useContext(UserContext);

  const link = useMemo(() => {
    switch (notification.type) {
      case NotificationType.NEW_SESSION_CREATED:
      case NotificationType.SESSION_UPDATE:
        return getRoutePath(Pages.SESSIONS_DETAILS, {
          sessionId: notification.reference,
        });
      case NotificationType.SESSION_AGENDA_UPDATE:
        return {
          pathname: getRoutePath(Pages.SESSIONS_DETAILS, {
            sessionId: notification.reference,
          }),
          search: 'anchor=description',
        };
      case NotificationType.NEW_SESSION_NOTE:
        return getRoutePath(Pages.SESSIONS_NOTES, {
          sessionId: notification.reference,
        });
      case NotificationType.NEW_WIZARD_SCHEDULED:
      case NotificationType.NEW_VENTURE_APPLICATION:
        return getRoutePath(Pages.VENTURE_DETAILS, {
          ventureId: notification.reference,
        });
      case NotificationType.MENTOR_ASSIGNMENT:
      case NotificationType.MENTOR_REMOVAL:
        return hasAccessToAction('venture.logs.view')
          ? getRoutePath(Pages.VENTURE_LOG, {
              ventureId: notification.reference,
            })
          : getRoutePath(Pages.VENTURE_ASSIGNMENTS, {
              ventureId: notification.reference,
            });
      case NotificationType.NEW_VENTURE_NOTE:
        return getRoutePath(Pages.VENTURE_NOTES, {
          ventureId: notification.reference,
        });
      case NotificationType.NEW_MENTOR_APPLICATION:
        return getRoutePath(Pages.MENTOR_DETAILS, {
          mentorId: notification.reference,
        });
      case NotificationType.SESSION_CANCELLATION:
      case NotificationType.GATHERING_INVITE_FAILURE:
      default:
        return null;
    }
  }, [hasAccessToAction, notification]);

  return (
    <div
      onClick={
        link
          ? () => {
              history.push(link);
            }
          : undefined
      }
      className={classNames(classes.notificationCard, {
        [classes.activeNotificationCard]:
          notification.status === NotificationStatus.NEW,
        [classes.clickable]: !!link,
      })}>
      <NotificationIcon type={notification.type} />
      <div className={classes.content}>
        <Text variant='normal' className={classes.message}>
          {notification.message}
        </Text>
        <Text variant='normal' className={classes.created}>
          {formatDate(notification.created)}
        </Text>
      </div>
      <IconButton
        aria-label='close'
        size='small'
        className={classes.closeButton}
        onClick={(e) => {
          e.stopPropagation();
          notificationsAPI.deleteNotification(notification.id);
          onRemove?.();
        }}>
        <ClearIcon style={{ fontSize: 14 }} />
      </IconButton>
    </div>
  );
};

export default NotificationCard;
