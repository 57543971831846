import AddIcon from '@material-ui/icons/Add';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { useSnackbar } from 'notistack';
import { useCallback, useState, useContext, useMemo } from 'react';
import { useHistory } from 'react-router';
import eventsAPI, { Event } from '../../api/events';
import { Role } from '../../api/user/Role';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { downloadICSFile } from '../common/add-to-calendar-button';
import TableActions from '../common/table-actions';

function SessionsRowActions({ session }: { session: Event }) {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { tokenData, hasAccessToAction, hasRole } = useContext(UserContext);

  const [loadingCalendar, setLoadingCalendar] = useState(false);

  const uploadCalendarIcs = useCallback(async () => {
    try {
      setLoadingCalendar(true);
      const ics = await eventsAPI.uploadCalendarIcs(session.id);
      downloadICSFile(ics, `${session.summary}.ics`);
      setLoadingCalendar(false);
    } catch (error) {
      enqueueSnackbar(
        'Something went wrong when adding to calendar. Please try again.',
        { variant: 'error' },
      );
      setLoadingCalendar(false);
    }
  }, [session, enqueueSnackbar]);

  const isMentorParticipant = useMemo(
    () =>
      !hasRole(Role.Mentor) ||
      session.invitedMentors?.some((id) => tokenData?.identityid === id),
    [session.invitedMentors, tokenData?.identityid, hasRole],
  );

  return isMentorParticipant ? (
    <TableActions
      actions={[
        {
          key: 'row-action-agenda',
          onClick: () =>
            history.push(
              getRoutePath(Pages.SESSIONS_AGENDA_REQUEST, {
                sessionId: session.id,
              }),
            ),
          icon: <AddIcon fontSize='small' color='inherit' />,
          label: 'Agenda',
          hidden: !hasAccessToAction('session.details.agenda'),
        },
        {
          key: 'row-action-report',
          onClick: () =>
            history.push(
              getRoutePath(Pages.SESSIONS_LEAD_MENTOR, {
                sessionId: session.id,
              }),
            ),
          icon: <AddIcon fontSize='small' color='inherit' />,
          label: 'Lead Report',
          hidden: !hasAccessToAction('session.details.leadMentorReport'),
        },
        {
          key: 'row-action-founder-notes',
          onClick: () =>
            history.push(
              getRoutePath(Pages.SESSIONS_FOUNDER_SESSION_NOTES, {
                sessionId: session.id,
              }),
            ),
          icon: <AddIcon fontSize='small' color='inherit' />,
          label: 'Founder Notes',
          hidden: !hasAccessToAction('session.details.founderNotes'),
        },
        {
          key: 'row-action-add-to-calendar',
          onClick: uploadCalendarIcs,
          disabled: loadingCalendar,
          icon: <CalendarIcon fontSize='small' color='inherit' />,
          label: 'Add to Calendar',
          hidden: !hasAccessToAction('session.details.addToCalendar'),
        },
      ]}
    />
  ) : null;
}

export default SessionsRowActions;
