import {
  Link as MaterialLink,
  Button as MaterialButton,
  makeStyles,
  Paper,
  Checkbox,
  IconButton,
  Tooltip,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EventIcon from '@material-ui/icons/Event';
import FlipCameraAndroidIcon from '@material-ui/icons/FlipCameraAndroid';
import SearchIcon from '@material-ui/icons/Search';
import ViewListIcon from '@material-ui/icons/ViewList';
import WarningIcon from '@material-ui/icons/Warning';
import Bowser from 'bowser';
import cn from 'classnames';
import { addDays, addMonths, endOfDay, format, startOfDay } from 'date-fns';
import { useSnackbar } from 'notistack';
import { parse as parseQuery, stringify as stringifyQuery } from 'query-string';
import {
  useMemo,
  useCallback,
  useState,
  useEffect,
  SyntheticEvent,
  useRef,
  useContext,
  ReactNode,
} from 'react';
import { createStaticRanges } from 'react-date-range';
import { useResizeDetector } from 'react-resize-detector';
import { useHistory, useLocation } from 'react-router';
import { Column, Table } from 'react-virtualized';
import _omit from 'lodash/omit';
import _pick from 'lodash/pick';
import _set from 'lodash/set';
import _uniq from 'lodash/uniq';
import { getActiveCohorts } from '../../api/Cohort';
import { CohortId } from '../../api/Cohort/types/Cohort';
import eventsAPI, { Event } from '../../api/events';
import IssueTableInfo from '../../components/sessions/issue-table-info';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import {
  formatDateApi,
  formatTimeInterval,
  formatDateInterval,
  getTomorrow,
  datesAreOnSameDay,
  getPreviousMonth,
} from '../../utils/date';
import {
  checkSizeTablet,
  decodeQuery,
  encodeQuery,
} from '../../utils/functions';
import { isMobile, isTablet } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  Select,
  Text,
  AlertState,
  Link,
  DateRangePickerInput,
  TextField,
  Toggler,
  Modal,
} from '../common';
import EventTypeIcon from '../common/event-type-icon';
import SelectVenturePopover from '../common/select-venture-popover';
import {
  TableCell,
  TableHeadCell,
  TableHeadRow,
  TableRow,
  TableFooter,
} from '../common/table';
import SessionChangeStatus from '../forms/session-change-status';
import SessionResolveIssues from '../forms/session-resolve-issues';
import AgendaIcon from '../sessions/agenda-icon';
import AttendanceIcon from '../sessions/attendance-icon';
import CalendarView from '../sessions/calendar-view';
import FounderNotesIcon from '../sessions/founder-notes-icon';
import LeadMentorReportIcon from '../sessions/lead-mentor-report-icon';
import SessionsRowActions from '../sessions/sessions-row-actions';

type VIEW_MODE = 'table' | 'calendar';

interface SearchParams {
  [x: string]: any;
}

const filters = {
  status: [
    {
      label: 'All Statuses',
      value: 'none',
    },
    {
      value: 'CREATED',
      label: 'Created',
    },
    {
      value: 'PUBLISHED',
      label: 'Published',
    },
    {
      value: 'COMPLETED',
      label: 'Completed',
    },
    {
      value: 'ARCHIVED',
      label: 'Archived',
    },
    {
      value: 'REQUIRE_ATTENTION',
      label: 'Attention Required',
    },
  ],
  venture: [
    {
      label: 'empty',
      value: '',
    },
  ],
  dateRange: [
    {
      label: 'empty',
      value: '',
    },
  ],
};

interface FilterValue {
  search?: string;
  status?: string;
  venture?: {
    id: string;
    ventureName: string;
  };
  dateRange?: {
    startDate: Date;
    endDate: Date;
  };
  cohort?: CohortId;
}

type CohortOption = { label: string; value: CohortId | null };

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 0,
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '12px 12px 12px 16px',
    minHeight: 64,
    boxSizing: 'border-box',
    position: 'relative',
    zIndex: 2,
    flexWrap: 'wrap',
    rowGap: 15,
  },
  filterList: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 15,
  },
  filterItem: {
    '& + &': {
      marginLeft: 15,
    },
  },
  filterSelect: {
    width: 160,
  },
  filterActions: {
    display: 'flex',
    alignItems: 'center',
    margin: '-1px 0',
    marginRight: 15,
  },
  filterActionsAssign: {
    marginLeft: 11,
  },
  emptyBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 696,
  },
  emptyState: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: 500,
  },
  emptyStateBtn: {
    marginTop: 16,
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  date: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  ventureName: {
    display: 'flex',
    padding: '0 32px 0 10px',
    boxSizing: 'border-box',
  },
  followupReminder: {
    paddingRight: 10,
    boxSizing: 'border-box',
  },
  summary: {
    paddingRight: 20,
    boxSizing: 'border-box',
  },
  actionsCell: {
    display: 'none',
    alignItems: 'center',
  },
  searchInput: {
    width: '100%',
    maxWidth: 320,
  },
  ventureInput: {
    width: 180,
  },
  filterDateRange: {
    position: 'relative',
  },
  headerActions: {
    display: 'flex',
    alignItems: 'center',
  },
  toggler: {
    marginLeft: 15,
  },
  calendar: {
    position: 'relative',
    zIndex: 1,
  },
  calendarLoader: {
    background: 'rgba(0,0,0,0.15)',
    position: 'absolute',
    top: '61px',
    left: '16px',
    right: '16px',
    bottom: '16px',
    zIndex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  warningIcon: {
    color: COLORS.COLOR_ADDITIONAL_ORANGE,
    cursor: 'pointer',
  },
  iconRemoveFilter: {
    width: 20,
  },
  containerRemoveFilter: {
    marginLeft: 10,
  },
  summaryContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',

    '& a': {
      display: 'block',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  resolveModal: {
    paddingTop: 0,
  },
  attendanceCell: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingRight: 32,
  },
}));

function getPageFromURL(searchParams: SearchParams) {
  const parsedQuery = _pick(searchParams, ['page']);
  return parsedQuery.page ? Number(parsedQuery.page) : 1;
}

function getFilterBySearch(searchParams: SearchParams): FilterValue {
  const decodedFilter = decodeQuery(searchParams.filter || '');
  const parsedQuery = _pick(decodedFilter || {}, [
    'search',
    'status',
    'venture',
    'dateRange',
    'cohort',
  ]) as FilterValue;
  if (parsedQuery.dateRange) {
    parsedQuery.dateRange = {
      startDate: new Date(parsedQuery.dateRange.startDate),
      endDate: new Date(parsedQuery.dateRange.endDate),
    };
  }
  return parsedQuery as FilterValue;
}

function getSearchParams(search: string): SearchParams {
  return parseQuery(search);
}

function getViewBySearch(searchParams: SearchParams): VIEW_MODE {
  const parsedQuery = _pick(searchParams || {}, ['view']);
  return (parsedQuery.view ? parsedQuery.view : 'table') as VIEW_MODE;
}

function getLastViewedIntervalBySearch(
  filter: FilterValue,
  viewMode: VIEW_MODE,
): { start: Date; end: Date } {
  if (viewMode === 'calendar' && filter && filter.dateRange) {
    return {
      start: filter.dateRange.startDate,
      end: filter.dateRange.endDate,
    };
  }
  return { start: new Date(), end: getTomorrow(new Date()) };
}

function isEmptyFilter(filter: FilterValue) {
  return Object.keys(filter).length === 0;
}

async function sessionsRequest(filter: FilterValue, page: number) {
  if (!isEmptyFilter(filter)) {
    let loadedSessions;
    if (filter.search) {
      loadedSessions = await eventsAPI.search(filter.search);
    } else {
      if (filter.status !== 'REQUIRE_ATTENTION') {
        const eventFilter = {
          statusId: filter.status,
          ...(filter.dateRange
            ? {
                startDate: formatDateApi(filter.dateRange.startDate),
                endDate: formatDateApi(filter.dateRange.endDate),
              }
            : filter.status === 'COMPLETED'
            ? { startDate: getPreviousMonth(new Date(), 'MMddyyyy') }
            : {}),
          ...(filter.venture ? { ventureId: filter.venture.id } : {}),
          ...(filter.cohort ? { cohortId: filter.cohort } : {}),
        };
        loadedSessions = await eventsAPI.searchByFilterV2(eventFilter);
      } else {
        loadedSessions = await eventsAPI.getEventsRequireAttention();
      }
    }
    return loadedSessions;
  }
  return eventsAPI.getEvents(page);
}

function formatSessionDate(date: Date) {
  return format(date, 'E, LLL dd, y');
}

function SessionsTable() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const { hasAccessToAction } = useContext(UserContext);
  const debouncedTimer = useRef<number>();
  const currentPath = useMemo(
    () => `${location.pathname}${location.search}`,
    [location],
  );
  const searchParams = useMemo(
    () => getSearchParams(location.search),
    [location.search],
  );
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isTabletVersion =
    browser?.getPlatform().type === 'tablet' || isTablet();

  const filter = useMemo(() => {
    return getFilterBySearch(searchParams);
  }, [searchParams]);

  const [searchValue, setSearchValue] = useState(filter.search || '');
  const [sessions, setSessions] = useState<Event[]>([]);
  const [selectedSessionsIds, setSelectedSessionsIds] = useState<Event['id'][]>(
    [],
  );

  const selectedSessionsIssueIds: Event['id'][] = useMemo(() => {
    return (
      sessions
        .filter(
          (session) =>
            selectedSessionsIds.includes(session.id) &&
            session.requiresAttention,
        )
        .map((session) => session.id) || []
    );
  }, [selectedSessionsIds, sessions]);

  const page = useMemo(() => getPageFromURL(searchParams), [searchParams]);
  const [isLoading, setIsLoading] = useState(true);
  const [isNextPageLoading, setIsNextPageLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadError, setLoadError] = useState();
  const [isEmptyState, setIsEmptyState] = useState(false);
  const [cohorts, setCohorts] = useState<Array<CohortOption>>([]);

  const perPage = useMemo(
    () => (isEmptyFilter(filter) ? 10 : undefined),
    [filter],
  );
  const viewMode = useMemo(() => {
    if (!hasAccessToAction('session.calendar')) {
      return 'table';
    }
    return getViewBySearch(searchParams);
  }, [searchParams, hasAccessToAction]);

  const [lastViewedInterval, setLastViewedInterval] = useState<{
    start: Date;
    end: Date;
  }>(getLastViewedIntervalBySearch(filter, viewMode));

  const showClearFilter = useMemo(() => {
    const isDataRangeFilter = viewMode === 'table' && !!filter.dateRange;

    return (
      !!searchValue ||
      !!filter.status ||
      !!filter.venture ||
      isDataRangeFilter ||
      !!filter.cohort
    );
  }, [searchValue, filter, viewMode]);

  const staticRanges = useMemo(() => {
    return createStaticRanges([
      {
        label: 'Relevant',
        range: () => ({
          startDate: startOfDay(addDays(new Date(), -7)),
          endDate: endOfDay(addDays(new Date(), 7)),
        }),
      },
      {
        label: 'Past',
        range: () => ({
          startDate: startOfDay(addMonths(new Date(), -6)),
          endDate: endOfDay(new Date()),
        }),
      },
      {
        label: 'Future',
        range: () => ({
          startDate: startOfDay(new Date()),
          endDate: endOfDay(addMonths(new Date(), 6)),
        }),
      },
    ]);
  }, []);

  const prepareNewURL = useCallback(
    (nextSearchParams) => {
      return `${location.pathname}${
        Object.keys(nextSearchParams).length
          ? `?${stringifyQuery(nextSearchParams)}`
          : ''
      }`;
    },
    [location.pathname],
  );

  const loadSessions = async (filter: FilterValue, currentPage: number) => {
    try {
      setSessions([]);
      setSelectedSessionsIds([]);
      setIsLoading(true);
      setIsEmptyState(false);
      if (!filter.search && searchValue.length > 2) {
        setSearchValue('');
      }
      const loadedSessions = await sessionsRequest(filter, currentPage - 1);
      if (loadedSessions.length === 0) {
        throw new Error('404');
      }
      setSessions(loadedSessions);
      setIsLoading(false);

      if (loadedSessions.length === 10) {
        setIsNextPageLoading(true);
        try {
          const nextSessions = await sessionsRequest(filter, currentPage);
          setIsNextPageLoading(false);
          setHasNextPage(nextSessions.length > 0);
        } catch (e: any) {
          setIsNextPageLoading(false);
          setHasNextPage(false);
        }
      } else {
        setHasNextPage(false);
      }
    } catch (e: any) {
      if (e?.response?.status === 404 || e?.message === '404') {
        setIsLoading(false);
        return setIsEmptyState(true);
      }
      const errorMessage =
        e?.response?.data?.message || 'Internal server error';
      setIsLoading(false);
      setLoadError(errorMessage);
    }
  };

  const handleClearFilters = useCallback(() => {
    const paramsArray = [
      'search',
      'status',
      'venture',
      'cohort',
      viewMode === 'table' ? 'dateRange' : '',
    ];
    const clearFilter = _omit(filter, paramsArray);
    const clearSearchParams = {
      ...searchParams,
      filter: encodeQuery(clearFilter),
    };
    history.replace(prepareNewURL(clearSearchParams));
  }, [filter, searchParams, prepareNewURL, history, viewMode]);

  const handleFilterUpdate = useCallback(
    (field: string, value: any, clear?: boolean) => {
      if (!value || value === 'none') {
        const newFilter = !clear ? _omit(filter, [field, 'search']) : {};
        const { filter: prevFilter, ...restSearchParams } = searchParams;
        const nextSearchParams = {
          ...restSearchParams,
          ...(!isEmptyFilter(newFilter)
            ? { filter: encodeQuery(newFilter) }
            : {}),
        };
        history.replace(prepareNewURL(nextSearchParams));
      } else {
        const isClearStatus =
          filter?.status === 'REQUIRE_ATTENTION' ? 'status' : '';
        const newFilter = !clear
          ? _set({ ..._omit(filter, ['search', isClearStatus]) }, field, value)
          : { [field]: value };
        const nextSearchParams = {
          ...searchParams,
          filter: encodeQuery(newFilter),
        };
        history.replace(prepareNewURL(nextSearchParams));
      }
    },
    [history, filter, searchParams, prepareNewURL],
  );

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debouncedTimer.current);
      setSearchValue(e.target.value);

      debouncedTimer.current = setTimeout(() => {
        if (e.target.value.trim().length > 2) {
          handleFilterUpdate('search', e.target.value.trim(), true);
        } else {
          handleFilterUpdate('search', null, true);
        }
      }, 800) as unknown as number;
    },
    [handleFilterUpdate],
  );

  const handlePageChange = useCallback(
    (nextPage) => {
      const query = stringifyQuery({
        ...searchParams,
        page: nextPage > 1 ? nextPage : undefined,
      });

      if (query) {
        history.replace(`${location.pathname}?${query}`);
      } else {
        history.replace(location.pathname);
      }
    },
    [searchParams, location.pathname, history],
  );

  const handleAllRowSelect = useCallback(
    (checked: boolean) => {
      if (checked) {
        const venturesIds = sessions.map((session) => session.id);
        return setSelectedSessionsIds([...venturesIds]);
      }
      return setSelectedSessionsIds([]);
    },
    [sessions],
  );

  const handleRowSelect = useCallback(
    (checked: boolean, ventureId: Event['id']) => {
      if (checked) {
        return setSelectedSessionsIds(
          _uniq([...selectedSessionsIds, ventureId]),
        );
      }
      return setSelectedSessionsIds(
        selectedSessionsIds.filter((id) => id !== ventureId),
      );
    },
    [selectedSessionsIds],
  );

  const handleRowClick = useCallback(
    ({ rowData }: { rowData: Event }) => {
      const ventureId = rowData.id;
      handleRowSelect(!selectedSessionsIds.includes(ventureId), ventureId);
    },
    [selectedSessionsIds, handleRowSelect],
  );

  const handleRangeChange = useCallback(
    (value: { startDate: Date; endDate?: Date }) => {
      const dates = value;
      if (!dates.endDate) {
        dates.endDate = getTomorrow(dates.startDate);
      }
      handleFilterUpdate('dateRange', dates);
      setLastViewedInterval({ start: dates.startDate, end: dates.endDate });
    },
    [handleFilterUpdate],
  );

  const handleViewChange = (view: string) => {
    const nextFilter = _pick(filter, ['status', 'venture', 'cohort']);
    const { filter: prevFilter, ...restSearchParams } = searchParams;
    const nextSearchParams = {
      ...restSearchParams,
      ...(!isEmptyFilter(nextFilter)
        ? { filter: encodeQuery(nextFilter) }
        : {}),
      view,
    };
    history.replace(prepareNewURL(nextSearchParams));
  };

  const handleInitCalendar = useCallback(
    (dates: { start: Date; end: Date }) => {
      handleFilterUpdate('dateRange', {
        startDate: dates.start,
        endDate: dates.end,
      });
    },
    [handleFilterUpdate],
  );

  const stopPropagation = (e: SyntheticEvent<any>) => {
    e.stopPropagation();
  };

  const getDataTestId = (data: string, isActive: boolean): string => {
    return `${data}${isActive ? '-active' : ''}`;
  };

  useEffect(() => {
    loadSessions(filter, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, page]);

  useEffect(() => {
    getActiveCohorts()
      .then((vs) =>
        vs.map((v) => ({
          value: v.id,
          label: v.name,
        })),
      )
      .then((vs) => setCohorts([{ label: 'All groups', value: null }, ...vs]))
      .catch(() =>
        enqueueSnackbar('Could not load groups details', { variant: 'error' }),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isSmallDesktop = checkSizeTablet(1400);
  const isXSmallDesktop = checkSizeTablet(1200);

  return (
    <Paper className={classes.container} data-testid='session-table'>
      {!isTabletVersion && hasAccessToAction('session.filters') && (
        <div className={classes.filterContainer}>
          <div className={classes.filterList}>
            {viewMode === 'table' && (
              <div
                className={classes.filterItem}
                data-testid={getDataTestId(
                  'session-filter-search',
                  !!searchValue,
                )}>
                <TextField
                  className={cn(
                    classes.searchInput,
                    CLASS_TRACKING.FILTER_ELEMENT,
                  )}
                  value={searchValue}
                  onChange={handleSearch}
                  placeholder='Search'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  small
                />
              </div>
            )}
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'session-filter-status',
                !!filter.status,
              )}>
              <Select
                className={cn(
                  classes.filterSelect,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                value={filter.status ? filter.status : filters.status[0].value}
                options={
                  viewMode === 'calendar'
                    ? filters.status.filter(
                        (st) => st.value !== 'REQUIRE_ATTENTION',
                      )
                    : filters.status
                }
                onSelect={(item) =>
                  handleFilterUpdate(
                    'status',
                    item?.value,
                    item?.value === 'REQUIRE_ATTENTION',
                  )
                }
                isActive={!!filter.status}
              />
            </div>
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'session-filter-venture',
                !!filter.venture,
              )}>
              <SelectVenturePopover
                className={cn(
                  classes.ventureInput,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}
                onChange={(val) => handleFilterUpdate('venture', val)}
                value={filter.venture}
                isActive={!!filter.venture}
                small
              />
            </div>
            {viewMode === 'table' && (
              <div
                className={cn(
                  classes.filterItem,
                  classes.filterDateRange,
                  CLASS_TRACKING.FILTER_ELEMENT,
                )}>
                <DateRangePickerInput
                  onChange={(val: any) => {
                    handleFilterUpdate('dateRange', val);
                  }}
                  value={filter.dateRange}
                  staticRanges={staticRanges}
                />
              </div>
            )}
            <div
              className={classes.filterItem}
              data-testid={getDataTestId(
                'session-filter-cohorts',
                !!filter.cohort,
              )}>
              <Select
                className={cn(classes.filterSelect)}
                value={filter?.cohort ? filter.cohort : cohorts?.[0]?.value}
                options={cohorts}
                onSelect={(item) => {
                  handleFilterUpdate('cohort', item?.value || null);
                }}
                isActive={!!filter.cohort}
              />
            </div>
            {showClearFilter && (
              <Tooltip title='Clear all filters'>
                <IconButton
                  data-testid='button-remove-filter'
                  className={classes.containerRemoveFilter}
                  onClick={() => handleClearFilters()}>
                  <img
                    src='/filter.svg'
                    alt='Remove filter'
                    className={classes.iconRemoveFilter}
                  />
                </IconButton>
              </Tooltip>
            )}
          </div>

          <div className={classes.headerActions}>
            {selectedSessionsIds.length > 0 && (
              <div className={classes.filterActions}>
                {hasAccessToAction('session.details.update') && (
                  <Text variant='normal'>
                    {selectedSessionsIds.length} selected
                  </Text>
                )}
                {selectedSessionsIssueIds.length > 0 &&
                  hasAccessToAction('session.details.update') && (
                    <Modal
                      overflowInitial={true}
                      title='Resolve all selected issues'
                      classNameContent={classes.resolveModal}
                      contentRenderer={({ handleClose }) => (
                        <SessionResolveIssues
                          onCancel={handleClose}
                          selectedSessionsIds={selectedSessionsIssueIds}
                          updateSession={() => loadSessions(filter, page)}
                        />
                      )}
                      buttonRenderer={({ onClick }) => (
                        <Tooltip title='Resolve all selected issues'>
                          <IconButton
                            data-testid='session-table-resolve-issues'
                            className={classes.filterActionsAssign}
                            onClick={onClick}>
                            <CheckCircleOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      width={350}
                    />
                  )}

                {hasAccessToAction('session.details.update') && (
                  <Modal
                    overflowInitial={true}
                    title='Apply status for sessions'
                    caption='Please select status to apply'
                    contentRenderer={({ handleClose }) => (
                      <SessionChangeStatus
                        onCancel={handleClose}
                        selectedSessionsIds={selectedSessionsIds}
                        sessions={sessions}
                        updateSession={() => loadSessions(filter, page)}
                      />
                    )}
                    buttonRenderer={({ onClick }) => (
                      <Tooltip title='Apply status'>
                        <IconButton
                          data-testid='session-table-apply-status'
                          className={classes.filterActionsAssign}
                          onClick={onClick}>
                          <FlipCameraAndroidIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    width={350}
                  />
                )}
              </div>
            )}
            {hasAccessToAction('session.calendar') && (
              <Toggler
                value={viewMode}
                onChange={handleViewChange}
                options={[
                  {
                    value: 'table',
                    label: <ViewListIcon />,
                    className: CLASS_TRACKING.SESSION_DETAILED_LIST,
                  },
                  {
                    value: 'calendar',
                    label: <EventIcon />,
                    className: CLASS_TRACKING.SESSION_CALENDAR_LIST,
                  },
                ]}
                withIcon={false}
              />
            )}
          </div>
        </div>
      )}
      {viewMode === 'table' ? (
        <div ref={containerRef}>
          {sessions.length > 0 ? (
            <div>
              <Table
                onRowClick={
                  hasAccessToAction('session.details.update')
                    ? handleRowClick
                    : () => {}
                }
                gridClassName={classes.table}
                headerHeight={56}
                height={64 * (sessions.length > 10 ? sessions.length : 10) + 56}
                rowHeight={64}
                rowCount={sessions.length}
                rowGetter={({ index }) => sessions[index]}
                rowClassName={classes.row}
                headerRowRenderer={(headRowProps) => (
                  <TableHeadRow {...headRowProps} />
                )}
                rowRenderer={(rowProps) => (
                  <TableRow
                    data-testid={`session-table-row-${rowProps.index}`}
                    selected={selectedSessionsIds.includes(rowProps.rowData.id)}
                    {...rowProps}
                  />
                )}
                width={containerWidth || 500}>
                <Column
                  dataKey='id'
                  className={classes.column}
                  width={
                    !isTabletVersion &&
                    hasAccessToAction('session.details.update')
                      ? 72
                      : 30
                  }
                  minWidth={
                    !isTabletVersion &&
                    hasAccessToAction('session.details.update')
                      ? 72
                      : 30
                  }
                  headerRenderer={() => (
                    <div>
                      {!isTabletVersion &&
                        hasAccessToAction('session.details.update') && (
                          <Checkbox
                            checked={
                              selectedSessionsIds.length === sessions.length
                            }
                            color='primary'
                            indeterminate={
                              selectedSessionsIds.length > 0 &&
                              selectedSessionsIds.length !== sessions.length
                            }
                            onChange={(e) =>
                              handleAllRowSelect(e.target.checked)
                            }
                          />
                        )}
                    </div>
                  )}
                  cellRenderer={({ cellData }) => (
                    <div>
                      {!isTabletVersion &&
                        hasAccessToAction('session.details.update') && (
                          <Checkbox
                            data-testid='session-table-row-checkbox'
                            checked={selectedSessionsIds.includes(cellData)}
                            color='primary'
                            onChange={(e) =>
                              handleRowSelect(e.target.checked, cellData)
                            }
                          />
                        )}
                    </div>
                  )}
                />
                <Column
                  dataKey='virtualChannelRequested'
                  className={classes.column}
                  width={28}
                  minWidth={28}
                  cellRenderer={({ rowData }: { rowData: Event }) => (
                    <EventTypeIcon event={rowData} />
                  )}
                />
                <Column
                  dataKey='summary'
                  className={cn(classes.column, classes.summary)}
                  headerClassName={classes.summary}
                  width={180}
                  minWidth={isTabletVersion ? 100 : 180}
                  flexGrow={1}
                  headerRenderer={() => (
                    <TableHeadCell>Session Name</TableHeadCell>
                  )}
                  cellRenderer={({
                    cellData,
                    rowData,
                  }: {
                    rowData: Event;
                    cellData?: ReactNode;
                  }) => (
                    <div className={classes.summaryContainer}>
                      <TableCell truncated>
                        <MaterialLink
                          data-testid='session-table-name'
                          onClick={stopPropagation}
                          component={Link}
                          to={{
                            pathname: getRoutePath(Pages.SESSIONS_DETAILS, {
                              sessionId: rowData.id,
                            }),
                            state: {
                              prevPath: currentPath,
                            },
                          }}>
                          {cellData}
                        </MaterialLink>
                      </TableCell>
                      {rowData.requiresAttention && (
                        <IssueTableInfo sessionId={rowData.id}>
                          <WarningIcon
                            className={classes.warningIcon}
                            data-testid='session-table-issue-icon'
                          />
                        </IssueTableInfo>
                      )}
                    </div>
                  )}
                />
                {!isMobile() && (
                  <Column
                    dataKey='ventureName'
                    className={cn(classes.column, classes.ventureName)}
                    headerClassName={classes.ventureName}
                    width={240}
                    minWidth={isTabletVersion ? 100 : 180}
                    maxWidth={320}
                    headerRenderer={() => (
                      <TableHeadCell>Venture</TableHeadCell>
                    )}
                    cellRenderer={({
                      cellData,
                      rowData,
                    }: {
                      rowData: Event;
                      cellData?: ReactNode;
                    }) => (
                      <TableCell truncated onClick={stopPropagation}>
                        <MaterialLink
                          onClick={stopPropagation}
                          data-testid='session-table-venture-name'
                          component={Link}
                          to={{
                            pathname: getRoutePath(Pages.VENTURE_DETAILS, {
                              ventureId: rowData.ventureId,
                            }),
                            state: {
                              prevPath: currentPath,
                            },
                          }}>
                          {cellData}
                        </MaterialLink>
                      </TableCell>
                    )}
                  />
                )}
                <Column
                  dataKey='date'
                  className={cn(classes.column, classes.date)}
                  headerClassName={classes.date}
                  width={204}
                  minWidth={180}
                  maxWidth={230}
                  flexGrow={1}
                  headerRenderer={() => <TableHeadCell>Date</TableHeadCell>}
                  cellRenderer={({ rowData }: { rowData: Event }) => {
                    const startDate = new Date(rowData.start);
                    const endDate = new Date(rowData.end);

                    const similarDates = datesAreOnSameDay(startDate, endDate);
                    const date = similarDates
                      ? formatSessionDate(startDate)
                      : formatDateInterval(startDate, endDate);

                    const time = formatTimeInterval(startDate, endDate);

                    return (
                      <TableCell>
                        <Text bold>{date}</Text>
                        <br />
                        <Text variant='upper1' color={COLORS.COLOR_GRAY_BASE}>
                          {time}
                        </Text>
                      </TableCell>
                    );
                  }}
                />
                {!isTablet() && (
                  <Column
                    dataKey='acceptanceStatus'
                    className={classes.column}
                    width={93}
                    minWidth={93}
                    maxWidth={93}
                    headerRenderer={() => (
                      <TableHeadCell>Accepted</TableHeadCell>
                    )}
                    cellRenderer={({ rowData }: { rowData: Event }) => {
                      return (
                        <TableCell className={classes.attendanceCell}>
                          <AttendanceIcon session={rowData} />
                        </TableCell>
                      );
                    }}
                  />
                )}

                {!isXSmallDesktop && (
                  <Column
                    dataKey='description'
                    className={classes.column}
                    width={81}
                    minWidth={81}
                    maxWidth={81}
                    headerRenderer={() => <TableHeadCell>Agenda</TableHeadCell>}
                    cellRenderer={({ rowData }: { rowData: Event }) => {
                      return (
                        <TableCell className={classes.attendanceCell}>
                          <AgendaIcon session={rowData} />
                        </TableCell>
                      );
                    }}
                  />
                )}

                {!isXSmallDesktop && (
                  <Column
                    dataKey='founderReportId'
                    className={classes.column}
                    width={isSmallDesktop ? 70 : 125}
                    minWidth={isSmallDesktop ? 70 : 125}
                    maxWidth={isSmallDesktop ? 70 : 125}
                    headerRenderer={() => (
                      <TableHeadCell>
                        {isSmallDesktop ? 'Notes' : 'Founder Notes'}
                      </TableHeadCell>
                    )}
                    cellRenderer={({ rowData }: { rowData: Event }) => {
                      return (
                        <TableCell className={classes.attendanceCell}>
                          <FounderNotesIcon session={rowData} />
                        </TableCell>
                      );
                    }}
                  />
                )}

                {!isXSmallDesktop && (
                  <Column
                    dataKey='leadMentorReportId'
                    className={classes.column}
                    width={isSmallDesktop ? 75 : 109}
                    minWidth={isSmallDesktop ? 75 : 109}
                    maxWidth={isSmallDesktop ? 75 : 109}
                    headerRenderer={() => (
                      <TableHeadCell>
                        {isSmallDesktop ? 'Report' : 'Lead Report'}
                      </TableHeadCell>
                    )}
                    cellRenderer={({ rowData }: { rowData: Event }) => {
                      return (
                        <TableCell className={classes.attendanceCell}>
                          <LeadMentorReportIcon session={rowData} />
                        </TableCell>
                      );
                    }}
                  />
                )}

                {!isMobile() && (
                  <Column
                    dataKey='actions'
                    className={cn(classes.column, classes.justifyContentEnd)}
                    minWidth={40}
                    width={40}
                    headerRenderer={() => <div></div>}
                    cellRenderer={({ rowData }: { rowData: Event }) => (
                      <TableCell onClick={stopPropagation}>
                        <SessionsRowActions session={rowData} />
                      </TableCell>
                    )}
                  />
                )}
              </Table>
            </div>
          ) : (
            <>
              {isEmptyState ? (
                <div className={classes.emptyBlock}>
                  <div className={classes.emptyState}>
                    <Text variant='normal' testid='session-table-no-items'>
                      {hasAccessToAction('session.create') ? (
                        <>
                          This panel contains a list of sessions.
                          <br />
                          We were not able to find information you requested,
                          but feel free to add something new!
                        </>
                      ) : (
                        <>
                          There are no sessions scheduled yet with your
                          participation. <br /> Please reach out to the Program
                          Manager to get a session with a venture on your
                          calendar!
                        </>
                      )}
                    </Text>
                    {hasAccessToAction('session.create') && (
                      <MaterialButton
                        component={Link}
                        to={Pages.NEW_SESSIONS}
                        className={classes.emptyStateBtn}
                        startIcon={<AddIcon />}
                        variant='contained'
                        color='primary'>
                        Session
                      </MaterialButton>
                    )}
                  </div>
                </div>
              ) : (
                <div className={classes.emptyBlock}>
                  {isLoading && <CircularProgress size={36} color='primary' />}
                  {!isLoading && !!loadError && (
                    <AlertState type='error'>{loadError}</AlertState>
                  )}
                </div>
              )}
            </>
          )}
          {!!perPage && (
            <TableFooter
              page={page}
              onPageChange={handlePageChange}
              disabled={isLoading}
              isLoading={isNextPageLoading}
              hasNextPage={sessions.length > 0 && hasNextPage}
            />
          )}
        </div>
      ) : (
        <div ref={containerRef} className={classes.calendar}>
          {sessions.length === 0 && isLoading && (
            <div className={classes.calendarLoader}>
              <CircularProgress />
            </div>
          )}
          <CalendarView
            lastInterval={lastViewedInterval}
            events={sessions}
            onRangeChange={handleRangeChange}
            onInit={handleInitCalendar}
          />
        </div>
      )}
    </Paper>
  );
}

export default SessionsTable;
