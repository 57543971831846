import { useSnackbar } from 'notistack';
import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
  PropsWithChildren,
  Dispatch,
  SetStateAction,
  useMemo,
} from 'react';
import commentsAPI, { NoteComment } from '../api/comments';
import foundersAPI from '../api/founders';
import mentorsAPI from '../api/mentors';
import { Role } from '../api/user/Role';
import { Attachment } from '../components/common/attachment-card';
import { ProtectedRouteProps } from '../router/type';
import { isMobile } from '../utils/functions';
import { UserContext } from './user-context';

type BeforeUnloadState = {
  action: 'add' | 'select' | 'close';
  note?: PersonalNote;
};

export enum NoteType {
  GeneralNote = 'GeneralNote',
  VentureNote = 'VentureNote',
  EventNote = 'EventNote',
  FounderNote = 'FounderNote',
  MentorReport = 'MentorReport',
  Agenda = 'Agenda',
  MentorNote = 'MentorNote',
}

export enum NotesBlockType {
  Attachment = 'Attachment',
  Comment = 'Comment',
}

interface CommonPersonalNote {
  id?: string;
  tenantId: string;
  type: keyof typeof NoteType;
  date: string;
  content: string;
  attachmentRefs?: string;
  parentId?: string;
  valid?: boolean;
  ventureName?: string;
}

export interface MentorPersonalNote extends CommonPersonalNote {
  mentorId: string | null;
}

export interface FounderPersonalNote extends CommonPersonalNote {
  founderId: string | null;
}

export type PersonalNote = MentorPersonalNote | FounderPersonalNote;

export interface PersonalNotesContextProps {
  notes: PersonalNote[];
  selectedNote: PersonalNote | null;
  attachments: Attachment[];
  showAttachments: boolean;
  comments: NoteComment[];
  activeBlock: NotesBlockType | null;
  loadingComments: boolean;
  showComments: boolean;
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  error: string;
  searchFilter: string | undefined;
  searchValue: string;
  value: string;
  isDirty: boolean;
  beforeUnloadState: BeforeUnloadState | null;
  setBeforeUnloadState: Dispatch<SetStateAction<BeforeUnloadState | null>>;
  setValue: Dispatch<SetStateAction<string>>;
  setSearchValue: Dispatch<SetStateAction<string>>;
  setSelectedNote: Dispatch<SetStateAction<PersonalNote | null>>;
  setAttachments: Dispatch<SetStateAction<Attachment[]>>;
  toggleAttachments: () => void;
  setComments: Dispatch<SetStateAction<NoteComment[]>>;
  setLoadingComments: Dispatch<SetStateAction<boolean>>;
  toggleComments: () => void;
  setSaving: (saving: boolean) => void;
  closeAllBlocks: () => void;
  createNote: (note: PersonalNote) => Promise<PersonalNote | void>;
  updateNote: (note: PersonalNote) => Promise<PersonalNote | void>;
  deleteNote: (note: PersonalNote) => Promise<PersonalNote | void>;
  fetchNextPage: () => Promise<void>;
  setSearchFilter: (searchFilter: string | undefined) => void;
  deleteComment: (commentId: string) => Promise<void>;
  resetSearch: () => void;
  handleSaveNote: () => Promise<void>;
  handleNoteRemove: (callback: Function) => Promise<void>;
  addNewNote: () => void;
  handleAddNewNote: () => void;
  handleCardSelect: (note: PersonalNote) => void;
  handleCardClose: () => void;
}

export const PersonalNotesContext = createContext<PersonalNotesContextProps>({
  notes: [],
  selectedNote: null,
  attachments: [],
  showAttachments: false,
  comments: [],
  activeBlock: null,
  loadingComments: false,
  showComments: false,
  loading: false,
  saving: false,
  deleting: false,
  error: '',
  searchFilter: undefined,
  searchValue: '',
  value: '',
  isDirty: false,
  beforeUnloadState: null,
  setBeforeUnloadState: () => {},
  setValue: () => {},
  setSearchValue: () => {},
  setSelectedNote: () => {},
  setAttachments: () => {},
  toggleAttachments: () => {},
  setComments: () => {},
  setLoadingComments: () => {},
  toggleComments: () => {},
  setSaving: () => {},
  closeAllBlocks: () => {},
  createNote: async () => {},
  updateNote: async () => {},
  deleteNote: async () => {},
  fetchNextPage: async () => {},
  setSearchFilter: () => {},
  deleteComment: async () => {},
  resetSearch: () => {},
  handleSaveNote: async () => {},
  handleNoteRemove: async () => {},
  addNewNote: () => {},
  handleAddNewNote: () => {},
  handleCardSelect: () => {},
  handleCardClose: () => {},
});

export const PersonalNotesProvider = ({
  children,
  user,
}: PropsWithChildren<ProtectedRouteProps>) => {
  const { tokenData, hasRole } = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [notes, setNotes] = useState<PersonalNote[]>([]);
  const [selectedNote, setSelectedNote] = useState<PersonalNote | null>(null);
  const [attachments, setAttachments] = useState<Attachment[]>([]);
  const [activeBlock, setActiveBlock] = useState<NotesBlockType | null>(null);
  const [comments, setComments] = useState<NoteComment[]>([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [deleting, setDeleting] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [searchFilter, setSearchFilter] = useState<string | undefined>();
  const [page, setPage] = useState<number>(0);
  const [hasMorePages, setHasMorePages] = useState<boolean>(true);
  const [value, setValue] = useState('');
  const [beforeUnloadState, setBeforeUnloadState] =
    useState<BeforeUnloadState | null>(null);

  const isDirty = useMemo(() => {
    if (!selectedNote) {
      return false;
    }
    return selectedNote?.content !== value;
  }, [selectedNote, value]);

  const fetchNotesByPage = useCallback(
    async (page: number) => {
      try {
        setLoading(true);
        setError('');
        let currentNotes: PersonalNote[] = [];

        if (hasRole(Role.Founder)) {
          currentNotes = await foundersAPI.getPersonalNotesByPages(page);
        } else {
          currentNotes = await mentorsAPI.getPersonalNotesByPages(page);
        }

        if (currentNotes.length === 0) {
          setHasMorePages(false);
        } else {
          setNotes((prev) => [...prev, ...currentNotes]);
          setPage((prev) => prev + 1);
        }

        return currentNotes;
      } catch (error) {
        setError('Error fetching notes');
        enqueueSnackbar(
          'Something went wrong while fetching notes, please try again',
          {
            variant: 'error',
          },
        );
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, hasRole],
  );

  const fetchInitialPage = useCallback(async () => {
    setHasMorePages(true);
    setNotes([]);
    await fetchNotesByPage(0);
  }, [fetchNotesByPage]);

  const fetchNextPage = useCallback(async () => {
    if (hasMorePages && !loading && !searchFilter) {
      await fetchNotesByPage(page);
    }
  }, [hasMorePages, loading, searchFilter, fetchNotesByPage, page]);

  const fetchBySearchFilter = useCallback(
    async (search: string) => {
      try {
        setLoading(true);
        setHasMorePages(false);
        setPage(0);
        setNotes([]);
        setError('');
        let currentNotes: PersonalNote[] = [];

        if (hasRole(Role.Founder)) {
          currentNotes = await foundersAPI.searchPersonalNotes(search);
        } else {
          currentNotes = await mentorsAPI.searchPersonalNotes(search);
        }

        setNotes(currentNotes);

        return currentNotes;
      } catch (error) {
        setError('Error searching notes');
        enqueueSnackbar(
          'Something went wrong while searching notes, please try again',
          {
            variant: 'error',
          },
        );
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, hasRole],
  );

  const updateNote = useCallback(
    async (note: PersonalNote) => {
      try {
        setSaving(true);
        let updatedNote: PersonalNote;

        if ('founderId' in note) {
          updatedNote = await foundersAPI.updatePersonalNotes(note);
        } else {
          updatedNote = await mentorsAPI.updatePersonalNotes(note);
        }

        setNotes((prevNotes) => {
          const copyPrevNotes = [...prevNotes];
          const noteIndex = copyPrevNotes.findIndex(
            (n) => n.parentId === note.parentId,
          );
          if (noteIndex !== -1) {
            copyPrevNotes[noteIndex] = updatedNote;
          }
          return copyPrevNotes;
        });

        return updatedNote;
      } catch (error) {
        setError('Error updating note');
        enqueueSnackbar(
          'Something went wrong while updating note, please try again',
          {
            variant: 'error',
          },
        );
      } finally {
        setSaving(false);
      }
    },
    [enqueueSnackbar],
  );

  const createNote = useCallback(
    async (note: PersonalNote) => {
      try {
        setSaving(true);
        let newNote: PersonalNote | undefined;

        if ('founderId' in note) {
          newNote = await foundersAPI.createPersonalNotes(note);
        } else {
          newNote = await mentorsAPI.createPersonalNotes(note);
        }

        if (!newNote) {
          throw new Error('Error creating note');
        } else {
          setNotes((prevNotes) =>
            newNote ? [newNote, ...prevNotes] : prevNotes,
          );
        }

        return newNote;
      } catch (error) {
        setError('Error creating note');
        enqueueSnackbar(
          'Something went wrong while creating note, please try again',
          {
            variant: 'error',
          },
        );
      } finally {
        setSaving(false);
      }
    },
    [enqueueSnackbar],
  );

  const deleteNote = useCallback(
    async (note: PersonalNote) => {
      try {
        setDeleting(true);
        let response;

        if ('founderId' in note) {
          response = await foundersAPI.removePersonalNote(note);
        } else {
          response = await mentorsAPI.removePersonalNote(note);
        }

        setNotes((prevNotes) => {
          return prevNotes.filter((n) => n.parentId !== note.parentId);
        });

        return response;
      } catch (error) {
        setError('Error deleting note');
        enqueueSnackbar(
          'Something went wrong while deleting note, please try again',
          {
            variant: 'error',
          },
        );
      } finally {
        setDeleting(false);
      }
    },
    [enqueueSnackbar],
  );

  const closeAllBlocks = useCallback(() => {
    if (activeBlock) {
      setActiveBlock(null);
    }
  }, [activeBlock]);

  const toggleAttachments = useCallback(() => {
    setActiveBlock((prev) =>
      prev === NotesBlockType.Attachment ? null : NotesBlockType.Attachment,
    );
  }, []);

  const toggleComments = useCallback(() => {
    setActiveBlock((prev) =>
      prev === NotesBlockType.Comment ? null : NotesBlockType.Comment,
    );
  }, []);

  const resetSearch = useCallback(() => {
    setSearchValue('');
    setSearchFilter(undefined);
  }, [setSearchFilter, setSearchValue]);

  const deleteComment = useCallback(
    async (commentId: string) => {
      try {
        await commentsAPI.deleteComment(commentId);
        setComments((prevComments) => {
          return prevComments.filter((c) => c.id !== commentId);
        });
      } catch (error) {
        setError('Error deleting comment');
        enqueueSnackbar(
          'Something went wrong while deleting comment, please try again',
          {
            variant: 'error',
          },
        );
      }
    },
    [enqueueSnackbar],
  );

  const handleSaveNote = useCallback(async () => {
    if (selectedNote) {
      const updatedNoteData: PersonalNote = {
        ...selectedNote,
        content: value,
        attachmentRefs: JSON.stringify(attachments),
      };
      const updatedNote = await updateNote(updatedNoteData);

      if (updatedNote) {
        setSelectedNote(isMobile() ? null : updatedNote);

        enqueueSnackbar('Note updated successfully', {
          variant: 'success',
        });
      }
    }
  }, [
    attachments,
    enqueueSnackbar,
    selectedNote,
    setSelectedNote,
    updateNote,
    value,
  ]);

  const handleNoteRemove = useCallback(
    async (callback: Function) => {
      if (!selectedNote) {
        setSelectedNote(notes[0]);
        return;
      }

      const res = await deleteNote(selectedNote);

      if (res) {
        enqueueSnackbar('Note removed successfully', {
          variant: 'success',
        });
      }

      callback();
    },
    [deleteNote, enqueueSnackbar, notes, selectedNote, setSelectedNote],
  );

  const handleCardSelect = useCallback(
    (note: PersonalNote) => {
      if (note.parentId === selectedNote?.parentId) {
        return;
      }

      if (isDirty) {
        setBeforeUnloadState({ action: 'select', note });
      } else {
        setBeforeUnloadState(null);
        setSelectedNote(note);
      }
    },
    [selectedNote, isDirty, setBeforeUnloadState, setSelectedNote],
  );

  const handleCardClose = useCallback(() => {
    if (isDirty) {
      setBeforeUnloadState({ action: 'close' });
    } else {
      setBeforeUnloadState(null);
      setSelectedNote(null);
    }
  }, [isDirty, setBeforeUnloadState, setSelectedNote]);

  const addNewNote = useCallback(async () => {
    const newNoteData: PersonalNote = hasRole(Role.Founder)
      ? {
          content: '',
          attachmentRefs: '[]',
          type: 'GeneralNote',
          tenantId: user.id,
          founderId: tokenData?.identityid || null,
          date: new Date().toISOString(),
        }
      : {
          content: '',
          attachmentRefs: '[]',
          type: 'GeneralNote',
          tenantId: user.id,
          mentorId: tokenData?.identityid || null,
          date: new Date().toISOString(),
        };
    const newNote = await createNote(newNoteData);

    if (newNote) {
      setSelectedNote(newNote);
      resetSearch();
    }

    // scroll card list to the top
    try {
      const cardList = document.getElementById('cardList');
      cardList?.scrollTo(0, 0);
    } catch (e) {
      console.error(e);
    }
  }, [
    createNote,
    hasRole,
    resetSearch,
    setSelectedNote,
    tokenData?.identityid,
    user.id,
  ]);

  const handleAddNewNote = useCallback(() => {
    if (isDirty) {
      setBeforeUnloadState({ action: 'add' });
    } else {
      setBeforeUnloadState(null);
      addNewNote();
    }
  }, [addNewNote, isDirty]);

  useEffect(() => {
    if (searchFilter) {
      fetchBySearchFilter(searchFilter);
    } else {
      fetchInitialPage();
    }
  }, [searchFilter, fetchInitialPage, fetchBySearchFilter]);

  useEffect(() => {
    const findSelectedNote = selectedNote
      ? notes.some((note) => note.parentId === selectedNote.parentId)
      : false;

    if (findSelectedNote) {
      return;
    }

    setSelectedNote(notes.length && !isMobile() ? notes[0] : null);
  }, [notes, selectedNote]);

  useEffect(() => {
    setActiveBlock(null);
  }, [selectedNote?.parentId]);

  const contextValue: PersonalNotesContextProps = {
    notes,
    selectedNote,
    attachments,
    activeBlock,
    showAttachments: activeBlock === NotesBlockType.Attachment,
    showComments: activeBlock === NotesBlockType.Comment,
    comments,
    loadingComments,
    loading,
    saving,
    deleting,
    error,
    searchFilter,
    searchValue,
    value,
    isDirty,
    beforeUnloadState,
    setBeforeUnloadState,
    setValue,
    setSearchValue,
    setSelectedNote,
    setAttachments,
    toggleAttachments,
    toggleComments,
    setComments,
    setLoadingComments,
    setSaving,
    closeAllBlocks,
    createNote,
    updateNote,
    deleteNote,
    fetchNextPage,
    setSearchFilter,
    deleteComment,
    resetSearch,
    handleSaveNote,
    handleNoteRemove,
    addNewNote,
    handleAddNewNote,
    handleCardSelect,
    handleCardClose,
  };

  return (
    <PersonalNotesContext.Provider value={contextValue}>
      {children}
    </PersonalNotesContext.Provider>
  );
};

export const usePersonalNotes = () => {
  const context = useContext(PersonalNotesContext);
  if (!context) {
    throw new Error(
      'usePersonalNotes must be used within a PersonalNotesProvider',
    );
  }
  return context;
};
