import { IconButton, LinearProgress, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import filesAPI from '../../api/files';
import { ImgFileIcon, PdfFileIcon, TxtFileIcon } from '../../assets/icons';
import { COLORS } from '../../theme/variables';
import { humanFileSize, isMobile } from '../../utils/functions';
import ConfirmButton from './confirm-button';
import Text from './text';

export interface Attachment {
  name: string;
  extension: string;
  size: number;
  url?: string;
}

export interface AttachmentCardProps {
  attachment: Attachment;
  loading?: boolean;
  onRemove?: () => any;
  classesFileName?: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '10px 45px 10px 16px',
    position: 'relative',
    border: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    background: 'white',
    boxSizing: 'border-box',
    borderRadius: 4,
  },
  link: {
    '&:hover': {
      background: COLORS.COLOR_GRAY_LIGHTENED_45,
    },
  },
  disabled: {
    pointerEvents: 'none',
  },
  loader: {
    padding: '8px 0',
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5,

    '& svg': {
      fontSize: 19,
    },
  },
  fileInfoContainer: {
    paddingLeft: 16,
    width: 'calc(100% - 32px)',
    boxSizing: 'border-box',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  fileName: {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export function getAttachmentFromFile(file: File): Attachment {
  const splittedFilename = file.name.split('.');
  const extension = splittedFilename.pop();

  return {
    name: splittedFilename.join('.'),
    extension: extension || '',
    size: file.size,
  };
}

function getIconByExtension(extension: string) {
  switch (extension) {
    case 'pdf':
      return <PdfFileIcon />;
    case 'bmp':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'png':
      return <ImgFileIcon />;
    default:
      return <TxtFileIcon />;
  }
}

function AttachmentCard({
  attachment,
  loading,
  onRemove,
  classesFileName,
}: AttachmentCardProps) {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);
  const [preloading, setPreloading] = useState(false);
  const [preloadedUrl, setPreloadedUrl] = useState('');
  const timer = useRef<number>();

  const isLoading = loading || preloading;

  const handleRemove = useCallback(
    (cbSuccess: () => any, e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (typeof onRemove === 'function') {
        onRemove();
        cbSuccess();
      }
    },
    [onRemove],
  );

  const handleDownloadFile = async (event: React.MouseEvent) => {
    if (preloadedUrl) {
      window.open(preloadedUrl, '_blank');
      return;
    }

    if (!attachment.url) return;

    try {
      const signed = await filesAPI.getFile(attachment?.url || '');
      window.open(signed, '_blank');
    } catch (e: any) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (isLoading) {
      timer.current = setInterval(() => {
        setProgress((oldProgress) => {
          const diff = Math.random() * 10;
          return Math.min(oldProgress + diff, 100);
        });
      }, 500) as unknown as number;

      return () => {
        clearInterval(timer.current);
      };
    }
  }, [isLoading]);

  useEffect(() => {
    const preloadUrl = async () => {
      if (!attachment.url) return;

      try {
        setPreloading(true);
        const signed = await filesAPI.getFile(attachment?.url || '');
        setPreloadedUrl(signed);
      } catch (e: any) {
        console.error(e);
      } finally {
        setPreloading(false);
      }
    };

    if (isMobile()) {
      preloadUrl();
    }
  }, [attachment]);

  return (
    <span
      onClick={handleDownloadFile}
      className={cn(classes.container, {
        [classes.link]: !!attachment.url && !isLoading,
        [classes.disabled]: isLoading,
      })}>
      {!!onRemove && (
        <ConfirmButton
          title='Remove the attachment?'
          body='Are you sure you want to remove the attachment?'
          successProps={{
            btnLabel: 'Remove',
            onSuccess: handleRemove,
          }}
          cancelProps={{
            btnLabel: 'Cancel',
          }}
          buttonRenderer={({ onClick }) => (
            <IconButton
              className={classes.closeButton}
              aria-label='close'
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                e.stopPropagation();
                onClick();
              }}>
              <CloseIcon />
            </IconButton>
          )}
        />
      )}
      {getIconByExtension(attachment.extension)}
      <span className={classes.fileInfoContainer}>
        <span className={classes.fileInfo}>
          <Text
            className={cn(classes.fileName, classesFileName)}
            variant='normal'>
            {attachment.name}
          </Text>
          <Text variant='normal'>.{attachment.extension}</Text>
        </span>
        {isLoading ? (
          <div className={classes.loader}>
            <LinearProgress variant='determinate' value={progress} />
          </div>
        ) : (
          <Text variant='normal2' color={COLORS.COLOR_GRAY_DARKENED}>
            {humanFileSize(attachment.size)}
          </Text>
        )}
      </span>
    </span>
  );
}

export default AttachmentCard;
