import { CircularProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import eventsAPI, {
  EventFullDetails,
  EventId,
  EventResponseForm,
} from '../../api/events';
import { Role } from '../../api/user/Role';
import venturesAPI from '../../api/ventures';
import { SnackMessage } from '../../components/common';
import { SelectOptions } from '../../components/common/custom-select';
import ReportAgendaRequestForm, {
  FormInitialValues,
  FormValues,
} from '../../components/forms/report-agenda-request-form';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { SessionContext } from '../../contexts/session-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';

enum AgendaRequestReportStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '560px',
    maxWidth: '100%',
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
}));

export const formatValuesRequest = (
  values: FormValues,
  fullDetails: EventFullDetails,
): EventResponseForm => {
  return {
    tenantId: fullDetails.event.tenantId,
    eventId: fullDetails.event.id,
    ventureId: fullDetails.event.ventureId,
    sessionRating: 0,
    ventureProgressRating: +values.ventureProgressRating,
    attendingMentors: [],
    attendingFounders: [],
    mostImpactfulMentor: '',
    submitterEmail: values.submitterEmail,
    mentorReport: '',
    mentorReportAttachments: '',
    mentorReportNotes: '',
    founderNotes: '',
    founderOtherNotes: '',
    founderNotesAttachments: '',
    agendaQuestion1: values.achieveMentoringSession || '',
    agendaQuestion2: values.happenedLastSession || '',
    agendaQuestion3: values.goingHappen || '',
    agendaQuestion4: values.additionalComments || '',
    agendaAttachments: values.attachments || '',
    nextEventStart: '',
    nextEventEnd: '',
    isLeadMentorReport: false,
    isMentorReport: false,
    isFounderReport: false,
    founderReport: false,
    agendaPresented: true,
  };
};

const getInitialValues = (
  fullDetails: EventFullDetails,
  defaultEmail?: string,
): FormInitialValues => {
  return {
    submitterEmail: defaultEmail || '',
    summary: fullDetails?.event.summary || '',
    start: fullDetails?.event.start || '',
    end: fullDetails?.event.end || '',
    ventureProgressRating: 0,
    achieveMentoringSession: '',
    happenedLastSession: '',
    goingHappen: '',
    additionalComments: '',
    attachments: [],
  };
};

const errorMessage =
  'Unfortunately an error occurred while loading the report. Please try again in a couple of minutes. If the problem persists please share the URL with the support team at support@tractionfive.com';

function SessionAgendaRequestPage() {
  const classes = useStyles();
  const history = useHistory();
  const { rb } = useResourceBundles();
  const { tokenData, hasRole } = useContext(UserContext);
  const { sessionId } = useParams<{ sessionId: EventId }>();
  const { sessionKey, loadSession } = useContext(SessionContext);

  const [fullDetails, setFullDetails] = useState<EventFullDetails>();
  const [statusForm, setStatusForm] = useState<AgendaRequestReportStatus>(
    AgendaRequestReportStatus.LOADING,
  );
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorVaildDate, setErrorVaildDate] = useState(false);

  const initialValues = useMemo(() => {
    if (fullDetails) {
      const submitterEmail =
        hasRole(Role.Founder) || hasRole(Role.Mentor)
          ? tokenData?.email ?? ''
          : '';
      return getInitialValues(fullDetails, submitterEmail);
    }
    return undefined;
  }, [fullDetails, hasRole, tokenData?.email]);

  const emailOptions: SelectOptions[] | undefined = useMemo(() => {
    if (!hasRole(Role.Admin) && !hasRole(Role.Manager)) {
      return undefined;
    }

    const founderList = fullDetails?.founderList;
    const mentorList = fullDetails?.mentorList;

    const categories: SelectOptions[] = [];

    if (founderList && founderList.length) {
      categories.push(
        {
          label: 'Founders',
          isCategory: true,
        },
        ...founderList.map((founder) => ({
          value: founder.email,
          label: [
            founder.firstName,
            founder.lastName,
            `(${founder.email})`,
          ].join(' '),
        })),
      );
    }

    if (mentorList && mentorList.length) {
      categories.push(
        {
          label: rb('mentors-u'),
          isCategory: true,
        },
        ...mentorList.map((mentor) => ({
          value: mentor.email,
          label: [mentor.firstName, mentor.lastName, `(${mentor.email})`].join(
            ' ',
          ),
        })),
      );
    }

    return categories.length ? categories : undefined;
  }, [fullDetails?.founderList, fullDetails?.mentorList, hasRole, rb]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      const valuesRequest = formatValuesRequest(
        values,
        fullDetails as EventFullDetails,
      );

      try {
        setIsLoadingSaveForm(true);

        await eventsAPI.eventsResponseForm(valuesRequest);
        setErrorVaildDate(false);
        enqueueSnackbar(`Agenda request submitted successfully`, {
          variant: 'success',
        });
        loadSession(sessionId);
        history.push(
          getRoutePath(Pages.SESSIONS_DETAILS, {
            sessionId,
          }),
        );
        setIsLoadingSaveForm(false);
      } catch (e: any) {
        setIsLoadingSaveForm(false);
        const messageError =
          e.response?.data?.message || 'Internal server error';
        enqueueSnackbar('An error occurred while saving your report', {
          variant: 'error',
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          style: { whiteSpace: 'pre-line' },
        });
      }
    },
    [enqueueSnackbar, fullDetails, history, loadSession, sessionId],
  );

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        if (fullDetails?.event.ventureId) {
          const loadedFileURL = await venturesAPI.attachFileToReport(
            fullDetails.event.ventureId,
            fullDetails.event.tenantId,
            file,
          );
          return loadedFileURL;
        }
      } catch (e: any) {
        return '';
      }
    },
    [fullDetails],
  );

  useEffect(() => {
    const loadDetails = async (key: string) => {
      try {
        setStatusForm(AgendaRequestReportStatus.LOADING);
        const responseDetails = await eventsAPI.getEventDetails(key);
        setFullDetails(responseDetails);
        setStatusForm(AgendaRequestReportStatus.LOADED);
      } catch (e: any) {
        enqueueSnackbar(errorMessage, {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage:
                e.response?.data?.message || 'Internal server error',
            }),
          variant: 'error',
        });
      }
    };

    if (sessionKey) {
      loadDetails(sessionKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionKey]);

  if (statusForm === AgendaRequestReportStatus.LOADING || !fullDetails) {
    return (
      <div className={classes.stateContainer}>
        <CircularProgress size={54} color='primary' />
      </div>
    );
  }

  return (
    <div className={classes.formContainer}>
      <ReportAgendaRequestForm
        initialValues={initialValues}
        fullDetails={fullDetails}
        onSubmit={handleSubmit}
        loading={isLoadingSaveForm}
        errorVaildDate={errorVaildDate}
        onUploadFile={handleUploadFile}
        isStickySubmit
        emailOptions={emailOptions}
        hasBeforeUnload
      />
    </div>
  );
}

export default SessionAgendaRequestPage;
