import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { useContext, useEffect } from 'react';
import { TextWysiwyg } from '../../components/common';
import PersonalNotesAttachments from '../../components/common/PersonalNotes/personal-notes-attachments';
import PersonalNotesComments from '../../components/common/PersonalNotes/personal-notes-comments';
import { Attachment } from '../../components/common/attachment-card';
import { usePersonalNotes } from '../../contexts/personal-notes-context';
import { UserContext } from '../../contexts/user-context';
import NoteEditorActions from './note-editor-actions';

const useStyles = makeStyles(() => ({
  inputWrapper: {
    position: 'relative',
    zIndex: 2,
    flexGrow: 1,
    overflow: 'hidden',
  },
  inputContainer: {
    width: '100%',
    height: '100%',
    position: 'relative',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 1,
      pointerEvents: 'none',
      opacity: 0,
      transition: 'opacity 0.3s ease-in-out',
    },

    '&.mask': {
      '&::before': {
        opacity: 1,
      },
    },

    '& > div:first-child': {
      height: '100%',

      '& > div': {
        height: '100%',
        overflow: 'auto',
        border: 'none !important',
        borderRadius: '0 !important',
        boxSizing: 'border-box',

        '&::-webkit-scrollbar-track': {
          marginTop: '39px',
        },

        '& > div:first-child': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          backgroundColor: 'rgb(244, 246, 248) !important',
        },

        '& > div:last-child': {
          paddingTop: '47.5px !important',
        },
      },
    },
  },
}));

const parseAttachments = (attachmentRefs: string): Attachment[] => {
  try {
    return JSON.parse(attachmentRefs);
  } catch (e: any) {
    return [];
  }
};

function NoteEditor() {
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);
  const {
    selectedNote,
    activeBlock,
    value,
    setValue,
    setAttachments,
    closeAllBlocks,
  } = usePersonalNotes();

  useEffect(() => {
    // Update note content and attachments when selected note is changed
    if (selectedNote) {
      setValue(selectedNote.content);
      const parsedAttachments = parseAttachments(
        selectedNote.attachmentRefs || '',
      );
      setAttachments(parsedAttachments || []);
    } else {
      setValue('');
      setAttachments([]);
    }
  }, [selectedNote, setAttachments, setValue]);

  return (
    <>
      <div className={classes.inputWrapper}>
        <div
          className={cn(classes.inputContainer, {
            mask: !!activeBlock,
          })}
          onClick={closeAllBlocks}>
          <TextWysiwyg
            placeholder={'Add a note'}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            disabled={!hasAccessToAction('personalNotes.update')}
          />
        </div>
        {hasAccessToAction('personalNotes.update') && (
          <PersonalNotesAttachments />
        )}
        <PersonalNotesComments />
      </div>
      {hasAccessToAction('personalNotes.update') && <NoteEditorActions />}
    </>
  );
}

export default NoteEditor;
