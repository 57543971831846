import { makeStyles, CircularProgress, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import {
  JSXElementConstructor,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import { Form, Field } from 'react-final-form';
import { Question, Type } from '../../api/tenants/QuestionTemplates/Question';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { validateEmail } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import {
  FormGroup,
  Button,
  FormButtons,
  Text,
  AlertState,
  BeforeUnload,
  StickyContent,
} from '../common';
import { SelectOptions } from '../common/custom-select';
import {
  TextFieldSummary,
  FormRating,
  TextFieldWysiwyg,
  NumberField,
  FormSelect,
} from './wrappers';

const useStyles = makeStyles((theme) => ({
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },

  formContainer: {
    width: '100%',
  },
  ul_list: {
    '& ul, li': {
      listStyle: 'unset',
    },
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  alert: {
    margin: '20px 0',
  },
  title: {
    margin: '20px 0',
  },
  subtitle: {
    margin: '20px 0 10px',
  },
  fields_line: {
    display: 'flex',
    alignItems: 'center',

    '& + &': {
      marginTop: 20,
    },
  },
  field_amount: {
    width: '35%',
  },
  field_comment: {
    width: 'calc(67% - 20px)',
    marginLeft: 20,
  },
  numberDesc: {
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
  actionsBlock: {
    marginTop: 56,
  },
}));

export interface FormValues {
  ventureName: string;
  submitter: string;
  fte: string;
  revenue: string;
  equityRaised: string;
  equityComments: string;
  grantsRaised: string;
  grantsComments: string;
  crowdFunding: string;
  crowdComments: string;
  loans: string;
  loansComments: string;
  other: string;
  otherComments: string;
  rating: string;
  questions: Record<string, string | number | undefined>;
}

export interface FormInitialValues {
  ventureName: string;
  submitter: string;
  fte: string;
  revenue: string;
  equityRaised: string;
  equityComments: string;
  grantsRaised: string;
  grantsComments: string;
  crowdFunding: string;
  crowdComments: string;
  loans: string;
  loansComments: string;
  other: string;
  otherComments: string;
  rating: string;
  questions: Record<string, undefined | string | number>;
}

interface Props {
  onSubmit: (values: FormValues) => void;
  loading: boolean;
  initialValues?: FormInitialValues;
  reportDate: {
    quarter: number;
    year: number;
  };
  questions: Question[];
  viewMode?: boolean;
  emailOptions?: SelectOptions[];
  isStickySubmit?: boolean;
  hasBeforeUnload?: boolean;
}

interface Errors {
  ventureName?: string;
  submitter?: string;
  fte?: string;
  revenue?: string;
  equityRaised?: string;
  equityComments?: string;
  grantsRaised?: string;
  grantsComments?: string;
  crowdFunding?: string;
  crowdComments?: string;
  loans?: string;
  loansComments?: string;
  other?: string;
  otherComments?: string;
  rating?: string;
  questions: Record<string, string | undefined>;
}

const validateForm = (questions: Question[]) => (values: FormValues) => {
  const errors: Errors = {
    questions: {},
  };

  if (!values.submitter) {
    errors.submitter = 'Required';
  } else if (!validateEmail(values.submitter)) {
    errors.submitter = 'Invalid';
  }
  if (!values.rating || values.rating === '0') {
    errors.rating = 'required';
  }
  if (!values.fte) {
    errors.fte = 'required';
  }
  if (!values.revenue) {
    errors.revenue = 'required';
  }

  questions.forEach((q) => {
    const isRequired = q.required;
    const questionValue = values?.questions && values.questions[q.id];
    const isEmptyValue = ['', undefined, null].includes(
      questionValue as string,
    );

    if (isEmptyValue) {
      if (isRequired) {
        errors.questions[q.id] = 'required';
      }
      return;
    }

    switch (q.type) {
      case Type.NUMBER: {
        if (isNaN(Number(questionValue))) {
          errors.questions[q.id] = 'invalid number';
        }
        return;
      }
      case Type.PARAGRAPH: {
        if ((questionValue as string)?.length > 2048) {
          errors.questions[q.id] = 'string is too long';
          return;
        }
      }
    }
  });

  return errors;
};

const getMonthesByQuarter = (quarter: number) => {
  switch (quarter) {
    case 1: {
      return '(Jan, Feb, March)';
    }
    case 2: {
      return '(April, May, June)';
    }
    case 3: {
      return '(July, August, Sept)';
    }
    default: {
      return '(Oct, Nov, Dec)';
    }
  }
};

function ReportVentureQuarterForm({
  onSubmit,
  loading,
  initialValues,
  reportDate,
  viewMode = false,
  questions,
  emailOptions,
  isStickySubmit,
  hasBeforeUnload = false,
}: Props) {
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const setSubmitValidationFailed = useScrollOnValidation();
  const isSubmitDisabled = loading;

  const handleSubmit = (values: FormValues) => {
    onSubmit(values);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validator = useCallback(validateForm(questions), [questions]);

  return (
    <Form
      validate={validator}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      keepDirtyOnReinitialize
      render={(formProps) => {
        setSubmitValidationFailed(
          formProps.submitFailed &&
            !formProps.dirtySinceLastSubmit &&
            !formProps.submitting,
        );
        return (
          <div className={classes.formBlocks}>
            <form noValidate className={classes.formContainer}>
              {hasBeforeUnload && (
                <BeforeUnload
                  when={formProps.dirty && !loading}
                  title='Leave the page'
                  body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                  disabled={loading}
                  confirmButtonRenderer={({ onConfirm }) => (
                    <Button
                      variant='outlined'
                      onClick={async () => {
                        try {
                          await formProps.handleSubmit();
                          onConfirm();
                        } catch (e: any) {}
                      }}
                      disabled={loading || !formProps.valid}>
                      {loading ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Save the changes'
                      )}
                    </Button>
                  )}
                />
              )}
              <FormGroup>
                <Field<string>
                  name='ventureName'
                  component={TextFieldSummary}
                  label='Venture'
                  disabled
                />
              </FormGroup>
              <Typography className={classes.title} variant='h4'>
                Q{reportDate.quarter} Report {reportDate.year}{' '}
                {getMonthesByQuarter(reportDate.quarter)}
              </Typography>
              {emailOptions ? (
                <FormGroup>
                  <Field<string>
                    name='submitter'
                    testid='submitter-email'
                    component={FormSelect}
                    label='Author*'
                    options={emailOptions}
                  />
                </FormGroup>
              ) : (
                <FormGroup>
                  <Field<string>
                    name='submitter'
                    component={TextFieldSummary}
                    label='Email*'
                    InputProps={{
                      inputProps: {
                        readOnly: viewMode,
                      },
                    }}
                  />
                </FormGroup>
              )}
              <FormGroup>
                <Typography variant='h5'>
                  1. What is your Full Time Employee Equivalent number? Your FTE
                  number = (Total average of part-time hours worked per week /
                  30) + # of full-time employees
                </Typography>
              </FormGroup>
              <AlertState className={classes.alert} type='info'>
                <Text className={classes.ul_list} variant='normal'>
                  It’s pretty easy. Grab a pencil, open your notebook, and we’ll
                  show you exactly how to calculate your FTE count.
                  <br />
                  <br />
                  <ul>
                    <li>
                      <b>Step 1:</b> How many part-time workers do you have?{' '}
                      <i>
                        According to the IRS, part-timers are those who work
                        less than 130 hours a month, or 30 hours a week.
                      </i>
                    </li>
                    <li>
                      <b>Step 2:</b> Write down the average number of hours each
                      part-time employee works per week. Now, add them all
                      together.
                    </li>
                    <li>
                      <b>Step 3:</b> Take the result and divide it by 30.
                    </li>
                    <li>
                      <b>Step 4:</b> Round down to the nearest whole number.
                    </li>
                  </ul>
                </Text>
              </AlertState>
              <FormGroup>
                <Field<string>
                  name='fte'
                  component={TextFieldSummary}
                  label='Number of Full-Time Employees*'
                  format={(val) => (val ? val.replace(/[^0-9]+/g, '') : val)}
                  InputProps={{
                    inputProps: {
                      readOnly: viewMode,
                    },
                  }}
                />
              </FormGroup>
              <Typography className={classes.subtitle} variant='h5'>
                2. What was your total revenue in the last quarter?
              </Typography>
              <FormGroup>
                <Field<string>
                  name='revenue'
                  component={TextFieldSummary}
                  label='Revenue last quarter*'
                  format={(val = '') =>
                    val
                      // Allow only numbers
                      .replace(/[^0-9]+/g, '')
                      // Prevent number starting with 0
                      .replace(/^0/g, '')
                      // Number mus be less then 10.000.000
                      .slice(0, 7)
                  }
                  InputProps={{
                    inputProps: {
                      readOnly: viewMode,
                    },
                  }}
                />
              </FormGroup>
              <Typography className={classes.subtitle} variant='h5'>
                3. Share funds raised or borrowed last quarter. Please fill out
                and comment on relevant fields
              </Typography>
              <div>
                <div className={classes.fields_line}>
                  <div className={classes.field_amount}>
                    <Field<string>
                      name='equityRaised'
                      component={TextFieldSummary}
                      label='Equity Raised ($)'
                      format={(val) =>
                        val ? val.replace(/[^0-9]+/g, '') : val
                      }
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.field_comment}>
                    <Field<string>
                      name='equityComments'
                      component={TextFieldSummary}
                      label='Please provide some details'
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className={classes.fields_line}>
                  <div className={classes.field_amount}>
                    <Field<string>
                      name='grantsRaised'
                      component={TextFieldSummary}
                      label='Grants Received ($)'
                      format={(val) =>
                        val ? val.replace(/[^0-9]+/g, '') : val
                      }
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.field_comment}>
                    <Field<string>
                      name='grantsComments'
                      component={TextFieldSummary}
                      label='Please provide some details'
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className={classes.fields_line}>
                  <div className={classes.field_amount}>
                    <Field<string>
                      name='crowdFunding'
                      component={TextFieldSummary}
                      label='Crowdfunding Raised ($)'
                      format={(val) =>
                        val ? val.replace(/[^0-9]+/g, '') : val
                      }
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.field_comment}>
                    <Field<string>
                      name='crowdComments'
                      component={TextFieldSummary}
                      label='Please provide some details'
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className={classes.fields_line}>
                  <div className={classes.field_amount}>
                    <Field<string>
                      name='loans'
                      component={TextFieldSummary}
                      label='Loans Received ($)'
                      format={(val) =>
                        val ? val.replace(/[^0-9]+/g, '') : val
                      }
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.field_comment}>
                    <Field<string>
                      name='loansComments'
                      component={TextFieldSummary}
                      label='Please provide some details'
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                </div>
                <div className={classes.fields_line}>
                  <div className={classes.field_amount}>
                    <Field<string>
                      name='other'
                      component={TextFieldSummary}
                      label='Other Funding ($)'
                      format={(val) =>
                        val ? val.replace(/[^0-9]+/g, '') : val
                      }
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                  <div className={classes.field_comment}>
                    <Field<string>
                      name='otherComments'
                      component={TextFieldSummary}
                      label='Please provide some details'
                      InputProps={{
                        inputProps: {
                          readOnly: viewMode,
                        },
                      }}
                    />
                  </div>
                </div>
              </div>
              <Typography className={classes.subtitle} variant='h5'>
                4. How likely would you recommend our {rb('mentorship')} program
                to a friend?
              </Typography>
              <FormGroup>
                <Field<number>
                  name='rating'
                  component={FormRating}
                  max={11}
                  readOnly={viewMode}
                  withCaption
                />
              </FormGroup>

              {questions.map((q, i) => {
                return (
                  <div data-testid='question-item'>
                    <FieldDescription index={i} question={q} />
                    <FormGroup key={q.id}>
                      <Field
                        name={`questions.${q.id}`}
                        component={questionComponent(q)}
                        max={11}
                        readOnly={viewMode}
                        withCaption
                      />
                    </FormGroup>
                  </div>
                );
              })}

              {!viewMode &&
                (isStickySubmit ? (
                  <div className={classes.actionsBlock}>
                    <StickyContent>
                      <Button
                        className={CLASS_TRACKING.INTERNAL_ACTION}
                        onClick={formProps.handleSubmit}
                        disabled={isSubmitDisabled}
                        data-testid='button-submit-form'
                        startIcon={<CheckIcon />}>
                        {loading ? (
                          <CircularProgress size={24} color='inherit' />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </StickyContent>
                  </div>
                ) : (
                  <FormButtons className={classes.formButtons}>
                    <Button
                      data-testid='button-submit-form'
                      onClick={formProps.handleSubmit}
                      disabled={isSubmitDisabled}>
                      {loading ? (
                        <CircularProgress size={24} color='inherit' />
                      ) : (
                        'Save'
                      )}
                    </Button>
                  </FormButtons>
                ))}
            </form>
          </div>
        );
      }}
    />
  );
}

function questionComponent(q: Question): JSXElementConstructor<any> {
  switch (q.type) {
    case Type.PARAGRAPH:
      return TextFieldWysiwyg;
    case Type.NUMBER:
      return NumberField;
  }
}

function FieldDescription({
  index,
  question,
}: {
  index: number;
  question: Question;
}): ReactElement {
  const classes = useStyles();

  const t = useMemo((): ReactNode => {
    switch (question.type) {
      case Type.PARAGRAPH:
        return null;
      case Type.NUMBER:
        return (
          <Typography
            component={'span'}
            variant={'subtitle2'}
            className={classes.numberDesc}>
            (number only)
          </Typography>
        );
    }
  }, [question.type, classes.numberDesc]);

  return (
    <Typography className={classes.subtitle} variant='h5' key={question.id}>
      {index + 5}. {question.description} {t} {question.required ? '*' : null}
    </Typography>
  );
}

export default ReportVentureQuarterForm;
