import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CalendarIcon from '@material-ui/icons/CalendarToday';
import { useSnackbar } from 'notistack';
import { useState, useCallback } from 'react';
import eventsAPI from '../../api/events';

interface AddToCalendarButtonProps {
  eventId: string;
  eventName: string;
}

export function downloadICSFile(icsString: string, fileName: string) {
  const element = document.createElement('a');
  const file = new Blob([icsString], { type: 'text/calendar;charset=utf-8' });
  element.href = URL.createObjectURL(file);
  element.download = fileName.endsWith('.ics') ? fileName : `${fileName}.ics`;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
}

function AddToCalendarButton({ eventId, eventName }: AddToCalendarButtonProps) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const uploadCalendarIcs = useCallback(async () => {
    try {
      setLoading(true);
      const ics = await eventsAPI.uploadCalendarIcs(eventId);
      downloadICSFile(ics, `${eventName}.ics`);
      setLoading(false);
    } catch (error) {
      enqueueSnackbar(
        'Something went wrong when adding to calendar. Please try again.',
        { variant: 'error' },
      );
      setLoading(false);
    }
  }, [enqueueSnackbar, eventId, eventName]);

  return (
    <Button
      onClick={uploadCalendarIcs}
      startIcon={
        loading ? (
          <CircularProgress size={24} color='inherit' />
        ) : (
          <CalendarIcon />
        )
      }
      variant='outlined'
      color='primary'
      disabled={loading}
      data-testid='button-add-to-calendar'>
      Add to Calendar
    </Button>
  );
}

export default AddToCalendarButton;
