import { CircularProgress, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import eventsAPI, {
  EventAttendanceType,
  EventFullDetails,
  EventResponseForm,
} from '../../../api/events';
import { Settings, TenantTimeZone } from '../../../api/tenants/types/Settings';
import venturesAPI from '../../../api/ventures';
import {
  Button,
  Dialog,
  FormButtons,
  SnackMessage,
  Text,
} from '../../../components/common';
import WarningBeforeStart from '../../../components/common/Confirmation/WarningBeforeStart';
import ReportLeadMentorForm, {
  FormInitialValues,
  FormValues,
} from '../../../components/forms/report-lead-mentor-form';

interface LeadMentorReportProps {
  payload: EventFullDetails;
  timeZone: TenantTimeZone;
  reportBasedSchedulingEnabled: boolean;
  formSettings: Partial<Settings>;
  defaultLocation?: string;
  isStickySubmit?: boolean;
  onSuccess?: () => void;
  emailOptions?: {
    label: string;
    value: string;
  }[];
  defaultEmail?: string;
  hasBeforeUnload?: boolean;
}

const useStyles = makeStyles((theme) => ({
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
}));

const formatValuesRequest = (
  values: FormValues,
  fullDetails: EventFullDetails,
): EventResponseForm => {
  return {
    tenantId: fullDetails.event.tenantId,
    eventId: fullDetails.event.id,
    ventureId: fullDetails.event.ventureId,
    sessionRating: +(values.sessionRating || 0),
    ventureProgressRating: +(values.ventureProgressRating || 0),
    ventureReadinessRating: +(values?.ventureReadinessRating || 0),
    mentorTeamImpactRating: +(values?.mentorTeamImpactRating || 0),
    eventEnjoymentRating: +(values?.eventEnjoymentRating || 0),
    attendingMentors: Object.keys(values.attendingMentors)
      .filter((key) => values.attendingMentors[key] !== 'ABSENT')
      .map((key) => ({
        id: key,
        type: values.attendingMentors[key] as EventAttendanceType,
      })),
    attendingFounders: Object.keys(values.attendingFounders)
      .filter((key) => values.attendingFounders[key] !== 'ABSENT')
      .map((key) => ({
        id: key,
        type: values.attendingFounders[key] as EventAttendanceType,
      })),
    mostImpactfulMentor: values?.mostImpactfulMentor || '',
    submitterEmail: values.submitterEmail,
    mentorReport: values.mentorReport || '',
    mentorReportAttachments: values.attachments || '',
    mentorReportNotes: values.mentorReportNotes || '',
    founderNotes: '',
    founderOtherNotes: '',
    founderNotesAttachments: '',
    agendaQuestion1: '',
    agendaQuestion2: '',
    agendaQuestion3: '',
    agendaQuestion4: '',
    agendaAttachments: '',
    isLeadMentorReport: true,
    nextEventStart: values.nextEventStart,
    nextEventEnd: values.nextEventEnd,
    isMentorReport: false,
    isFounderReport: false,
    founderReport: false,
    agendaPresented: false,
    physicalLocationRequested:
      values.meeting === 'in-person' || values.meeting === 'hybrid'
        ? values.physicalLocation
        : undefined,
    virtualChannelRequested:
      values.meeting === 'virtually' || values.meeting === 'hybrid',
  };
};

function formatValueDateRequest(
  values: FormValues,
  fullDetails: EventFullDetails,
) {
  return {
    email: values.submitterEmail,
    event: {
      ...fullDetails.event,
      start: values.nextEventStart,
      end: values.nextEventEnd,
      physicalLocation:
        values.meeting === 'in-person' ? values.physicalLocation ?? null : null,
    },
  };
}

const getInitialValues = (
  fullDetails: EventFullDetails,
  defaultLocation?: string,
  defaultEmail?: string,
): FormInitialValues => {
  return {
    submitterEmail: defaultEmail || '',
    summary: fullDetails?.event.summary || '',
    start: fullDetails?.event.start || '',
    end: fullDetails?.event.end || '',
    sessionRating: 0,
    ventureProgressRating: 0,
    ventureReadinessRating: 0,
    mentorTeamImpactRating: 0,
    eventEnjoymentRating: 0,
    attendingMentors: {},
    attendingFounders: {},
    mostImpactfulMentor: '',
    mentorReport: '',
    mentorReportNotes: '',
    nextEventStart: '',
    nextEventEnd: '',
    rateSession: 0,
    rateVenture: 0,
    attachments: [],
    physicalLocation: defaultLocation,
    meeting: 'virtually',
  };
};

function LeadMentorReport({
  payload: fullDetails,
  timeZone,
  reportBasedSchedulingEnabled,
  formSettings,
  defaultLocation,
  isStickySubmit,
  onSuccess,
  emailOptions,
  defaultEmail,
  hasBeforeUnload,
}: LeadMentorReportProps) {
  const classes = useStyles();

  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorVaildDate, setErrorVaildDate] = useState(false);
  const [prevValue, setPrevValue] = useState<FormValues>();
  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState('');

  const initialValues = useMemo(() => {
    if (fullDetails) {
      return getInitialValues(fullDetails, defaultLocation, defaultEmail);
    }
    return undefined;
  }, [fullDetails, defaultLocation, defaultEmail]);

  const handleSubmit = async (values: FormValues) => {
    const valuesRequest = formatValuesRequest(
      values,
      fullDetails as EventFullDetails,
    );

    let valueValidDate;
    if (valuesRequest.nextEventStart && valuesRequest.nextEventEnd) {
      valueValidDate = formatValueDateRequest(values, fullDetails);
    }
    try {
      setIsLoadingSaveForm(true);
      if (valueValidDate) {
        const responseCheckValidation =
          await eventsAPI.eventsValidationAnonymous(valueValidDate);

        if (!responseCheckValidation.scheduled) {
          const newValue = {
            ...values,
            nextEventStart: '',
            nextEventEnd: '',
          };

          setPrevValue(newValue);
          setIsLoadingSaveForm(false);
          responseCheckValidation.message &&
            setModalText(responseCheckValidation.message);
          setOpenModal(true);
          return;
        }
      }

      await eventsAPI.eventsResponseForm(valuesRequest);
      setErrorVaildDate(false);
      onSuccess?.();
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';

      enqueueSnackbar('An error occurred while saving your report', {
        variant: 'error',
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        if (fullDetails?.event.ventureId) {
          const loadedFileURL = await venturesAPI.attachFileToReport(
            fullDetails.event.ventureId,
            fullDetails.event.tenantId,
            file,
          );
          return loadedFileURL;
        }
      } catch (e: any) {
        return '';
      }
    },
    [fullDetails],
  );

  const resendingForm = () => {
    if (prevValue) {
      handleSubmit(prevValue);
      setPrevValue(undefined);
    }
  };

  return (
    <>
      <WarningBeforeStart date={fullDetails.event.start} />
      <ReportLeadMentorForm
        initialValues={initialValues}
        fullDetails={fullDetails}
        onSubmit={handleSubmit}
        loading={isLoadingSaveForm}
        errorVaildDate={errorVaildDate}
        onUploadFile={handleUploadFile}
        timeZone={timeZone}
        reportBasedSchedulingEnabled={reportBasedSchedulingEnabled}
        formSettings={formSettings}
        isStickySubmit={isStickySubmit}
        emailOptions={emailOptions}
        disabledEmail={!!defaultEmail}
        hasBeforeUnload={hasBeforeUnload}
      />
      <Dialog
        open={openModal}
        setOpen={setOpenModal}
        title='Chosen date/time is not available'
        width={500}
        contentRenderer={({ handleClose }) => (
          <div>
            <Text variant='normal'>
              {modalText ||
                'The next meeting cannot be scheduled automatically for the date and time you selected as it was taken by one or more ventures.'}
            </Text>
            <br />
            <br />
            <Text variant='normal'>
              Please pick a different date or send the report without date/time.
            </Text>
            <br />
            <br />
            <FormButtons className={classes.formButtons} justify='center'>
              <Button onClick={handleClose}>
                Pick a<br />
                different time
              </Button>
              <Button
                variant='outlined'
                disabled={isLoadingSaveForm}
                data-testid='mentor-assignment-apply'
                onClick={() => resendingForm()}>
                {isLoadingSaveForm ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  <>
                    Send report without the
                    <br />
                    next meeting date/time
                  </>
                )}
              </Button>
            </FormButtons>
          </div>
        )}
      />
    </>
  );
}

export default LeadMentorReport;
