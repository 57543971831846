import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import cn from 'classnames';
import { isT } from 'fp-utilities';
import { useSnackbar } from 'notistack';
import { ChangeEvent, useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useResizeDetector } from 'react-resize-detector';
import { Column, Table } from 'react-virtualized';
import { EventAttendanceType, EventFullDetails } from '../../api/events';
import { Settings, TenantTimeZone } from '../../api/tenants/types/Settings';
import FieldDates from '../../components/common/field-dates-report-lead-mentor';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { formatDateToRFC, isValidateDate } from '../../utils/date';
import { isStringDate, toDate } from '../../utils/date/StringDate';
import { ZonedDate } from '../../utils/date/ZonedDate';
import { lengthField, SELECT_NONE } from '../../utils/form';
import {
  getTextWithoutTags,
  isMobile,
  validateEmail,
} from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { length } from '../../utils/wysiwyg';
import {
  AttachFilesButton,
  BeforeUnload,
  Button,
  FormButtons,
  FormGroup,
  StickyContent,
} from '../common';
import { Option } from '../common/Autocomplete';
import { DatesOutOfRange } from '../common/Confirmation/DatesOutOfRange';
import AttachmentCard, {
  Attachment,
  getAttachmentFromFile,
} from '../common/attachment-card';
import { TableHeadCell, TableHeadRow, TableRow } from '../common/table';
import Text from '../common/text';
import {
  FormDateTimeInput,
  FormRadioboxGroup,
  FormRating,
  FormSelect,
  TextFieldSummary,
  TextFieldWrapper,
  TextFieldWysiwyg,
} from './wrappers';

const useStyles = makeStyles((theme) => ({
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  formContainer: {
    width: '100%',
  },
  title: {
    marginTop: 20,
  },

  ventureUpdate: {
    '& .MuiInputBase-inputMultiline': {
      minHeight: 64,
    },
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up('xs')]: {
      width: '50%',
    },
  },
  progressVenture: {
    marginBottom: '10px !important',
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  fileName: {
    maxWidth: '200px !important',
  },
  tableContainer: {
    marginTop: 20,
    maxWidth: 'calc(100vw - 64px)',
  },
  table: {
    outline: 'none',
  },
  column: {
    display: 'flex',
  },
  row: {
    '&:hover $actionsCell': {
      display: 'flex',
    },
  },
  attendingColumn: {
    width: '100%',

    '& > div': {
      width: '100%',

      '& .MuiFormGroup-root': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        justifyItems: 'center',
        gap: 10,
        width: '100%',

        '& .MuiFormControlLabel-root': {
          margin: 0,
        },
      },
    },
  },
  attendingColumnHead: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    justifyItems: 'center',
    gap: 10,

    '@media (max-width: 700px)': {
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  name: {
    lineHeight: '42px',
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  nameError: {
    color: theme.palette.error.main,
  },
  actionsBlock: {
    marginTop: 56,
  },
}));

export interface Params {
  label: string;
  value: string;
}

type MeetingType = 'virtually' | 'in-person' | 'hybrid';

export interface FormValues {
  submitterEmail: string;
  summary: string;
  start: string;
  end: string;
  attendingMentors: { [key: string]: EventAttendanceType | 'ABSENT' };
  attendingFounders: { [key: string]: EventAttendanceType | 'ABSENT' };
  mentorReport: string;
  mentorReportNotes: string;
  nextEventStart: string;
  nextEventEnd: string;
  rateSession: number;
  rateVenture: number;
  attachments: string;
  mostImpactfulMentor: string;
  physicalLocation: string | undefined;
  meeting: MeetingType;
  sessionRating?: number;
  ventureProgressRating?: number;
  ventureReadinessRating?: number;
  mentorTeamImpactRating?: number;
  eventEnjoymentRating?: number;
}

export interface FormInitialValues {
  submitterEmail: string;
  summary: string;
  start: string;
  end: string;
  attendingMentors: { [key: string]: EventAttendanceType };
  attendingFounders: { [key: string]: EventAttendanceType };
  mentorReport: string;
  mentorReportNotes: string;
  nextEventStart: string;
  nextEventEnd: string;
  rateSession: number;
  rateVenture: number;
  attachments: Attachment[];
  mostImpactfulMentor: string;
  meeting: MeetingType;
  physicalLocation: string | undefined;
  sessionRating?: number;
  ventureProgressRating?: number;
  ventureReadinessRating?: number;
  mentorTeamImpactRating?: number;
  eventEnjoymentRating?: number;
}

interface Props {
  fullDetails: EventFullDetails;
  onSubmit: (values: FormValues) => void;
  onUploadFile: (file: File) => Promise<string | undefined>;
  loading: boolean;
  initialValues?: FormInitialValues;
  errorVaildDate?: boolean;
  timeZone: TenantTimeZone;
  reportBasedSchedulingEnabled: boolean;
  formSettings: Partial<Settings>;
  isStickySubmit?: boolean;
  emailOptions?: {
    label: string;
    value: string;
  }[];
  disabledEmail?: boolean;
  hasBeforeUnload?: boolean;
}

type Errors = {
  [K in keyof FormValues]?: string | { [key: string]: string };
};

const attendingOptions = [
  { value: 'ABSENT', label: '' },
  { value: EventAttendanceType.VIRTUAL, label: '' },
  { value: EventAttendanceType['IN_PERSON'], label: '' },
];

const getValidateForm =
  (fullDetails: EventFullDetails, formSettings: Partial<Settings>) =>
  (values: FormValues) => {
    const errors: Errors = {};

    if (values.nextEventStart && !values.nextEventEnd) {
      errors.nextEventEnd = 'Required';
    }

    if (!values.nextEventStart && values.nextEventEnd) {
      errors.nextEventStart = 'Required';
    }

    if (values.nextEventStart && !isValidateDate(values.nextEventStart)) {
      errors.nextEventStart = 'Invalid';
    }
    if (values.nextEventEnd && !isValidateDate(values.nextEventEnd)) {
      errors.nextEventEnd = 'Invalid';
    }

    if (!values.submitterEmail) {
      errors.submitterEmail = 'Required';
    } else if (!validateEmail(values.submitterEmail)) {
      errors.submitterEmail = 'Invalid';
    }
    if (
      !values.sessionRating &&
      formSettings?.mentorReportSessionRatingQuestionEnabled
    ) {
      errors.sessionRating = 'Required';
    }
    if (
      !values.ventureProgressRating &&
      formSettings?.mentorReportVentureProgressQuestionEnabled
    ) {
      errors.ventureProgressRating = 'Required';
    }
    if (
      !values.ventureReadinessRating &&
      formSettings?.mentorReportVentureReadinessQuestionEnabled
    ) {
      errors.ventureReadinessRating = 'Required';
    }
    if (
      !values.mentorTeamImpactRating &&
      formSettings?.mentorReportMentorTeamImpactQuestionEnabled
    ) {
      errors.mentorTeamImpactRating = 'Required';
    }
    if (
      !values.eventEnjoymentRating &&
      formSettings?.mentorReportSessionEnjoymentQuestionEnabled
    ) {
      errors.eventEnjoymentRating = 'Required';
    }
    if (!values.mentorReport || length(values.mentorReport) > 500) {
      errors.mentorReport = 'Required';
    }
    if (values.mentorReportNotes && length(values.mentorReportNotes) > 2000) {
      errors.mentorReportNotes = 'Invalid';
    }

    if (values.meeting === 'in-person' || values.meeting === 'hybrid') {
      if (!values.physicalLocation) {
        errors['physicalLocation'] = 'Required';
      } else if (values.physicalLocation.length > 2048) {
        errors['physicalLocation'] =
          'Address should be less then 2048 characters';
      }
    }

    if (
      fullDetails?.mentorList?.length &&
      (!values.attendingMentors ||
        Object.keys(values.attendingMentors).length !==
          fullDetails.mentorList.length)
    ) {
      const missedMentors = fullDetails.mentorList.filter(
        (mentor) => !values.attendingMentors[mentor.id],
      );

      missedMentors.forEach((mentor) => {
        if (!errors.attendingMentors) {
          errors.attendingMentors = {
            [mentor.id]: 'Required',
          };
        } else {
          (errors.attendingMentors as { [key: string]: string })[mentor.id] =
            'Required';
        }
      });
    }

    if (
      fullDetails?.founderList?.length &&
      (!values.attendingFounders ||
        Object.keys(values.attendingFounders).length !==
          fullDetails.founderList.length)
    ) {
      const missedFounders = fullDetails.founderList.filter(
        (founder) => !values.attendingFounders[founder.id],
      );

      missedFounders.forEach((founder) => {
        if (!errors.attendingFounders) {
          errors.attendingFounders = {
            [founder.id]: 'Required',
          };
        } else {
          (errors.attendingFounders as { [key: string]: string })[founder.id] =
            'Required';
        }
      });
    }

    return errors;
  };

const formatAttachments = (attachments: Attachment[]) => {
  try {
    if (!attachments.length) {
      return '';
    }
    const attachmentRefs = JSON.stringify(attachments);
    return attachmentRefs;
  } catch (e: any) {
    return '';
  }
};

function ReportLeadMentorForm({
  fullDetails,
  onSubmit,
  loading,
  initialValues,
  errorVaildDate,
  onUploadFile,
  timeZone,
  reportBasedSchedulingEnabled,
  formSettings,
  isStickySubmit,
  emailOptions,
  disabledEmail,
  hasBeforeUnload,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const setSubmitValidationFailed = useScrollOnValidation();
  const { width: containerWidth, ref: containerRef } = useResizeDetector();
  const isSubmitDisabled = loading;
  const [fieldsValidation, setFieldsValidation] = useState<{ date: boolean }>();
  const [attendingMentorsOptions, setAttendingMentorsOptions] = useState<
    Option<string>[]
  >([]);

  const [attachments, setAttachments] = useState<Attachment[]>(
    initialValues?.attachments || [],
  );

  const handleSubmit = (values: FormValues) => {
    const cleanText = getTextWithoutTags(values.mentorReport);
    if (cleanText.length > 1000) {
      enqueueSnackbar(
        'Progress report should not exceed 1000 characters.\nIf you would like to submit your longer notes,\nplease attach them as a file or add them manually in Traction5.',
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      return;
    }

    const attachmentRefs = formatAttachments(attachments);
    onSubmit({ ...values, attachments: attachmentRefs });
  };

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  const handleFieldsValidation = useCallback(
    (field: 'date') => (valid: boolean) => {
      setFieldsValidation(
        (prevValidations) =>
          ({
            ...(prevValidations ? prevValidations : {}),
            [field]: valid,
          } as { date: boolean }),
      );
    },
    [],
  );

  const handleFileUpload = async (file: File) => {
    try {
      const fileIndex = attachments.length;
      setAttachments((prevAttachments) => [
        ...prevAttachments,
        getAttachmentFromFile(file),
      ]);
      const fileURL = await onUploadFile(file);
      setAttachments((prevAttachments) => {
        return prevAttachments.map((prevAttach, prevAttachIndex) => {
          if (prevAttachIndex !== fileIndex) {
            return prevAttach;
          }
          return {
            ...prevAttach,
            url: fileURL,
          };
        });
      });
    } catch (e: any) {}
  };

  const handleFileRemove = (index: number) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, attachIndex) => attachIndex !== index),
    );
  };

  return (
    <DatesOutOfRange
      getDates={(v) =>
        [
          isStringDate(v.nextEventStart)
            ? new ZonedDate(toDate(v.nextEventStart), timeZone)
            : undefined,
          isStringDate(v.nextEventEnd)
            ? new ZonedDate(toDate(v.nextEventEnd), timeZone)
            : undefined,
        ].filter(isT)
      }
      onSubmit={handleSubmit}>
      {({ onSubmit }) => {
        return (
          <Form
            validate={getValidateForm(fullDetails, formSettings)}
            onSubmit={onSubmit}
            initialValues={initialValues}
            keepDirtyOnReinitialize
            render={(formProps) => {
              setSubmitValidationFailed(
                formProps.submitFailed &&
                  !formProps.dirtySinceLastSubmit &&
                  !formProps.submitting,
              );
              return (
                <div className={classes.formBlocks} ref={containerRef}>
                  <form noValidate className={classes.formContainer}>
                    {hasBeforeUnload && (
                      <BeforeUnload
                        when={formProps.dirty && !loading}
                        title='Leave the page'
                        body='You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
                        disabled={loading}
                        confirmButtonRenderer={({ onConfirm }) => (
                          <Button
                            variant='outlined'
                            onClick={async () => {
                              try {
                                await formProps.handleSubmit();
                                onConfirm();
                              } catch (e: any) {}
                            }}
                            disabled={loading || !formProps.valid}>
                            {loading ? (
                              <CircularProgress size={24} color='inherit' />
                            ) : (
                              'Save the changes'
                            )}
                          </Button>
                        )}
                      />
                    )}
                    <FormGroup>
                      <Field<string>
                        name='summary'
                        component={TextFieldSummary}
                        label='Summary'
                        disabled
                        testid='report-lead-mentor-form-summary'
                      />
                    </FormGroup>
                    <FormGroup mobile>
                      <Field<string>
                        name='start'
                        component={FormDateTimeInput}
                        label='Start'
                        parse={(value) => formatDateToRFC(value)}
                        disabled
                        testId='report-lead-mentor-form-start'
                      />
                      <Field<string>
                        name='end'
                        component={FormDateTimeInput}
                        label='End'
                        parse={(value) => formatDateToRFC(value)}
                        disabled
                        testId='report-lead-mentor-form-end'
                      />
                    </FormGroup>
                    {emailOptions ? (
                      <FormGroup>
                        <Field<string>
                          name='submitterEmail'
                          testid='report-lead-mentor-form-email'
                          component={FormSelect}
                          label='Author*'
                          options={emailOptions}
                        />
                      </FormGroup>
                    ) : (
                      <FormGroup>
                        <Field<string>
                          name='submitterEmail'
                          component={TextFieldWrapper}
                          label='Email*'
                          testid='report-lead-mentor-form-email'
                          disabled={disabledEmail}
                          InputProps={{
                            inputProps: {
                              maxLength: lengthField.email,
                            },
                          }}
                          formatOnBlur
                          format={(value: string) => {
                            return value ? value.toLowerCase() : value;
                          }}
                        />
                      </FormGroup>
                    )}
                    {!!formSettings?.mentorReportSessionRatingQuestionEnabled && (
                      <FormGroup>
                        <Field<number>
                          name='sessionRating'
                          component={FormRating}
                          label='How would you rate this session? *'
                          testid='report-lead-mentor-form-session-rating'
                        />
                      </FormGroup>
                    )}
                    {!!formSettings?.mentorReportVentureProgressQuestionEnabled && (
                      <FormGroup>
                        <Field<number>
                          name='ventureProgressRating'
                          component={FormRating}
                          label='How would you rate the progress of the venture? *'
                          className={classes.progressVenture}
                          testid='report-lead-mentor-form-venture-rating'
                        />
                      </FormGroup>
                    )}
                    {!!formSettings?.mentorReportVentureReadinessQuestionEnabled && (
                      <FormGroup>
                        <Field<number>
                          name='ventureReadinessRating'
                          component={FormRating}
                          label='How well prepared was the venture for the meeting - did all the homework, submitted agenda, etc.? *'
                          className={classes.progressVenture}
                          testid='report-lead-mentor-form-venture-readiness-rating'
                        />
                      </FormGroup>
                    )}
                    {!!formSettings?.mentorReportMentorTeamImpactQuestionEnabled && (
                      <FormGroup>
                        <Field<number>
                          name='mentorTeamImpactRating'
                          component={FormRating}
                          label='How do you rate the impact of the mentor team as a whole in this meeting? *'
                          className={classes.progressVenture}
                          testid='report-lead-mentor-form-mentor-team-impact-rating'
                        />
                      </FormGroup>
                    )}
                    {!!formSettings?.mentorReportSessionEnjoymentQuestionEnabled && (
                      <FormGroup>
                        <Field<number>
                          name='eventEnjoymentRating'
                          component={FormRating}
                          label='How enjoyable was this mentor meeting for the mentor team as a whole? *'
                          className={classes.progressVenture}
                          testid='report-lead-mentor-form-event-enjoyment-rating'
                        />
                      </FormGroup>
                    )}
                    <div className={classes.title}>
                      <Typography variant='h4'>
                        Please select all {rb('mentors-u').toUpperCase()} who
                        were present *
                      </Typography>
                    </div>
                    <FormGroup column className={classes.tableContainer}>
                      {fullDetails?.mentorList &&
                      fullDetails?.mentorList.length > 0 ? (
                        <Table
                          gridClassName={classes.table}
                          headerHeight={56}
                          height={64 * fullDetails.mentorList.length + 56}
                          rowHeight={64}
                          rowCount={fullDetails.mentorList.length}
                          rowGetter={({ index }) =>
                            fullDetails.mentorList[index]
                          }
                          rowClassName={classes.row}
                          headerRowRenderer={(headRowProps) => (
                            <TableHeadRow {...headRowProps} />
                          )}
                          rowRenderer={(rowProps) => (
                            <TableRow
                              data-testid={`mentor-list-table-row-${rowProps.index}`}
                              {...rowProps}
                            />
                          )}
                          width={containerWidth || 500}>
                          <Column
                            dataKey='name'
                            className={classes.column}
                            width={isMobile() ? 80 : 172}
                            flexGrow={1}
                            headerRenderer={() => (
                              <TableHeadCell>Name</TableHeadCell>
                            )}
                            cellRenderer={({ rowData }) => {
                              return (
                                <Text
                                  className={cn(classes.name, {
                                    [classes.nameError]:
                                      !!formProps.errors?.attendingMentors?.[
                                        rowData.id
                                      ] && formProps.submitFailed,
                                  })}>
                                  {rowData.firstName} {rowData.lastName}
                                </Text>
                              );
                            }}
                          />
                          <Column
                            dataKey='virtual'
                            className={cn(
                              classes.column,
                              classes.attendingColumn,
                            )}
                            width={isMobile() ? 140 : 210}
                            minWidth={isMobile() ? 140 : 210}
                            maxWidth={isMobile() ? 140 : 210}
                            headerRenderer={() => (
                              <TableHeadCell
                                className={classes.attendingColumnHead}>
                                <Text>Absent</Text>
                                <Text>Virtually</Text>
                                <Text>In-person</Text>
                              </TableHeadCell>
                            )}
                            cellRenderer={({ rowData }) => {
                              return (
                                <Field
                                  name={`attendingMentors[${rowData.id}]`}
                                  component={FormRadioboxGroup}
                                  options={attendingOptions}
                                  testid={`mentor-list-table-row-${rowData.id}`}
                                  onChange={(
                                    e: ChangeEvent<HTMLInputElement>,
                                  ) => {
                                    const mentorId = rowData.id as string;
                                    const mentorName = `${rowData.firstName} ${rowData.lastName}`;
                                    const isAbsent =
                                      e?.target?.value === 'ABSENT';
                                    if (isAbsent) {
                                      setAttendingMentorsOptions((prev) =>
                                        prev.filter(
                                          (option) => option.value !== mentorId,
                                        ),
                                      );

                                      if (
                                        formProps.values.mostImpactfulMentor ===
                                        mentorId
                                      ) {
                                        formProps.form.change(
                                          'mostImpactfulMentor',
                                          SELECT_NONE.value,
                                        );
                                      }
                                    } else {
                                      setAttendingMentorsOptions(
                                        (prev: Option<string>[]) => [
                                          ...prev,
                                          {
                                            value: mentorId,
                                            label: mentorName,
                                          },
                                        ],
                                      );
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                        </Table>
                      ) : (
                        <Text variant='normal'>
                          There are no invited {rb('mentors')}
                        </Text>
                      )}
                    </FormGroup>

                    <div className={classes.title}>
                      <Typography variant='h4'>
                        Please select all FOUNDERS who were present *
                      </Typography>
                    </div>
                    <FormGroup column className={classes.tableContainer}>
                      {fullDetails?.founderList &&
                      fullDetails?.founderList.length > 0 ? (
                        <Table
                          gridClassName={classes.table}
                          headerHeight={56}
                          height={64 * fullDetails.founderList.length + 56}
                          rowHeight={64}
                          rowCount={fullDetails.founderList.length}
                          rowGetter={({ index }) =>
                            fullDetails.founderList[index]
                          }
                          rowClassName={classes.row}
                          headerRowRenderer={(headRowProps) => (
                            <TableHeadRow {...headRowProps} />
                          )}
                          rowRenderer={(rowProps) => (
                            <TableRow
                              data-testid={`founder-list-table-row-${rowProps.index}`}
                              {...rowProps}
                            />
                          )}
                          width={containerWidth || 500}>
                          <Column
                            dataKey='name'
                            className={classes.column}
                            width={isMobile() ? 80 : 172}
                            flexGrow={1}
                            headerRenderer={() => (
                              <TableHeadCell>Name</TableHeadCell>
                            )}
                            cellRenderer={({ rowData }) => {
                              return (
                                <Text
                                  className={cn(classes.name, {
                                    [classes.nameError]:
                                      !!formProps.errors?.attendingFounders?.[
                                        rowData.id
                                      ] && formProps.submitFailed,
                                  })}>
                                  {rowData.firstName} {rowData.lastName}
                                </Text>
                              );
                            }}
                          />
                          <Column
                            dataKey='virtual'
                            className={cn(
                              classes.column,
                              classes.attendingColumn,
                            )}
                            width={isMobile() ? 140 : 210}
                            minWidth={isMobile() ? 140 : 210}
                            maxWidth={isMobile() ? 140 : 210}
                            headerRenderer={() => (
                              <TableHeadCell
                                className={classes.attendingColumnHead}>
                                <Text>Absent</Text>
                                <Text>Virtually</Text>
                                <Text>In-person</Text>
                              </TableHeadCell>
                            )}
                            cellRenderer={({ rowData }) => {
                              return (
                                <Field
                                  name={`attendingFounders[${rowData.id}]`}
                                  component={FormRadioboxGroup}
                                  options={attendingOptions}
                                  testid={`founder-list-table-row-${rowData.id}`}
                                />
                              );
                            }}
                          />
                        </Table>
                      ) : (
                        <Text variant='normal'>
                          There are no invited founders
                        </Text>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='mentorReport'
                        label='Venture Progress Update. Progress update should not exceed 500 characters. Use the link below to attach files*'
                        placeholder='Type your answer here...'
                        component={TextFieldWysiwyg}
                        className={classes.ventureUpdate}
                        showCount={true}
                        maxLength={500}
                        testid='report-lead-mentor-form-mentor-report'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.ventureUpdate,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='mentorReportNotes'
                        label='Notes'
                        placeholder='Type here...'
                        component={TextFieldWysiwyg}
                        className={classes.ventureUpdate}
                        showCount={true}
                        maxLength={2000}
                        testid='report-lead-mentor-form-mentor-report-notes'
                      />
                    </FormGroup>

                    {formSettings.mostImpactfulMentorEnabled && (
                      <FormGroup>
                        <Field<string>
                          name='mostImpactfulMentor'
                          component={FormSelect}
                          label={`Who was the most impactful ${rb(
                            'mentor',
                          )} during the session?`}
                          options={[SELECT_NONE, ...attendingMentorsOptions]}
                        />
                      </FormGroup>
                    )}

                    {reportBasedSchedulingEnabled && (
                      <>
                        <FieldDates
                          onValid={handleFieldsValidation('date')}
                          errorVaildDate={errorVaildDate}
                        />
                        <br />
                        <Typography variant='h4'>
                          How would you like to meet?
                        </Typography>
                        <Field<string>
                          name='meeting'
                          component={FormRadioboxGroup}
                          options={[
                            { value: 'virtually', label: 'Virtually' },
                            {
                              value: 'in-person',
                              label: 'In-person',
                            },
                            {
                              value: 'hybrid',
                              label: 'Hybrid',
                              info: 'This hybrid meeting will have both in-person and virtual channels',
                            },
                          ]}
                          testid='scheduling-wizard-initial-form-meeting'
                        />
                      </>
                    )}
                    {formProps.values.meeting === 'in-person' ||
                    formProps.values.meeting === 'hybrid' ? (
                      <Field<string>
                        name='physicalLocation'
                        component={TextFieldWrapper}
                        placeholder={'Default location (editable)'}
                        testid='scheduling-wizard-initial-form-physicalLocation'
                      />
                    ) : null}

                    <div className={classes.attachBtn}>
                      <AttachFilesButton onChange={handleSelectFile} />
                    </div>
                    <div className={classes.attachmentsList}>
                      {attachments.map((attachment, attachIndex) => (
                        <div key={attachIndex} className={classes.attachment}>
                          <AttachmentCard
                            attachment={attachment}
                            loading={!attachment.url}
                            onRemove={() => handleFileRemove(attachIndex)}
                            classesFileName={classes.fileName}
                          />
                        </div>
                      ))}
                    </div>
                    {isStickySubmit ? (
                      <div className={classes.actionsBlock}>
                        <StickyContent>
                          <Button
                            className={CLASS_TRACKING.INTERNAL_ACTION}
                            onClick={formProps.handleSubmit}
                            disabled={
                              isSubmitDisabled ||
                              (reportBasedSchedulingEnabled &&
                                !fieldsValidation?.date)
                            }
                            data-testid='button-submit-form'
                            startIcon={<CheckIcon />}>
                            {loading ? (
                              <CircularProgress size={24} color='inherit' />
                            ) : (
                              'Save'
                            )}
                          </Button>
                        </StickyContent>
                      </div>
                    ) : (
                      <FormButtons className={classes.formButtons}>
                        <Button
                          data-testid='button-submit-form'
                          onClick={formProps.handleSubmit}
                          disabled={
                            isSubmitDisabled ||
                            (reportBasedSchedulingEnabled &&
                              !fieldsValidation?.date)
                          }>
                          {loading ? (
                            <CircularProgress size={24} color='inherit' />
                          ) : (
                            'Save'
                          )}
                        </Button>
                      </FormButtons>
                    )}
                  </form>
                </div>
              );
            }}
          />
        );
      }}
    </DatesOutOfRange>
  );
}

export default ReportLeadMentorForm;
