import { Button, CircularProgress, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import eventsAPI, { EventFullDetails, EventId } from '../../api/events';
import { TenantTimeZone } from '../../api/tenants/types/Settings';
import { Role } from '../../api/user/Role';
import { Link, SnackMessage, Text } from '../../components/common';
import LeadMentorReport from '../../components/sessions/reports/lead-mentor-report';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { SessionContext } from '../../contexts/session-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

enum LeadMentorReportStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  SUBMITTED = 'SUBMITTED',
  NO_MENTORS = 'NO_MENTORS',
  NO_FOUNDERS = 'NO_FOUNDERS',
}

const useStyles = makeStyles((theme) => ({
  formContainer: {
    width: '560px',
    maxWidth: '100%',
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    gap: 15,
    width: 300,
    maxWidth: '100%',
    padding: '32px',
    boxSizing: 'border-box',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
  },
  successText: {
    textAlign: 'center',
  },
}));

const errorMessage =
  'Unfortunately an error occurred while loading the report. Please try again in a couple of minutes. If the problem persists please share the URL with the support team at support@tractionfive.com.';

function SessionLeadMentorPage() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, tokenData, hasRole } = useContext(UserContext);
  const { sessionId } = useParams<{ sessionId: EventId }>();
  const { sessionKey } = useContext(SessionContext);
  const [fullDetails, setFullDetails] = useState<EventFullDetails>();
  const [statusForm, setStatusForm] = useState<LeadMentorReportStatus>(
    LeadMentorReportStatus.LOADING,
  );

  const additionalDetails = useMemo(
    () => ({
      defaultLocation: user?.defaultPhysicalLocation ?? undefined,
      tenantTimezone: user?.timeZone as TenantTimeZone,
      reportBasedSchedulingEnabled: !!user?.reportBasedScheduleEnabled,
      formSettings: {
        mentorReportSessionRatingQuestionEnabled:
          !!user?.mentorReportSessionRatingQuestionEnabled,
        mentorReportVentureProgressQuestionEnabled:
          !!user?.mentorReportVentureProgressQuestionEnabled,
        mentorReportVentureReadinessQuestionEnabled:
          !!user?.mentorReportVentureReadinessQuestionEnabled,
        mentorReportMentorTeamImpactQuestionEnabled:
          !!user?.mentorReportMentorTeamImpactQuestionEnabled,
        mentorReportSessionEnjoymentQuestionEnabled:
          !!user?.mentorReportSessionEnjoymentQuestionEnabled,
        mostImpactfulMentorEnabled: !!user?.mostImpactfulMentorEnabled,
      },
    }),
    [user],
  );

  const emailOptions = useMemo(() => {
    return (hasRole(Role.Admin) || hasRole(Role.Manager)) &&
      fullDetails?.mentorList &&
      fullDetails?.mentorList?.length > 0
      ? fullDetails?.mentorList.map((mentor) => {
          return {
            value: mentor.email,
            label: [
              mentor.firstName,
              mentor.lastName,
              `(${mentor.email})`,
            ].join(' '),
          };
        })
      : undefined;
  }, [fullDetails?.mentorList, hasRole]);

  const defaultEmail = useMemo(() => {
    return hasRole(Role.Mentor) ? tokenData?.email ?? '' : '';
  }, [hasRole, tokenData?.email]);

  const onSuccess = useCallback(() => {
    enqueueSnackbar(`Lead ${rb('mentor-u')} Report successfully submitted!`, {
      variant: 'success',
    });
    history.push(
      getRoutePath(Pages.SESSIONS_DETAILS, {
        sessionId,
      }),
    );
  }, [enqueueSnackbar, rb, history, sessionId]);

  useEffect(() => {
    const loadDetails = async (key: string) => {
      try {
        setStatusForm(LeadMentorReportStatus.LOADING);
        const isSubmitted = await eventsAPI.getLeadMentorReportSubmission(
          sessionKey,
        );

        if (isSubmitted) {
          setStatusForm(LeadMentorReportStatus.SUBMITTED);
          return;
        }

        const responseDetails = await eventsAPI.getEventDetails(sessionKey);
        const noMentors = !responseDetails.mentorList?.length;
        const noFounders = !responseDetails.founderList?.length;

        if (noMentors) {
          setStatusForm(LeadMentorReportStatus.NO_MENTORS);
          return;
        } else if (noFounders) {
          setStatusForm(LeadMentorReportStatus.NO_FOUNDERS);
          return;
        }
        setFullDetails(responseDetails);
        setStatusForm(LeadMentorReportStatus.LOADED);
      } catch (e: any) {
        enqueueSnackbar(errorMessage, {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage:
                e.response?.data?.message || 'Internal server error',
            }),
          variant: 'error',
        });
      }
    };

    if (sessionKey) {
      loadDetails(sessionKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionKey]);

  if (statusForm === LeadMentorReportStatus.LOADING) {
    return (
      <div className={classes.stateContainer}>
        <CircularProgress size={54} color='primary' />
      </div>
    );
  }

  if (
    [
      LeadMentorReportStatus.SUBMITTED,
      LeadMentorReportStatus.NO_MENTORS,
      LeadMentorReportStatus.NO_FOUNDERS,
    ].includes(statusForm) ||
    !fullDetails
  ) {
    return (
      <div
        data-testid={`report-page-${statusForm}`}
        className={classes.stateContainer}>
        <div className={classes.block}>
          {statusForm === LeadMentorReportStatus.SUBMITTED ? (
            <CheckCircleIcon className={classes.successIcon} />
          ) : (
            <CancelIcon className={classes.errorIcon} />
          )}
          <div className={classes.successText}>
            <Text variant='normal'>
              {(() => {
                switch (statusForm) {
                  case LeadMentorReportStatus.SUBMITTED:
                    return `A lead ${rb(
                      'mentor',
                    )} report for this session was already submitted. You cannot re-submit the report again. If you believe this is an error, please consult with the program admin.`;
                  case LeadMentorReportStatus.NO_MENTORS:
                    return `You cannot submit a Lead ${rb(
                      'mentor-u',
                    )} Report for this session since no ${rb(
                      'mentors-u',
                    )} are assigned.`;
                  case LeadMentorReportStatus.NO_FOUNDERS:
                    return `You cannot submit a Lead ${rb(
                      'mentor-u',
                    )} Report for this session since no Founders are assigned.`;
                  default:
                    return 'Something went wrong. Please try again.';
                }
              })()}
            </Text>
          </div>

          {statusForm === LeadMentorReportStatus.SUBMITTED && (
            <Button
              variant='contained'
              color='primary'
              component={Link}
              to={{
                pathname: getRoutePath(Pages.SESSIONS_NOTES, {
                  sessionId,
                }),
                state: {
                  prevPath: `${location.pathname}${location.search}`,
                },
                search: 'anchor=lead-mentor-report-note',
              }}>
              See Lead Report
            </Button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={classes.formContainer}>
      <LeadMentorReport
        payload={fullDetails}
        formSettings={additionalDetails.formSettings}
        timeZone={additionalDetails.tenantTimezone}
        reportBasedSchedulingEnabled={
          additionalDetails.reportBasedSchedulingEnabled
        }
        defaultLocation={additionalDetails.defaultLocation}
        isStickySubmit
        onSuccess={onSuccess}
        emailOptions={emailOptions}
        defaultEmail={defaultEmail}
        hasBeforeUnload
      />
    </div>
  );
}

export default SessionLeadMentorPage;
