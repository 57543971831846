import {
  makeStyles,
  IconButton,
  Tooltip,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import AccountBox from '@material-ui/icons/AccountBox';
import { useState, useEffect, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import eventsAPI, {
  ACCEPTANCE_STATUS,
  AttendantsResponse,
  Event,
} from '../../api/events';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { getStatusVariant } from '../../utils/status';
import { Link, StatusBadge, Text } from '../common';
import NoIcon from '../common/no-icon';

interface AttendanceIconProps {
  session: Event;
}

const STooltip = withStyles(() => ({
  tooltip: {
    width: 300,
    padding: 16,
    backgroundColor: COLORS.COLOR_ADDITIONAL_WHITE,
    boxShadow:
      '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 100px',
    gap: 10,
    width: '100%',
  },
  title: {
    fontSize: 16,
    textAlign: 'left',
  },
  name: {
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
}));

const getStatusColor = (status: ACCEPTANCE_STATUS) => {
  switch (status) {
    case ACCEPTANCE_STATUS.GREEN:
      return COLORS.COLOR_GREEN_BASE;
    case ACCEPTANCE_STATUS.YELLOW:
      return COLORS.COLOR_ADDITIONAL_ORANGE;
    case ACCEPTANCE_STATUS.RED:
      return COLORS.COLOR_RED_BASE;
    case ACCEPTANCE_STATUS.GRAY:
    default:
      return COLORS.COLOR_GRAY_BASE;
  }
};

function AttendanceTooltip({ session }: AttendanceIconProps) {
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const [isLoading, setIsLoading] = useState(true);
  const [attendants, setAttendants] = useState<AttendantsResponse | null>(null);

  useEffect(() => {
    const fetchAttendants = async () => {
      try {
        if (session.acceptanceStatus === ACCEPTANCE_STATUS.GRAY) {
          setAttendants({ founders: [], mentors: [] });
          return;
        }

        setIsLoading(true);
        const attendants = await eventsAPI.getAttendants(session.id);
        setAttendants(attendants);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAttendants();
  }, [session.acceptanceStatus, session.id]);

  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress size={24} color='primary' />
        </div>
      ) : !attendants ? (
        <Text variant='normal'>
          Something went wrong... Please try again later.
        </Text>
      ) : !attendants.founders?.length && !attendants.mentors?.length ? (
        <Text variant='normal'>The session does not have any invitees.</Text>
      ) : (
        <div className={classes.listWrapper}>
          {attendants.founders?.length ? (
            <>
              <Typography
                variant='h6'
                color='textPrimary'
                className={classes.title}>
                Founders
              </Typography>
              <div className={classes.list}>
                {attendants.founders.map((founder) => (
                  <Fragment key={founder.id}>
                    <Text variant='normal' className={classes.name}>
                      {founder.founderName}
                    </Text>
                    <div>
                      <StatusBadge
                        status={founder.status}
                        variant={getStatusVariant(founder.status)}
                      />
                    </div>
                  </Fragment>
                ))}
              </div>
            </>
          ) : null}
          {attendants.mentors?.length ? (
            <>
              <Typography
                variant='h6'
                color='textPrimary'
                className={classes.title}>
                {rb('mentors-u')}
              </Typography>
              <div className={classes.list}>
                {attendants.mentors.map((mentor) => (
                  <Fragment key={mentor.id}>
                    <Text variant='normal' className={classes.name}>
                      {mentor.mentorName}
                    </Text>
                    <div>
                      <StatusBadge
                        status={mentor.status}
                        variant={getStatusVariant(mentor.status)}
                      />
                    </div>
                  </Fragment>
                ))}
              </div>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
}

function AttendanceIcon({ session }: AttendanceIconProps) {
  const location = useLocation();

  return session.status === 'PUBLISHED' ? (
    <STooltip title={<AttendanceTooltip session={session} />}>
      <IconButton
        data-testid='attendance-icon'
        component={Link}
        to={{
          pathname: getRoutePath(Pages.SESSIONS_DETAILS, {
            sessionId: session.id,
          }),
          search: 'anchor=session-attendance-list',
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        }}>
        <AccountBox
          style={{ color: getStatusColor(session.acceptanceStatus) }}
        />
      </IconButton>
    </STooltip>
  ) : (
    <NoIcon />
  );
}

export default AttendanceIcon;
