import { Button, IconButton, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import cn from 'classnames';
import { useContext } from 'react';
import { Tenant } from '../../api/auth';
import MobileMenu from '../../components/sidebar/mobile-menu';
import { DrawerContext } from '../../contexts/drawer-context';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { isMobile } from '../../utils/functions';
import { Text } from '../common';
import Help from '../header/help';
import { Logo } from '../header/logo';
import NotificationBell from '../header/notification/notification-bell';
import UserAvatar from '../header/user-avatar';
import { ModuleMenu } from './ModuleMenu';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '56px',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.2), 0px 0px 8px rgba(51, 126, 255, 0.2)',
    position: 'relative',
    zIndex: 4,
    background: '#fff',
    padding: '0 28px 0 0',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: '28px',
    },
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    marginLeft: 12,
  },
  headerLogo: {
    display: 'none',
    [theme.breakpoints.up('xs')]: {
      display: 'flex',
      alignItems: 'center',
    },
  },
  mobileMenuButton: {
    display: 'block',
    paddingLeft: 0,
    [theme.breakpoints.up('xs')]: {
      display: 'none',
    },
  },
  noPrint: {
    '@media print': {
      display: 'none',
    },
  },
  unset: {
    '& ul, ol': {
      listStyle: 'revert',
      padding: 'revert',
      maring: 'revert',
    },
  },
  contactUs: {
    width: '100%',
    marginTop: 24,
  },
}));

export interface Props {
  user: Tenant;
}

function Header({ user }: Props) {
  const classes = useStyles();
  const { createDrawer } = useContext(DrawerContext);
  const { logo, hasAccessToAction } = useContext(UserContext);
  const { rb } = useResourceBundles();

  const handleHelpOpen = () => {
    createDrawer({
      title: 'Help Articles',
      body: (
        <div className={classes.unset}>
          <Text variant='normal'>
            Please find below a list of articles that will help you understand
            how to use traction5 service.
            <br />
            <br />
            <div>
              <a href='https://capricious-mangosteen-1a4.notion.site/Traction5-Guide-c1f02d1157db49e59c0182851ac929c4'>
                Program Administrator Guide
              </a>
            </div>
            <br />
            <div>
              <a href='https://capricious-mangosteen-1a4.notion.site/Mentor-Guide-0645708edc0b4a64be8cbc49754892c0'>
                {rb('mentor-u')} Guide
              </a>
            </div>
          </Text>
          <Button
            href={'mailto: igor@fractionalCMOs.com'}
            className={classes.contactUs}
            variant='contained'
            color='primary'>
            Contact Us
          </Button>
        </div>
      ),
    });
  };

  return (
    <div className={cn(classes.container, classes.noPrint)}>
      <div className={classes.headerLogo}>
        <ModuleMenu />
        <Logo user={user} logo={logo} />
      </div>
      <div>
        <MobileMenu
          contentRenderer={({ handleOpen }) => {
            return (
              <IconButton
                onClick={handleOpen}
                className={classes.mobileMenuButton}
                data-testid='mobile-menu'>
                <MenuIcon />
              </IconButton>
            );
          }}
          user={user}
        />
      </div>

      <div className={classes.menu}>
        {hasAccessToAction('help') && (
          <IconButton onClick={handleHelpOpen} data-testid='menu-button-help'>
            <Help />
          </IconButton>
        )}
        {hasAccessToAction('notifications') && !isMobile() && (
          <NotificationBell />
        )}
        <div className={classes.avatar}>
          <UserAvatar />
        </div>
      </div>
    </div>
  );
}

export default Header;
