import { useState, useEffect, ChangeEvent } from 'react';
import { Field } from 'react-final-form';
import { MentorId } from '../../api/mentors';
import venturesAPI from '../../api/ventures';
import { FormSelect } from '../forms/wrappers';

export interface OptionProps {
  label: string;
  value: string;
}

export interface Props {
  mentorId: MentorId;
  initialOption?: OptionProps;
  className?: string;
  readOnly?: boolean;
  onChange: (value: { id: string; ventureName: string }) => void;
}

function SelectMyVenture({
  mentorId,
  className,
  readOnly,
  initialOption,
  onChange,
}: Props) {
  const [options, setOptions] = useState<OptionProps[]>(
    initialOption ? [initialOption] : [],
  );
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadMyVentures = async () => {
      try {
        const ventures = await venturesAPI.getVenturesDetailsByMentorId(
          mentorId,
        );
        const options = ventures.map((venture) => ({
          label: venture.ventureName,
          value: venture.id,
        }));
        setOptions(options);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    loadMyVentures();
  }, [mentorId]);

  return (
    <Field<string>
      testid='session-select-my-venture'
      name='ventureId'
      component={FormSelect}
      options={options}
      readOnly={isLoading || readOnly}
      className={className}
      label='Venture*'
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target?.value;
        if (!value) return;
        const targetOption = options.find((option) => option.value === value);
        if (!targetOption) return;
        onChange({
          id: value,
          ventureName: targetOption.label,
        });
      }}
    />
  );
}

export default SelectMyVenture;
