import {
  makeStyles,
  IconButton,
  Tooltip,
  CircularProgress,
  withStyles,
  Link as MaterialLink,
} from '@material-ui/core';
import AssignmentIcon from '@material-ui/icons/Assignment';
import DOMPurify from 'dompurify';
import { useState, useEffect, SyntheticEvent } from 'react';
import { Link, useLocation } from 'react-router-dom';
import eventsAPI, { Event, FounderNoteReportData } from '../../api/events';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { createMarkup } from '../../utils/functions';
import { Text } from '../common';
import NoIcon from '../common/no-icon';

interface FounderNotesIconProps {
  session: Event;
}

const STooltip = withStyles(() => ({
  tooltip: {
    width: 300,
    padding: 16,
    backgroundColor: COLORS.COLOR_ADDITIONAL_WHITE,
    boxShadow:
      '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyText: {
    color: COLORS.COLOR_TEXT_BASE,
    display: '-webkit-box',
    WebkitLineClamp: 12,
    WebkitBoxOrient: 'vertical',
    textAlign: 'left',
    wordBreak: 'break-word',
    overflow: 'hidden',

    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
}));

function FounderNotesTooltip({ session }: FounderNotesIconProps) {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [founderNotesReport, setFounderNotesReport] =
    useState<FounderNoteReportData | null>(null);

  useEffect(() => {
    const fetchFounderNotesReport = async () => {
      try {
        if (!session.founderReportId) {
          return;
        }

        setIsLoading(true);
        const lastFounderReportData = await eventsAPI.getFounderReportLast(
          session.id,
        );
        setFounderNotesReport(lastFounderReportData);
      } catch (e) {
        console.error(e);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFounderNotesReport();
  }, [session.founderReportId, session.id]);

  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        <div className={classes.loader}>
          <CircularProgress size={24} color='primary' />
        </div>
      ) : isError ? (
        <Text variant='normal'>
          Something went wrong... Please try again later.
        </Text>
      ) : !founderNotesReport ? (
        <Text variant='normal'>The report is not submitted.</Text>
      ) : (
        <Text variant='normal'>
          <div
            className={classes.bodyText}
            dangerouslySetInnerHTML={createMarkup(
              DOMPurify.sanitize(founderNotesReport.content),
            )}
          />
        </Text>
      )}
    </div>
  );
}

function FounderNotesIcon({ session }: FounderNotesIconProps) {
  const location = useLocation();

  if (!session.founderReportId) return <NoIcon />;

  return (
    <STooltip title={<FounderNotesTooltip session={session} />}>
      <MaterialLink
        data-testid='venture-table-name'
        onClick={(e: SyntheticEvent<any>) => e.stopPropagation()}
        component={Link}
        to={{
          pathname: getRoutePath(Pages.SESSIONS_NOTES, {
            sessionId: session.id,
          }),
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
          search: 'anchor=founder-notes-report-note',
        }}>
        <IconButton data-testid='founder-notes-icon'>
          <AssignmentIcon />
        </IconButton>
      </MaterialLink>
    </STooltip>
  );
}

export default FounderNotesIcon;
