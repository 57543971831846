import { Tooltip, Typography, makeStyles } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import mentorsAPI, { MentorId, MentorStatistics } from '../../api/mentors';
import { PageLoader, Text } from '../../components/common';
import { Dot } from '../../components/common/Dot';
import { InfoTooltip } from '../../components/common/InfoTooltip';
import { Inline } from '../../components/common/Inline';
import { StepProgress } from '../../components/common/StepProgress';
import StatsSessionsTable from '../../components/tables/stats-sessions-table';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { getColorByUnicode } from '../../utils/functions';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
  },
  loader: {
    width: '100%',
    minHeight: 400,
    maxHeight: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  topStatsGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: 30,

    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr min-content 1fr',
    },
  },
  mentorProgress: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 120,
    flex: 1,

    [theme.breakpoints.up('xs')]: {
      maxWidth: 136,
    },
  },
  mentorProgressWrapper: {
    flex: 1,
  },
  mentorLevelDetails: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    justifyContent: 'end',
    alignItems: 'center',
    columnGap: 8,
    '& > span': {
      fontSize: 13,
    },
  },
  mentorLevel: {
    lineHeight: '50px',
  },
  mentorProgressNumber: {
    lineHeight: '1.2rem',
  },
  attendanceBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  attendacePercentage: {
    fontSize: '48px',
    lineHeight: '50px',
    fontWeight: 700,
    fontFamily: MONTSERRAT_FONT_FAMILY,

    '& span': {
      fontSize: '24px',
      lineHeight: '24px',
    },
  },
  divider: {
    margin: '30px 0',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_20}`,
  },
  horizontalDivider: {
    width: '100%',
    height: 1,
    backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,

    [theme.breakpoints.up('sm')]: {
      width: 1,
      height: '100%',
    },
  },
  sectionTitle: {
    marginTop: 30,
    marginBottom: 16,
  },
  listWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'grid',
    gridTemplateColumns: '10px 1fr 80px',
    alignItems: 'center',
    gap: 10,
    width: '100%',
    padding: '15px 12px 15px 0',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  name: {
    maxWidth: 220,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
}));

function MentorStatsPage() {
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const { mentorId } = useParams<{ mentorId: MentorId }>();

  const [stats, setStats] = useState<MentorStatistics | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const cohortAttendance = useMemo(() => {
    return (
      stats?.cohortAttendance
        ?.filter((cohort) => cohort.totalSessions)
        ?.map((cohort) => {
          const getPercent = (amount: number, total: number) => {
            const percent = (amount / total) * 100;
            const finalPercent = isNaN(percent) ? 0 : percent;

            return Math.round(finalPercent);
          };

          return {
            ...cohort,
            color: getColorByUnicode(cohort.cohortName),
            percent: getPercent(cohort.sessionsAttended, cohort.totalSessions),
          };
        }) || []
    );
  }, [stats]);

  const { attendancePercentage, attendanceColor } = useMemo(() => {
    const prePercentage = stats
      ? Math.round(
          (stats.numberOfSessionsAttended / stats.numberOfSessionsInPeriod) *
            100,
        )
      : 0;
    const attendancePercentage = isNaN(prePercentage) ? 0 : prePercentage;
    let attendanceColor = COLORS.COLOR_RED_BASE;

    switch (true) {
      case attendancePercentage >= 90:
        attendanceColor = COLORS.COLOR_GREEN_BASE;
        break;
      case attendancePercentage >= 75:
        attendanceColor = COLORS.COLOR_ADDITIONAL_ORANGE;
        break;
      default:
        attendanceColor = COLORS.COLOR_RED_BASE;
        break;
    }

    return { attendancePercentage, attendanceColor };
  }, [stats]);

  const loadStats = async (mentorId: MentorId) => {
    try {
      setIsLoading(true);
      const statistics = await mentorsAPI.getMentorStatistics(mentorId);

      setStats(statistics);
      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadStats(mentorId);
  }, [mentorId]);

  if (isLoading || !stats) {
    return (
      <div className={classes.loader}>
        <PageLoader />
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div className={classes.topStatsGrid}>
        <Inline align={'center'} justify={'space-between'} gap={28}>
          <div className={classes.attendanceBlock}>
            <Typography
              variant='subtitle1'
              style={{
                color: attendanceColor,
              }}>
              Attendance
            </Typography>
            <Typography
              className={classes.attendacePercentage}
              style={{
                color: attendanceColor,
              }}>
              {attendancePercentage}
              <span>%</span>
            </Typography>
          </div>
          <div className={classes.mentorLevelDetails}>
            <Typography variant={'caption'} align={'right'}>
              Session Opportunities:
            </Typography>
            <Typography variant={'caption'}>
              {stats.numberOfSessionsInPeriod}
            </Typography>
            <Typography variant={'caption'} align='right'>
              Session Attended:
            </Typography>
            <Typography variant={'caption'}>
              {stats.numberOfSessionsAttended}
            </Typography>
          </div>
        </Inline>
        <div className={classes.horizontalDivider} />
        <Inline align={'center'} justify={'space-between'} gap={28}>
          <div className={classes.mentorProgress}>
            <Inline gap={5} align={'center'}>
              <Typography variant='subtitle1'>
                T5 {rb('mentor-u')} level
              </Typography>
              <InfoTooltip
                enterTouchDelay={0}
                leaveTouchDelay={20000}
                placement={'top'}
                message={`You get Traction5 ${rb(
                  'mentor',
                )} experience points when the lead ${rb(
                  'mentor',
                )} marks your session attendance (20 pts), when you submit your lead ${rb(
                  'mentor',
                )} report (10 pts), and when you submit your ${rb(
                  'mentor',
                )} assessment (5 pts). Each level stands for 50 points`}
              />
            </Inline>
            <Typography
              variant='h1'
              align={'center'}
              className={classes.mentorLevel}>
              {stats.mentorLevel}
            </Typography>
            <Tooltip
              title={`xp ${stats.mentorXp}`}
              placement={'bottom'}
              enterTouchDelay={0}>
              <div className={classes.mentorProgressWrapper}>
                <Inline gap={5}>
                  <Typography
                    variant='subtitle1'
                    className={classes.mentorProgressNumber}>
                    {stats.mentorLevel}
                  </Typography>
                  <StepProgress
                    steps={10}
                    value={Math.trunc(stats.mentorScore / 5)}
                  />
                  <Typography
                    variant='subtitle1'
                    className={classes.mentorProgressNumber}>
                    {stats.mentorLevel + 1}
                  </Typography>
                </Inline>
              </div>
            </Tooltip>
          </div>
          <div className={classes.mentorLevelDetails}>
            <Typography variant={'caption'} align='right'>
              Lead {rb('mentor-u')} Reports:
            </Typography>
            <Typography variant={'caption'}>
              {stats.leadMentorReportsSubmitted}
            </Typography>
            <Typography variant={'caption'} align='right'>
              {rb('mentor-u')} Assessments:
            </Typography>
            <Typography variant={'caption'}>
              {stats.mentorAssessmentsSubmitted}
            </Typography>
          </div>
        </Inline>
      </div>
      {cohortAttendance.length > 0 || stats.invitedEvents.length > 0 ? (
        <div className={classes.divider} />
      ) : null}
      {cohortAttendance.length > 0 && (
        <>
          <Typography className={classes.sectionTitle} variant='h3'>
            Attendance by Group
          </Typography>
          <div className={classes.listWrapper}>
            <div className={classes.row}>
              <div />
              <Text variant='normal2' bold>
                Group
              </Text>
              <Text variant='normal2' bold>
                Attendance
              </Text>
            </div>
            {cohortAttendance.map((cohort) => {
              return (
                <div key={cohort.cohortId} className={classes.row}>
                  <Dot color={cohort.color} />
                  <Text variant='normal' className={classes.name}>
                    {cohort.cohortName}
                  </Text>
                  <Text variant='normal'>{cohort.percent}%</Text>
                </div>
              );
            })}
          </div>
        </>
      )}
      {stats.invitedEvents.length > 0 ? (
        <>
          <Typography className={classes.sectionTitle} variant='h3'>
            Attendance by Session
          </Typography>
          <StatsSessionsTable
            invitedEvents={stats.invitedEvents}
            attendedEvents={stats.attendedEvents}
          />
        </>
      ) : null}
    </div>
  );
}

export default MentorStatsPage;
