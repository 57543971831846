import { makeStyles } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import NoteIcon from '@material-ui/icons/Note';
import NotesIcon from '@material-ui/icons/Notes';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { FC } from 'react';
import { NotificationType } from '../../../api/notifications';
import { COLORS } from '../../../theme/variables';

interface NotificationIconProps {
  type: NotificationType;
}

const useStyles = makeStyles((theme) => ({
  greenIcon: {
    color: COLORS.COLOR_GREEN_BASE,
  },
}));

const NotificationIcon: FC<NotificationIconProps> = ({ type }) => {
  const classes = useStyles();

  switch (type) {
    case NotificationType.NEW_SESSION_NOTE:
      return <NoteIcon className={classes.greenIcon} />;
    case NotificationType.NEW_SESSION_CREATED:
      return <EventAvailableIcon className={classes.greenIcon} />;
    case NotificationType.NEW_WIZARD_SCHEDULED:
      return <NotesIcon color='primary' />;
    case NotificationType.MENTOR_REMOVAL:
      return <PersonIcon color='error' />;
    case NotificationType.NEW_VENTURE_APPLICATION:
      return <BusinessIcon color='primary' />;
    case NotificationType.NEW_MENTOR_APPLICATION:
      return <PersonAddIcon color='primary' />;
    case NotificationType.NEW_VENTURE_NOTE:
      return <NoteIcon className={classes.greenIcon} />;
    case NotificationType.SESSION_AGENDA_UPDATE:
      return <EventIcon color='primary' />;
    case NotificationType.MENTOR_ASSIGNMENT:
      return <PersonIcon color='primary' />;
    case NotificationType.SESSION_UPDATE:
      return <EventIcon color='primary' />;
    case NotificationType.SESSION_CANCELLATION:
      return <EventIcon color='error' />;
    case NotificationType.GATHERING_INVITE_FAILURE:
      return <EventIcon color='error' />;
    default:
      return <NewReleasesIcon color='primary' />;
  }
};

export default NotificationIcon;
