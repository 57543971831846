import {
  Fade,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useMemo, useState } from 'react';
import { CLASS_TRACKING } from '../../utils/tracking_class';

interface TableAction {
  key: string;
  label: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  onClick: () => void;
}

interface TableActionsProps {
  actions: TableAction[];
}

const useStyles = makeStyles(() => ({
  menuItem: {
    gap: 8,
  },
}));

function TableActions({ actions }: TableActionsProps) {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const activeActions = useMemo(
    () => actions.filter((a) => !a.hidden),
    [actions],
  );

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return activeActions?.length ? (
    <>
      <IconButton
        data-testid='table-action-button'
        aria-describedby={id}
        className={CLASS_TRACKING.LIST_QUICK_ACTION}
        onClick={handleOpen}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        keepMounted
        TransitionComponent={Fade}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        {activeActions.map((action) => (
          <MenuItem
            disabled={action.disabled}
            onClick={() => {
              action.onClick();
              handleClose();
            }}
            className={classes.menuItem}
            key={action.key}>
            {action.icon}
            <span>{action.label}</span>
          </MenuItem>
        ))}
      </Menu>
    </>
  ) : null;
}

export default TableActions;
