import { AxiosError } from 'axios';
import { StringDate } from '../utils/date/StringDate';
import { Founder, FounderId } from './founders';
import { axiosRequest } from './instance';
import { Mentor, MentorId } from './mentors';
import { TenantId } from './tenants/Tenant';
import { VentureId } from './ventures/types/Venture';

// region EventId
declare const _eventId: unique symbol;

export type EventId = string & { [_eventId]: 'EventId' };
// endregion

// region EventChannelId
declare const _eventChannelId: unique symbol;

export type EventChannelId = string & { [_eventChannelId]: 'EventChannelId' };
// endregion

export interface EventLog {
  id: string;
  founderId: FounderId;
  creationDate: string;
  contents: string;
}

export interface EventNote {
  contents: string;
  creationDate: StringDate;
  eventId: string;
  id: string;
  isPrimaryMentor: boolean;
  attachmentRefs: string | null;
}

export interface EventNewNote {
  contents: string;
  eventId: string;
  attachmentRefs?: string | null;
  creationDate?: StringDate;
}

export interface LeadMentorReportData {
  id: string;
  creationDate: StringDate;
  tenantId: TenantId;
  eventId: EventId;
  contents: string;
  ventureId: VentureId;
  submittedBy: string;
  valid: boolean;
}

export interface FounderNoteReportData {
  id: string;
  creationDate: StringDate;
  tenantId: TenantId;
  eventId: EventId;
  content: string;
  founderId: FounderId;
  valid: boolean;
}

export type EventStatus =
  | 'ARCHIVED'
  | 'COMPLETED'
  | 'PUBLISHED'
  | 'CREATED'
  | 'REQUIRE_ATTENTION';

export enum ACCEPTANCE_STATUS {
  RED = 'RED',
  GREEN = 'GREEN',
  YELLOW = 'YELLOW',
  GRAY = 'GRAY',
}

export type EventStatusOrAll = EventStatus | 'ALL';

export interface Event {
  acceptanceStatus: ACCEPTANCE_STATUS;
  agendaReceived: boolean;
  description: string;
  end: string;
  eventNotes?: EventNote[];
  followupActions: string;
  followupReminder: string;
  id: EventId;
  start: string;
  status: EventStatus;
  summary: string;
  tenantEventChannelId: EventChannelId | null;
  physicalLocation: string | null;
  tenantId: TenantId;
  valid: boolean;
  ventureId: VentureId;
  ventureName: string;
  emailList: string;
  requiresAttention: boolean;
  founderReportReceived: boolean;
  founderReportId: string | null;
  leadMentorReportId: string | null;
  invitedFounders: FounderId[] | null;
  invitedMentors: MentorId[] | null;
}

export interface EventFounder {
  id: string;
  eventId: EventId;
  founderId: FounderId;
  status: string;
  valid: boolean;
  attended: boolean;
}

export interface EventMentor {
  id: string;
  eventId: EventId;
  mentorId: MentorId;
  status: string;
  valid: boolean;
  attended: boolean;
}

export interface EventFullDetails {
  event: Event;
  founderList: Founder[];
  mentorList: Mentor[];
  eventFounderList: EventFounder[];
  eventMentorList: EventMentor[];
}

export interface EventDetails {
  description?: string | null;
  end: string;
  eventNotes?: Array<EventNote | EventNewNote>;
  followupReminder?: string | null;
  id?: string;
  start: string;
  status: string;
  summary: string;
  tenantEventChannelId: EventChannelId | null | undefined;
  physicalLocation: string | null | undefined;
  ventureId: VentureId;
  ventureName?: string;
  emailList?: string | null;
}

interface EventResponse {
  eventChannelId: EventChannelId;
  eventId: EventId;
  event: Event;
  scheduled: boolean;
  suggestedEventDateTime: string;
}

interface EventFilter {
  ventureId?: string;
  statusId?: string;
  startDate?: string;
  endDate?: string;
}

interface EventFilterV2 extends EventFilter {
  cohortId?: string;
  sortOrder?: string;
}

export enum EventAttendanceType {
  VIRTUAL = 'VIRTUAL',
  'IN_PERSON' = 'IN_PERSON',
}

export interface EventResponseForm {
  tenantId: TenantId;
  eventId: EventId;
  ventureId: string;
  sessionRating: number;
  ventureProgressRating: number;
  attendingMentors: {
    id: string;
    type: EventAttendanceType;
  }[];
  attendingFounders: {
    id: string;
    type: EventAttendanceType;
  }[];
  mostImpactfulMentor: string;
  submitterEmail: string;
  mentorReport: string | null;
  mentorReportAttachments: string;
  mentorReportNotes: string | null;
  founderNotes: string | null;
  founderOtherNotes: string;
  founderNotesAttachments: string;
  agendaQuestion1: string;
  agendaQuestion2: string;
  agendaQuestion3: string;
  agendaQuestion4: string;
  agendaAttachments: string;
  isLeadMentorReport: boolean;
  nextEventStart: string;
  nextEventEnd: string;
  isMentorReport: boolean;
  isFounderReport: boolean;
  founderReport: boolean;
  agendaPresented: boolean;
  virtualChannelRequested?: boolean;
  physicalLocationRequested?: string;
  ventureReadinessRating?: number;
  mentorTeamImpactRating?: number;
  eventEnjoymentRating?: number;
}

export interface Tags {
  id: string;
  name: string;
  code: string;
  type: string;
  description: string;
}

export interface EventTag {
  id: string;
  eventId: string;
  creationDate: string;
  tagId: string;
  valid: boolean;
}

export interface EventNewTag {
  eventId: string;
  creationDate: string;
  tagId: string;
}

export interface EventSchedulingRequest {
  email: string;
  event: Event;
}

export interface EventSchedulingResponse {
  eventId: string;
  suggestedEventDateTime: string;
  eventChannelId: EventChannelId;
  message: string | null;
  event: Event;
  scheduled: boolean;
}

export interface MentorAttendantResponse {
  id: string;
  eventId: string;
  mentorId: string;
  status: string;
  mentorName: string;
  valid: boolean;
}

export interface FounderAttendantResponse {
  id: string;
  eventId: string;
  founderId: string;
  status: string;
  attended: boolean;
  founderName: string;
  valid: boolean;
}

export interface AttendantsResponse {
  mentors: MentorAttendantResponse[];
  founders: FounderAttendantResponse[];
}

export type EventVentures = VentureId | 'ALL';

const eventsAPI = {
  async search(searchString: string) {
    const res = await axiosRequest.get<Event[]>({
      url: `/events/search/${searchString}`,
      credentials: true,
    });
    return res.data;
  },
  async searchByFilter({
    ventureId,
    statusId,
    startDate,
    endDate,
  }: EventFilter = {}) {
    const res = await axiosRequest.get<Event[]>({
      url: `/events/filters/`,
      data: { ventureId, statusId, startDate, endDate },
      credentials: true,
    });
    return res.data;
  },
  async searchByFilterV2({
    ventureId,
    statusId,
    startDate,
    endDate,
    cohortId,
    sortOrder,
  }: EventFilterV2 = {}) {
    const res = await axiosRequest.get<Event[]>({
      url: `/events/filters/v2/`,
      data: { ventureId, statusId, startDate, endDate, cohortId, sortOrder },
      credentials: true,
    });
    return res.data;
  },
  async getSessionsByStatus(status: string) {
    const res = await axiosRequest.get<Event[]>({
      url: `/events/status/${status}`,
      credentials: true,
    });
    return res.data;
  },
  async getEvent(eventId: Event['id']) {
    const res = await axiosRequest.get<Event>({
      url: `/events/${eventId}`,
      credentials: true,
    });
    return res.data;
  },
  async getFullEvent(eventId: Event['id']) {
    const res = await axiosRequest.get<EventFullDetails>({
      url: `/events/${eventId}/fulldetails`,
      credentials: true,
    });
    return res.data;
  },
  async futureEvents(page = 0) {
    try {
      const res = await axiosRequest.get<Event[]>({
        url: `/events/future/pages/${page}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      return [];
    }
  },
  async create(eventDetails: EventDetails) {
    try {
      const res = await axiosRequest.post<EventResponse>({
        url: '/events',
        data: eventDetails,
        credentials: true,
      });

      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  async update(eventId: Event['id'], eventDetails: EventDetails) {
    const res = await axiosRequest.put<EventResponse>({
      url: '/events',
      data: { ...eventDetails, id: eventId },
      credentials: true,
    });
    return res.data;
  },
  async upcomingEvents() {
    return this.futureEvents(0);
  },
  async getEvents(page = 0) {
    const res = await axiosRequest.get<Event[]>({
      url: `/events/pages/${page}`,
      credentials: true,
    });
    return res.data;
  },
  async getEventsDetailsByVentureId(ventureId: string, pageId: number = 0) {
    try {
      const res = await axiosRequest.get<Event[]>({
        url: `/events/ventures/${ventureId}/pages/${pageId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getEventsDetailsByDataRange(
    startDate: Date | string,
    endDate: Date | string,
    pageId: number = 0,
  ) {
    try {
      const res = await axiosRequest.get<Event[]>({
        url: `/events/dates/start/${startDate}/end/${endDate}/pages/${pageId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async attachFileToNote(eventId: Event['id'], file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/files/events/${eventId}/eventnotes`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
  async attachFileToAgendaNote(eventId: Event['id'], file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/files/events/${eventId}/eventagendas`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
  async addFounder(eventId: Event['id'], founderId: string) {
    const res = await axiosRequest.post<string>({
      url: `/events/${eventId}/founders/${founderId}`,
      credentials: true,
    });
    return res.data;
  },
  async addFounderAndNotify(eventId: Event['id'], founderId: string) {
    const res = await axiosRequest.post<string>({
      url: `/events/${eventId}/founders/${founderId}/invites`,
      credentials: true,
    });
    return res.data;
  },
  async removeFounder(eventId: Event['id'], founderId: string) {
    const res = await axiosRequest.delete<string>({
      url: `/events/${eventId}/founders/${founderId}`,
      credentials: true,
    });
    return res.data;
  },
  async removeFounderAndNotify(eventId: Event['id'], founderId: string) {
    const res = await axiosRequest.delete<string>({
      url: `/events/${eventId}/founders/${founderId}/invites`,
      credentials: true,
    });
    return res.data;
  },
  async addMentor(eventId: Event['id'], mentorId: string) {
    const res = await axiosRequest.post<string>({
      url: `/events/${eventId}/mentors/${mentorId}`,
      credentials: true,
    });
    return res.data;
  },
  async addMentorAndNotify(eventId: Event['id'], mentorId: string) {
    const res = await axiosRequest.post<string>({
      url: `/events/${eventId}/mentors/${mentorId}/invites`,
      credentials: true,
    });
    return res.data;
  },
  async removeMentor(eventId: Event['id'], mentorId: string) {
    const res = await axiosRequest.delete<string>({
      url: `/events/${eventId}/mentors/${mentorId}`,
      credentials: true,
    });
    return res.data;
  },
  async removeMentorAndNotify(eventId: Event['id'], mentorId: string) {
    const res = await axiosRequest.delete<string>({
      url: `/events/${eventId}/mentors/${mentorId}/invites`,
      credentials: true,
    });
    return res.data;
  },
  async getAssignedFounders(eventId: Event['id']) {
    try {
      const res = await axiosRequest.get<Founder[]>({
        url: `/events/${eventId}/founders/details`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getAssignedMentors(eventId: Event['id']) {
    try {
      const res = await axiosRequest.get<Mentor[]>({
        url: `/events/${eventId}/mentors/details`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async sendInvites(eventId: Event['id']) {
    const res = await axiosRequest.post<EventFullDetails>({
      url: `/events/invites/${eventId}`,
      credentials: true,
    });
    return res.data;
  },
  async eventsValidations(event: Event) {
    try {
      const res = await axiosRequest.post<EventResponse>({
        url: '/events/validations',
        data: event,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async eventsResponseForm(value: EventResponseForm) {
    try {
      const res = await axiosRequest.post<EventResponse>({
        url: '/events/responseform',
        data: value,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getEventDetails(key: string) {
    try {
      const res = await axiosRequest.get<EventFullDetails>({
        url: `/events/keys/${key}`,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async cancelEvent(eventId: string) {
    try {
      const res = await axiosRequest.delete<EventFullDetails>({
        url: `/events/invites/${eventId}`,
        credentials: true,
      });
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  async deleteEvent(eventId: string) {
    try {
      const res = await axiosRequest.delete<EventFullDetails>({
        url: `/events/${eventId}`,
        credentials: true,
      });
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  async getNotes(eventId: string) {
    try {
      const res = await axiosRequest.get<EventNote[]>({
        url: `/events/${eventId}/notes`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getLogs(eventId: string) {
    try {
      const res = await axiosRequest.get<EventLog[]>({
        url: `/events/${eventId}/log`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createNote(note: EventNewNote) {
    try {
      const res = await axiosRequest.post<EventNote>({
        url: '/events/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeNote(eventId: string, noteId: string) {
    try {
      const res = await axiosRequest.delete<EventNote>({
        url: `/events/${eventId}/notes/${noteId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updateNote(note: EventNote) {
    try {
      const res = await axiosRequest.put<EventNote>({
        url: '/events/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async loadListTags() {
    try {
      const res = await axiosRequest.get<Tags[]>({
        url: '/tags/sessions',
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getTags(eventId: string) {
    try {
      const res = await axiosRequest.get<EventTag[]>({
        url: `/events/${eventId}/tags`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async setTags(data: EventNewTag) {
    try {
      const res = await axiosRequest.post<EventTag>({
        url: '/events/tags',
        data,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async removeTags(eventId: string, eventTagId: string) {
    try {
      const res = await axiosRequest.delete<EventTag>({
        url: `/events/${eventId}/tags/${eventTagId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getEventsRequireAttention() {
    const res = await axiosRequest.get<Event[]>({
      url: '/events/requireattention',
      credentials: true,
    });
    return res.data;
  },
  async postArchivingSessions(sessionIds: string[]) {
    try {
      const res = await axiosRequest.post<EventResponse>({
        url: '/events/archives',
        data: sessionIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async postSessionResolutions(sessionIds: string[]) {
    try {
      const res = await axiosRequest.post<EventResponse>({
        url: '/events/resolutions',
        data: sessionIds,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getSessionKey(sessionId: string) {
    const res = await axiosRequest.get<string>({
      url: `/events/${sessionId}/keys`,
      credentials: true,
    });
    return res.data;
  },
  async eventsValidationAnonymous(data: EventSchedulingRequest) {
    try {
      const res = await axiosRequest.post<EventSchedulingResponse>({
        url: '/events/validations/anonymous',
        data,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getMentorUpcomingEvents() {
    try {
      const res = await axiosRequest.get<Event[]>({
        url: '/events/mentors/current',
        credentials: true,
      });
      return res.data;
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        return [];
      }

      throw e;
    }
  },
  async uploadCalendarIcs(eventId: Event['id'] | string) {
    try {
      const res = await axiosRequest.get<string>({
        url: `/events/${eventId}/ics`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getAttendants(eventId: Event['id'] | string) {
    try {
      const res = await axiosRequest.get<AttendantsResponse>({
        url: `/events/${eventId}/attendants`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async confirmAttendance(eventId: Event['id'] | string) {
    try {
      const res = await axiosRequest.post<string>({
        url: `/events/${eventId}/confirmed-attendance`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async rejectAttendance(eventId: Event['id'] | string) {
    try {
      const res = await axiosRequest.post<string>({
        url: `/events/${eventId}/rejected-attendance`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getLeadMentorReportSubmission(key: string) {
    try {
      const res = await axiosRequest.get<string>({
        url: `/public/lead-mentor-report-submissions/keys/${key}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return null;
      }
      throw e;
    }
  },

  async getLeadMentorReportLast(eventId: Event['id'] | string) {
    try {
      const res = await axiosRequest.get<LeadMentorReportData>({
        url: `/events/${eventId}/leadmentorreport/last`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return null;
      }
      throw e;
    }
  },

  async getFounderReportLast(eventId: Event['id'] | string) {
    try {
      const res = await axiosRequest.get<FounderNoteReportData>({
        url: `/events/${eventId}/founderreport/last`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return null;
      }
      throw e;
    }
  },
};

export default eventsAPI;
