import { makeStyles } from '@material-ui/core';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles({
  container: {
    padding: 16,
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    background: COLORS.COLOR_GRAY_BASE,
  },
});

function NoIcon() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.dot} />
    </div>
  );
}

export default NoIcon;
