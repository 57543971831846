import { CircularProgress, IconButton, makeStyles } from '@material-ui/core';
import { Button as MaterialButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { usePersonalNotes } from '../../contexts/personal-notes-context';
import { COLORS } from '../../theme/variables';
import { isMobile } from '../../utils/functions';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { ConfirmButton } from '../common';

const useStyles = makeStyles((theme) => ({
  noteBottomSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 20,
    gap: 20,
    borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    [theme.breakpoints.down('xs')]: {
      padding: 15,
    },
  },
  inputWrapper: {
    position: 'relative',
    zIndex: 2,
    flexGrow: 1,
    overflow: 'hidden',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    marginTop: 0,

    [theme.breakpoints.down('xs')]: {
      gap: 10,
    },
  },
  additionalActions: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,

    [theme.breakpoints.down('xs')]: {
      gap: 0,
    },
  },
  attachmentsBtn: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
}));

function NoteEditorActions() {
  const classes = useStyles();
  const {
    selectedNote,
    attachments,
    comments,
    saving,
    deleting,
    loadingComments,
    toggleAttachments,
    toggleComments,
    handleSaveNote,
    handleNoteRemove,
    handleCardClose,
  } = usePersonalNotes();

  return (
    <div className={classes.noteBottomSection}>
      <div className={classes.actions}>
        {isMobile() && (
          <IconButton
            data-testid='button-cancel-personal-note'
            onClick={handleCardClose}
            disabled={saving || deleting}
            color='primary'>
            <ArrowBackIcon />
          </IconButton>
        )}
        {isMobile() ? (
          <IconButton
            data-testid='button-save-personal-note'
            onClick={handleSaveNote}
            disabled={saving || deleting}
            color='primary'>
            <SaveIcon />
          </IconButton>
        ) : (
          <MaterialButton
            className={CLASS_TRACKING.ENTITY_ACTION}
            data-testid='button-save-personal-note'
            variant='contained'
            onClick={handleSaveNote}
            disabled={saving || deleting}
            color='primary'>
            Save
          </MaterialButton>
        )}
        <ConfirmButton
          loading={deleting}
          title='Remove the note?'
          body='Sure you want to remove the note? Changes can’t be undone'
          successProps={{
            btnLabel: 'Remove',
            onSuccess: handleNoteRemove,
          }}
          buttonRenderer={({ onClick }) =>
            isMobile() ? (
              <IconButton
                onClick={onClick}
                data-testid='button-remove-personal-note'
                disabled={saving || deleting}
                color='primary'>
                <DeleteIcon />
              </IconButton>
            ) : (
              <MaterialButton
                onClick={onClick}
                className={CLASS_TRACKING.ENTITY_ACTION}
                data-testid='button-remove-personal-note'
                variant='outlined'
                disabled={saving || deleting}
                color='primary'>
                Remove
              </MaterialButton>
            )
          }
        />
      </div>
      {selectedNote && (
        <div className={classes.additionalActions}>
          <MaterialButton
            className={classes.attachmentsBtn}
            component='label'
            color='primary'
            onClick={toggleAttachments}
            startIcon={<AttachFileIcon fontSize='inherit' />}>
            ({attachments?.length || 0})
          </MaterialButton>
          <MaterialButton
            className={classes.attachmentsBtn}
            component='label'
            color='primary'
            onClick={toggleComments}
            disabled={loadingComments}
            startIcon={<ChatBubbleOutlineIcon fontSize='inherit' />}>
            {loadingComments ? (
              <CircularProgress size={20} color='inherit' />
            ) : (
              `(${comments?.length || 0})`
            )}
          </MaterialButton>
        </div>
      )}
    </div>
  );
}

export default NoteEditorActions;
