import { makeStyles } from '@material-ui/core';
import { useMemo, useState } from 'react';
import {
  DateRangePicker,
  DateRange,
  Range,
  StaticRange,
} from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { formatDateRange } from '../../utils/date';
import { isMobile, isTablet } from '../../utils/functions';
import { Button, Modal, TextField } from '../common';

interface DateInputProps {
  onChange: (e: Range | null) => void;
  value?: Range;
  staticRanges?: StaticRange[];
}

const useStyles = makeStyles({
  container: {
    width: 200,
  },
  dateRangePickerWrapper: {
    '& .rdrDateRangePickerWrapper': {
      height: 418,
    },
  },
});

const getStartDate = () => {
  const startDate = new Date();
  startDate.setDate(startDate.getDate() - 30);
  return startDate;
};

export function DateRangePickerInput({
  onChange,
  value,
  staticRanges,
}: DateInputProps) {
  const classes = useStyles();
  const [currentValue, setCurrentValue] = useState<Range>(
    value || {
      startDate: getStartDate(),
      endDate: new Date(),
      key: 'selection',
    },
  );

  const textInputValue = useMemo(() => {
    return value?.startDate && value?.endDate
      ? formatDateRange(value.startDate, value.endDate)
      : '';
  }, [value]);

  return (
    <div className={classes.container}>
      <Modal
        title={'Date range'}
        noPadding
        margin='0'
        contentRenderer={({ handleClose }) => {
          return (
            <div className={classes.dateRangePickerWrapper}>
              {isMobile() ? (
                <DateRange
                  onChange={(dateRange) => {
                    setCurrentValue(dateRange.selection);
                  }}
                  moveRangeOnFirstSelection={false}
                  ranges={[{ ...currentValue, key: 'selection' }]}
                />
              ) : (
                <DateRangePicker
                  onChange={(dateRange) => {
                    setCurrentValue(dateRange.selection);
                  }}
                  moveRangeOnFirstSelection={false}
                  months={isTablet() ? 1 : 2}
                  ranges={[{ ...currentValue, key: 'selection' }]}
                  direction='horizontal'
                  staticRanges={staticRanges}
                />
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  gap: '8px',
                  padding: '16px',
                }}>
                <Button variant={'outlined'} onClick={() => handleClose()}>
                  Cancel
                </Button>
                <Button
                  variant={'contained'}
                  onClick={() => {
                    onChange(currentValue);
                    handleClose();
                  }}>
                  Accept
                </Button>
              </div>
            </div>
          );
        }}
        buttonRenderer={({ onClick }) => (
          <TextField
            className={classes.container}
            value={textInputValue}
            label='Date range'
            onChange={() => {
              onChange(null);
            }}
            onClick={onClick}
            small
          />
        )}
      />
    </div>
  );
}

export default DateRangePickerInput;
