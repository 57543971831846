import { IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import { useCallback, useRef } from 'react';
import eventsAPI, { EventId } from '../../../api/events';
import filesAPI from '../../../api/files';
import foundersAPI from '../../../api/founders';
import mentorsAPI from '../../../api/mentors';
import venturesAPI from '../../../api/ventures';
import { AttachFilesButton, Text } from '../../../components/common';
import AttachmentCard, {
  getAttachmentFromFile,
} from '../../../components/common/attachment-card';
import { usePersonalNotes } from '../../../contexts/personal-notes-context';
import { COLORS } from '../../../theme/variables';

const useStyles = makeStyles((theme) => ({
  attachmentsBlock: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    minWidth: 340,
    backgroundColor: '#fff',
    transform: 'translateX(100%)',
    zIndex: 2,
    transition: 'all 0.3s ease-in-out',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  attachmentsBlockOpen: {
    transform: 'translateX(0)',
    boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
  },
  attachmentsActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 20,
    padding: '.5px 10px',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  attachmentsBtn: {
    '& .MuiButton-startIcon': {
      marginRight: 0,
    },
  },
  closeAttachmentsBtn: {
    padding: 4,
  },
  attachmentsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: '100%',
    height: '100%',
    padding: 20,
    overflow: 'auto',
    boxSizing: 'border-box',
  },
  attachment: {
    width: '100%',
    maxWidth: 397,
    boxSizing: 'border-box',
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  emptyMessage: {
    padding: 20,
    textAlign: 'center',
  },
}));

function PersonalNotesAttachments() {
  const classes = useStyles();
  const {
    selectedNote,
    attachments,
    showAttachments,
    setSelectedNote,
    setAttachments,
    toggleAttachments,
    setSaving,
    updateNote,
  } = usePersonalNotes();

  const attachmentsBlockRef = useRef<HTMLDivElement>(null);

  const handleUploadFile = useCallback(
    async (file: File) => {
      if (!selectedNote?.parentId) {
        return '';
      }

      try {
        let loadedFileURL = '';
        switch (selectedNote?.type) {
          case 'EventNote':
            loadedFileURL = await eventsAPI.attachFileToNote(
              selectedNote.parentId as EventId,
              file,
            );
            break;
          case 'FounderNote':
            loadedFileURL = await foundersAPI.attachFileToNote(
              selectedNote.parentId,
              file,
            );
            break;
          case 'MentorReport':
            loadedFileURL = await mentorsAPI.attachFileToNote(
              selectedNote.parentId,
              file,
            );
            break;
          case 'VentureNote':
            loadedFileURL = await venturesAPI.attachFileToNote(
              selectedNote.parentId,
              file,
            );
            break;
          case 'GeneralNote':
          default:
            loadedFileURL = await filesAPI.attachFileToNote(
              selectedNote.parentId,
              file,
            );
            break;
        }
        return loadedFileURL;
      } catch (e: any) {
        return '';
      }
    },
    [selectedNote],
  );

  const handleFileUpload = useCallback(
    async (file: File) => {
      if (handleUploadFile && selectedNote) {
        try {
          setSaving(true);
          const fileIndex = attachments.length;
          const attachmentFromFile = getAttachmentFromFile(file);
          setAttachments((prevAttachments) => [
            ...prevAttachments,
            attachmentFromFile,
          ]);
          const fileURL = await handleUploadFile(file);

          if (!fileURL) {
            return;
          }

          const updatedNote = await updateNote({
            ...selectedNote,
            attachmentRefs: JSON.stringify([
              ...attachments,
              { ...attachmentFromFile, url: fileURL },
            ]),
          });

          if (!updatedNote) {
            return;
          }

          setSelectedNote(updatedNote);
          setAttachments((prevAttachments) => {
            return prevAttachments.map((prevAttach, prevAttachIndex) => {
              if (prevAttachIndex !== fileIndex) {
                return prevAttach;
              }
              return {
                ...prevAttach,
                url: fileURL,
              };
            });
          });
        } catch (e: any) {
        } finally {
          setSaving(false);
        }
      }
    },
    [
      handleUploadFile,
      selectedNote,
      setSaving,
      attachments,
      setAttachments,
      updateNote,
      setSelectedNote,
    ],
  );

  const handleFileRemove = useCallback(
    async (index: number) => {
      if (!selectedNote) {
        return;
      }

      const filteredAttachments = attachments.filter(
        (_, attachIndex) => attachIndex !== index,
      );

      setAttachments(filteredAttachments);
      const updatedNote = await updateNote({
        ...selectedNote,
        attachmentRefs: JSON.stringify(filteredAttachments),
      });
      updatedNote && setSelectedNote(updatedNote);
    },
    [attachments, selectedNote, setAttachments, setSelectedNote, updateNote],
  );

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  return (
    <div
      key={selectedNote?.parentId}
      ref={attachmentsBlockRef}
      className={cn(classes.attachmentsBlock, {
        [classes.attachmentsBlockOpen]: showAttachments,
      })}>
      <div className={classes.attachmentsActions}>
        <AttachFilesButton onChange={handleSelectFile} text='Attach Files' />
        <IconButton
          className={classes.closeAttachmentsBtn}
          onClick={toggleAttachments}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={classes.attachmentsList}>
        {!!attachments.length ? (
          attachments.map((attachment, attachIndex) => (
            <div key={attachIndex} className={classes.attachment}>
              <AttachmentCard
                attachment={attachment}
                loading={!attachment.url}
                onRemove={() => handleFileRemove(attachIndex)}
              />
            </div>
          ))
        ) : (
          <div className={classes.emptyState}>
            <Text variant='normal' className={classes.emptyMessage}>
              You do not have any attachments for this note yet, feel free to
              add them by clicking on "Attach Files" button above
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}

export default PersonalNotesAttachments;
