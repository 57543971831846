import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import { SvgIconComponent } from '@material-ui/icons';
import BusinessIcon from '@material-ui/icons/Business';
import DashboardIcon from '@material-ui/icons/Dashboard';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EventIcon from '@material-ui/icons/Event';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import NoteIcon from '@material-ui/icons/Note';
import PeopleIcon from '@material-ui/icons/People';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import cn from 'classnames';
import { useContext, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tenant } from '../../api/auth';
import { KnownModule } from '../../api/user/Module';
import { Role } from '../../api/user/Role';
import { LightSvg } from '../../assets/icons';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { UserContext } from '../../contexts/user-context';
import { Pages } from '../../router/constants';
import { COLORS, MONTSERRAT_FONT_FAMILY } from '../../theme/variables';
import { CLASS_TRACKING } from '../../utils/tracking_class';
import { Logo } from '../header/logo';

interface MenuItemType {
  label: string;
  icon:
    | SvgIconComponent
    | React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  link: string;
  module?: string;
}

interface ContentRendererProps {
  handleOpen: () => any;
}

interface MenuItemProps {
  className: string;
  label: string;
  icon: React.ReactNode;
  link: string;
}

interface Props {
  user: Tenant;
  contentRenderer: (props: ContentRendererProps) => React.ReactNode;
}

const MODULES_ITEMS: MenuItemType[] = [
  {
    label: 'Program Materials',
    icon: QuestionAnswerIcon,
    link: Pages.EDUCATION_LIST,
    module: 'education',
  },
  {
    label: 'Reports',
    icon: EqualizerIcon,
    link: Pages.REPORTS_LIST,
    module: 'analytics',
  },
];

const useStyles = makeStyles((theme) => ({
  menuItem: {
    width: 320,
    '& a': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '14px',
      fontWeight: 500,
      fontFamily: MONTSERRAT_FONT_FAMILY,
      padding: '16px 8px 16px 16px',
      color: COLORS.COLOR_TEXT_LIGHTENED_10,
      borderRadius: '8px',
      textDecoration: 'none',

      '&:hover': {
        background: COLORS.COLOR_GRAY_LIGHTENED_45,
      },

      '& svg': {
        marginRight: 10,
      },
    },
  },
  isActive: {
    '& a, & a:hover': {
      background: COLORS.COLOR_BLUE_LIGHTENED_45,
      color: COLORS.COLOR_BLUE_DARKENED_10,
    },
    '& $icon': {
      color: COLORS.COLOR_BLUE_DARKENED_10,
    },
  },

  modules: {
    padding: '14px 0',
    color: COLORS.COLOR_TEXT_LIGHTENED_20,
    textTransform: 'uppercase',
    marginTop: 16,
    marginLeft: 10,
    fontSize: '10px',
    fontWeight: 500,
    textAlign: 'left',
  },
  icon: {
    color: COLORS.COLOR_GRAY_BASE,
  },
  logo: {
    padding: '20px 10px',
    boxShadow:
      '0px 0px 2px rgb(34 91 187 / 20%), 0px 0px 8px rgb(51 126 255 / 20%);',
  },
  itemLink: {
    padding: '0 10px',
  },
  itemsLink: {
    marginTop: 25,
  },
}));

function MobileMenu({ contentRenderer, user }: Props) {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();
  const { logo, hasAccessToAction, hasModules, hasRole, hasAccessToModal } =
    useContext(UserContext);
  const rootURL = location.pathname.split('/')[1];
  const isActivePage = (pageURL: string) => {
    const rootPageURL = pageURL.split('/')[1];
    return rootPageURL.includes(rootURL);
  };

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleOpen = () => {
    setOpenMenu(true);
  };

  const MENU_ITEMS: MenuItemType[] = useMemo(() => {
    return [
      {
        label: 'Dashboard',
        icon: DashboardIcon,
        link: Pages.DASHBOARD,
      },
      {
        label: 'Dashboard',
        icon: DashboardIcon,
        link: Pages.FOUNDER_DASHBOARD,
        visible: hasRole(Role.Founder),
      },
      {
        label: 'Dashboard',
        icon: DashboardIcon,
        link: Pages.MENTOR_DASHBOARD,
        visible: hasRole(Role.Mentor),
      },
      {
        label: rb('mentors-u'),
        icon: PeopleIcon,
        link: Pages.ACTIVE_MENTORS,
      },
      {
        label: 'Ventures',
        icon: BusinessIcon,
        link: hasAccessToAction('venture.filters')
          ? hasRole(Role.Mentor)
            ? `${Pages.VENTURES}?mentorFilter=my-ventures`
            : Pages.ACTIVE_VENTURES
          : Pages.VENTURES,
      },
      {
        label: 'Founders',
        icon: LightSvg,
        link: hasAccessToAction('founder.filters')
          ? Pages.ACTIVE_FOUNDERS
          : Pages.FOUNDERS,
        visible: hasAccessToAction('founder.view'),
      },
      {
        label: 'Sessions',
        icon: EventAvailableIcon,
        link: Pages.SESSIONS,
      },
      {
        label: 'Goals',
        icon: TrendingUpIcon,
        link: Pages.GOALS,
        visible:
          hasAccessToAction('goal.view') && hasAccessToModal(KnownModule.Goals),
      },
      {
        id: 'personalNotes',
        label: 'Notes',
        icon: NoteIcon,
        link: Pages.PERSONAL_NOTES,
        visible: hasAccessToAction('personalNotes.view'),
      },
      {
        label: 'Gatherings',
        icon: EventIcon,
        link: Pages.GATHERINGS,
        visible: hasRole(Role.Mentor) && hasAccessToAction('gathering.view'),
      },
    ].filter((v) => v.visible === undefined || v.visible);
  }, [hasRole, rb, hasAccessToAction, hasAccessToModal]);

  const menuItems = useMemo(() => {
    const items = MENU_ITEMS;
    if (!hasAccessToAction('dashboard.view')) {
      delete items[0];
    }
    return items;
  }, [hasAccessToAction, MENU_ITEMS]);

  const modulesItems = useMemo(() => {
    const items: MenuItemType[] = [];
    if (hasAccessToAction('modules.programMaterials.view')) {
      items.push(MODULES_ITEMS[0]);
    }
    if (hasAccessToAction('modules.reports.view')) {
      items.push(MODULES_ITEMS[1]);
    }
    return items;
  }, [hasAccessToAction]);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setOpenMenu(open);
  };

  function MenuItem({ className, label, icon, link }: MenuItemProps) {
    return (
      <li
        className={cn(classes.itemLink, className)}
        data-testid={`menu-item-${label
          .toLocaleLowerCase()
          .replace(/\s/g, '-')}`}>
        <Link to={link}>
          {icon}
          <span>{label}</span>
        </Link>
      </li>
    );
  }

  return (
    <>
      {contentRenderer({ handleOpen })}
      <Drawer anchor={'left'} open={openMenu} onClose={toggleDrawer(false)}>
        <div className={classes.logo}>
          <Logo mobile user={user} logo={logo} />
        </div>
        <ul className={cn(classes.itemsLink)}>
          {menuItems.map(({ label, icon: IconComponent, link }) => (
            <MenuItem
              key={label}
              className={cn(
                classes.menuItem,
                CLASS_TRACKING.SIDE_NAVIGATION_CLASS,
                {
                  [classes.isActive]: isActivePage(link),
                },
              )}
              label={label}
              icon={<IconComponent className={classes.icon} />}
              link={link}
            />
          ))}
        </ul>
        {hasModules && !!modulesItems.length && (
          <>
            <div className={classes.modules}>Modules</div>
            <ul>
              {modulesItems.map(({ label, icon: IconComponent, link }) => (
                <MenuItem
                  key={label}
                  className={cn(classes.menuItem, {
                    [classes.isActive]: isActivePage(link),
                  })}
                  label={label}
                  icon={<IconComponent className={classes.icon} />}
                  link={link}
                />
              ))}
            </ul>
          </>
        )}
      </Drawer>
    </>
  );
}

export default MobileMenu;
