import { CircularProgress, makeStyles } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import eventsAPI, {
  EventFullDetails,
  EventId,
  EventResponseForm,
} from '../../api/events';
import { Role } from '../../api/user/Role';
import venturesAPI from '../../api/ventures';
import { SnackMessage, Text } from '../../components/common';
import WarningBeforeStart from '../../components/common/Confirmation/WarningBeforeStart';
import ReportMentorAssessmentForm, {
  FormInitialValues,
  FormValues,
} from '../../components/forms/report-mentor-assessment-form';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { SessionContext } from '../../contexts/session-context';
import { UserContext } from '../../contexts/user-context';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';

enum MentorAssessmentStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  NO_MENTORS = 'NO_MENTORS',
}

const useStyles = makeStyles(() => ({
  formContainer: {
    width: '560px',
    maxWidth: '100%',
  },
  stateContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  block: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    width: 300,
    maxWidth: '100%',
    padding: '32px',
    boxSizing: 'border-box',
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  errorText: {
    textAlign: 'center',
  },
}));

const formatValuesRequest = (
  values: FormValues,
  fullDetails: EventFullDetails,
): EventResponseForm => {
  return {
    tenantId: fullDetails.event.tenantId,
    eventId: fullDetails.event.id,
    ventureId: fullDetails.event.ventureId,
    sessionRating: +values.sessionRating,
    ventureProgressRating: +values.ventureProgressRating,
    attendingMentors: [],
    attendingFounders: [],
    mostImpactfulMentor: values?.mostImpactfulMentor || '',
    submitterEmail: values.submitterEmail,
    mentorReport: values.notes || '',
    mentorReportAttachments: values.attachments || '',
    mentorReportNotes: '',
    founderNotes: '',
    founderOtherNotes: '',
    founderNotesAttachments: '',
    agendaQuestion1: '',
    agendaQuestion2: '',
    agendaQuestion3: '',
    agendaQuestion4: '',
    agendaAttachments: '',
    isLeadMentorReport: false,
    nextEventStart: '',
    nextEventEnd: '',
    isMentorReport: true,
    isFounderReport: false,
    founderReport: false,
    agendaPresented: false,
  };
};

const getInitialValues = (
  fullDetails: EventFullDetails,
  submitterEmail?: string,
): FormInitialValues => {
  return {
    submitterEmail: submitterEmail || '',
    summary: fullDetails?.event.summary || '',
    start: fullDetails?.event.start || '',
    end: fullDetails?.event.end || '',
    sessionRating: 0,
    ventureProgressRating: 0,
    notes: '',
    attachments: [],
    mostImpactfulMentor: '',
  };
};

const errorMessage =
  'Unfortunately an error occurred while loading the report. Please try again in a couple of minutes. If the problem persists please share the URL with the support team at support@tractionfive.com';

function SessionMentorAssessmentPage() {
  const classes = useStyles();
  const history = useHistory();
  const { rb } = useResourceBundles();
  const { sessionId } = useParams<{ sessionId: EventId }>();
  const { user, tokenData, hasRole } = useContext(UserContext);
  const { sessionKey } = useContext(SessionContext);

  const [fullDetails, setFullDetails] = useState<EventFullDetails>();
  const [statusForm, setStatusForm] = useState<MentorAssessmentStatus>(
    MentorAssessmentStatus.LOADING,
  );
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorVaildDate, setErrorVaildDate] = useState(false);

  const initialValues = useMemo(() => {
    if (fullDetails) {
      const submitterEmail = hasRole(Role.Mentor) ? tokenData?.email ?? '' : '';
      return getInitialValues(fullDetails, submitterEmail);
    }
    return undefined;
  }, [fullDetails, hasRole, tokenData?.email]);

  const optionsMentor = useMemo(() => {
    return fullDetails?.mentorList && fullDetails?.mentorList?.length > 0
      ? fullDetails?.mentorList.map((mentor) => {
          return {
            value: mentor.id,
            label: `${mentor.firstName} ${mentor.lastName}`,
          };
        })
      : [];
  }, [fullDetails?.mentorList]);

  const emailOptions = useMemo(() => {
    return (hasRole(Role.Admin) || hasRole(Role.Manager)) &&
      fullDetails?.mentorList &&
      fullDetails?.mentorList?.length > 0
      ? fullDetails?.mentorList.map((mentor) => {
          return {
            value: mentor.email,
            label: [
              mentor.firstName,
              mentor.lastName,
              `(${mentor.email})`,
            ].join(' '),
          };
        })
      : undefined;
  }, [fullDetails?.mentorList, hasRole]);

  const handleSubmit = async (values: FormValues) => {
    const valuesRequest = formatValuesRequest(
      values,
      fullDetails as EventFullDetails,
    );

    try {
      setIsLoadingSaveForm(true);

      await eventsAPI.eventsResponseForm(valuesRequest);
      setErrorVaildDate(false);
      enqueueSnackbar(`${rb('mentors-u')} Assessment submitted successfully`, {
        variant: 'success',
      });
      history.push(
        getRoutePath(Pages.SESSIONS_DETAILS, {
          sessionId,
        }),
      );
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';
      enqueueSnackbar('An error occurred while saving your report', {
        variant: 'error',
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        if (fullDetails?.event.ventureId) {
          const loadedFileURL = await venturesAPI.attachFileToReport(
            fullDetails.event.ventureId,
            fullDetails.event.tenantId,
            file,
          );
          return loadedFileURL;
        }
      } catch (e: any) {
        return '';
      }
    },
    [fullDetails],
  );

  useEffect(() => {
    const loadDetails = async (key: string) => {
      try {
        setStatusForm(MentorAssessmentStatus.LOADING);
        const responseDetails = await eventsAPI.getEventDetails(key);
        setFullDetails(responseDetails);

        if (!responseDetails.mentorList?.length) {
          setStatusForm(MentorAssessmentStatus.NO_MENTORS);
        } else {
          setStatusForm(MentorAssessmentStatus.LOADED);
        }
      } catch (e: any) {
        enqueueSnackbar(errorMessage, {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage:
                e.response?.data?.message || 'Internal server error',
            }),
          variant: 'error',
        });
      }
    };

    if (sessionKey) {
      loadDetails(sessionKey);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionKey]);

  if (statusForm === MentorAssessmentStatus.LOADING || !fullDetails) {
    return (
      <div className={classes.stateContainer}>
        <CircularProgress size={54} color='primary' />
      </div>
    );
  }

  if (statusForm === MentorAssessmentStatus.NO_MENTORS) {
    return (
      <div
        data-testid={`report-page-${statusForm}`}
        className={classes.stateContainer}>
        <div className={classes.block}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.errorText}>
            <Text variant='normal'>
              You cannot submit {rb('mentors-u')} Assessment & Notes for this
              session since no {rb('mentors-u')} are assigned.
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <WarningBeforeStart date={fullDetails.event.start} />
      <div className={classes.formContainer}>
        <ReportMentorAssessmentForm
          initialValues={initialValues}
          fullDetails={fullDetails}
          onSubmit={handleSubmit}
          loading={isLoadingSaveForm}
          errorVaildDate={errorVaildDate}
          onUploadFile={handleUploadFile}
          optionsMentor={optionsMentor}
          leadingMentor={!!user?.mostImpactfulMentorEnabled}
          emailOptions={emailOptions}
          disabledEmail={!!initialValues?.submitterEmail}
          isStickySubmit
          hasBeforeUnload
        />
      </div>
    </>
  );
}

export default SessionMentorAssessmentPage;
