import {
  makeStyles,
  IconButton,
  Tooltip,
  withStyles,
  Link as MaterialLink,
} from '@material-ui/core';
import ListAltIcon from '@material-ui/icons/ListAlt';
import DOMPurify from 'dompurify';
import { SyntheticEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { Event } from '../../api/events';
import { getRoutePath, Pages } from '../../router/constants';
import { COLORS } from '../../theme/variables';
import { createMarkup } from '../../utils/functions';
import { Link, Text } from '../common';
import NoIcon from '../common/no-icon';

interface AgendaIconProps {
  session: Event;
}

const STooltip = withStyles(() => ({
  tooltip: {
    width: 300,
    padding: 16,
    backgroundColor: COLORS.COLOR_ADDITIONAL_WHITE,
    boxShadow:
      '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
  },
}))(Tooltip);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bodyText: {
    color: COLORS.COLOR_TEXT_BASE,
    display: '-webkit-box',
    WebkitLineClamp: 12,
    WebkitBoxOrient: 'vertical',
    textAlign: 'left',
    wordBreak: 'break-word',
    overflow: 'hidden',

    '& ul, ol, li, b, i, u, h1, h2, pre': {
      margin: 'revert',
      padding: 'revert',
      fontWeight: 'revert',
      fontStyle: 'revert',
      listStyle: 'revert',
      border: 'revert',
      fontSize: 'revert',
      font: 'revert',
      verticalAlign: 'revert',
      lineHeight: 'normal !important',
    },
  },
}));

function AgendaTooltip({ session }: AgendaIconProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {!session.description ? (
        <Text variant='normal'>The report is not submitted.</Text>
      ) : (
        <Text variant='normal'>
          <div
            className={classes.bodyText}
            dangerouslySetInnerHTML={createMarkup(
              DOMPurify.sanitize(session.description),
            )}
          />
        </Text>
      )}
    </div>
  );
}

function AgendaIcon({ session }: AgendaIconProps) {
  const location = useLocation();

  if (!session.description || !session.agendaReceived) {
    return <NoIcon />;
  }

  return (
    <STooltip title={<AgendaTooltip session={session} />}>
      <MaterialLink
        data-testid='venture-table-name'
        onClick={(e: SyntheticEvent<any>) => e.stopPropagation()}
        component={Link}
        to={{
          pathname: getRoutePath(Pages.SESSIONS_DETAILS, {
            sessionId: session.id,
          }),
          search: 'anchor=description',
          state: {
            prevPath: `${location.pathname}${location.search}`,
          },
        }}>
        <IconButton data-testid='founder-notes-icon'>
          <ListAltIcon />
        </IconButton>
      </MaterialLink>
    </STooltip>
  );
}

export default AgendaIcon;
