import {
  IconButton,
  InputAdornment,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Button as MaterialButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import cn from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { PageLoader, Text, TextField } from '../../components/common';
import PersonalNoteCard from '../../components/common/PersonalNotes/note-card';
import {
  PersonalNote,
  usePersonalNotes,
} from '../../contexts/personal-notes-context';
import { UserContext } from '../../contexts/user-context';
import { COLORS } from '../../theme/variables';
import { getFormattedFullDay } from '../../utils/date';
import { CLASS_TRACKING } from '../../utils/tracking_class';

interface NotesListProps {
  hidden?: boolean;
}

const useStyles = makeStyles((theme) => ({
  cardBlock: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(100dvh - 160px)',
    borderRight: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,

    [theme.breakpoints.down('xs')]: {
      height: 'calc(100dvh - 66px)',
      maxHeight: 'calc(100dvh - 66px)',
    },
  },
  cardTopSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 20px 0 20px',
    gap: 10,

    '& > button': {
      minWidth: 'min-content',
    },
  },
  cardList: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    // maxHeight: 'calc(100dvh - 325px)',
    padding: '0 0 20px',
    overflow: 'auto',

    '&::-webkit-scrollbar-track': {
      marginTop: '62px',
      borderTop: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
    },
  },
  addNoteBtn: {
    padding: '6.5px 16px',
  },
  closeBtnWrapper: {
    position: 'relative',
    width: '100%',
  },
  closeBtn: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
  cardGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
  searchInput: {
    '& .MuiOutlinedInput-input': {
      paddingRight: '36px',
    },
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  emptyMessage: {
    padding: 20,
    textAlign: 'center',
  },
  groupTitle: {
    position: 'sticky',
    top: 0,
    paddingTop: 20,
    paddingLeft: 30,
    paddingBottom: 10,
    margin: 0,
    backgroundColor: '#fff',
    borderBottom: `1px solid ${COLORS.COLOR_GRAY_LIGHTENED_30}`,
  },
  hidden: {
    display: 'none',
  },
}));

const groupNotesByMonth = (notes: PersonalNote[]) => {
  const groupedNotes: {
    name: string;
    notes: PersonalNote[];
  }[] = [];
  const sortedNotes = notes.sort(
    (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime(),
  );
  sortedNotes.forEach((note) => {
    const d = new Date(note.date);
    const date = getFormattedFullDay(d);
    const foundGroup = groupedNotes.find((group) => group.name === date);
    if (foundGroup) {
      foundGroup.notes.push(note);
    } else {
      groupedNotes.push({ name: date, notes: [note] });
    }
  });
  return groupedNotes;
};

const getEncodedSearchValue = (search: string): string => {
  // remove http(s):// part from the string
  const filteredSearch = search
    .replace(/https?:\/\/[^ ]+/g, '')
    .replace(/[^a-zA-Z0-9\s\-_.!~*'(),@?]/g, '');
  const encodedValue = encodeURIComponent(filteredSearch);
  return encodedValue;
};

function NotesList({ hidden }: NotesListProps) {
  const classes = useStyles();
  const { hasAccessToAction } = useContext(UserContext);
  const {
    notes,
    selectedNote,
    loading,
    searchFilter,
    searchValue,
    setSearchValue,
    fetchNextPage,
    setSearchFilter,
    resetSearch,
    handleAddNewNote,
    handleCardSelect,
  } = usePersonalNotes();

  const cardListRef = useRef<HTMLDivElement>(null);
  const debouncedTimer = useRef<number>();

  const groupNotes = useMemo(() => groupNotesByMonth(notes), [notes]);

  const handleSearch = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearTimeout(debouncedTimer.current);
      setSearchValue(e.target.value);

      debouncedTimer.current = setTimeout(() => {
        if (e.target.value.trim().length > 2) {
          const encodedValue = getEncodedSearchValue(e.target.value);
          setSearchFilter(encodedValue);
        } else {
          setSearchFilter(undefined);
        }
      }, 800) as unknown as number;
    },
    [setSearchFilter, setSearchValue],
  );

  useEffect(() => {
    const onScroll = (event: Event) => {
      const target = event.target as HTMLDivElement;
      if (
        target &&
        target.scrollHeight - target.scrollTop <= target.clientHeight + 2000
      ) {
        fetchNextPage();
      }
    };

    const currentRef = cardListRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', onScroll);

      return () => {
        currentRef.removeEventListener('scroll', onScroll);
      };
    }
  }, [fetchNextPage, loading]);

  return (
    <div className={cn(classes.cardBlock, { [classes.hidden]: hidden })}>
      <div className={classes.cardTopSection}>
        {hasAccessToAction('personalNotes.create') ? (
          <MaterialButton
            className={cn(CLASS_TRACKING.ENTITY_ACTION, classes.addNoteBtn)}
            data-testid='button-new-personal-note'
            startIcon={<AddIcon />}
            variant='contained'
            onClick={handleAddNewNote}
            disabled={loading}
            color='primary'>
            Note
          </MaterialButton>
        ) : null}
        <div className={classes.closeBtnWrapper}>
          <TextField
            className={cn(classes.searchInput, CLASS_TRACKING.FILTER_ELEMENT)}
            value={searchValue}
            onChange={handleSearch}
            placeholder='Search'
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            small
          />
          <IconButton
            className={classes.closeBtn}
            size='small'
            onClick={resetSearch}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
      <div className={classes.cardList} ref={cardListRef} id='cardList'>
        {groupNotes.map((group, groupIdx) => (
          <div key={groupIdx} className={classes.cardGroup}>
            <Typography variant='h4' className={classes.groupTitle}>
              {group.name}
            </Typography>
            {group.notes.map((note, idx) => (
              <PersonalNoteCard
                key={idx}
                note={note}
                selected={selectedNote?.parentId === note.parentId}
                onClick={() => handleCardSelect(note)}
              />
            ))}
          </div>
        ))}
        {loading && <PageLoader />}
        {!loading && !notes.length && (
          <div className={classes.emptyState}>
            <Text variant='normal' className={classes.emptyMessage}>
              {searchFilter
                ? 'We were not able to find any notes with a keyword provided. Please adjust your search prompt and try again.'
                : 'We do not have any notes here yet. Please feel free to create a new one!'}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
}

export default NotesList;
