import { CircularProgress, makeStyles } from '@material-ui/core';
import { Button as MaterialButton, Paper } from '@material-ui/core';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import { FC, useCallback, useEffect } from 'react';
import { BeforeUnload } from '../components/common';
import BeforeChange from '../components/common/before-change';
import BaseLayout from '../components/layout/base-layout';
import NoteEditor from '../components/notes/note-editor';
import NotesList from '../components/notes/notes-list';
import {
  PersonalNotesProvider,
  usePersonalNotes,
} from '../contexts/personal-notes-context';
import { ProtectedRouteProps } from '../router/type';
import { COLORS } from '../theme/variables';
import { isMobile } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    padding: 0,
    overflow: 'hidden',

    '& * ::-webkit-scrollbar': {
      width: 12,
    },

    '& * ::-webkit-scrollbar-thumb': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_30,

      '&:hover': {
        backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_20,
      },
    },

    '& * ::-webkit-scrollbar-track': {
      backgroundColor: COLORS.COLOR_GRAY_LIGHTENED_45,
    },
  },
  container: {
    display: 'grid',
    gridTemplateColumns: '340px 1fr',
    height: '100%',
  },
  noteBlock: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    maxHeight: 'calc(100dvh - 160px)',

    [theme.breakpoints.down('xs')]: {
      height: 'calc(100dvh - 66px)',
      maxHeight: 'calc(100dvh - 66px)',
    },
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  nonSelectedIcon: {
    fontSize: 80,
    color: COLORS.COLOR_GRAY_LIGHTENED_30,
  },
}));

function PersonalNotesPage({ user }: ProtectedRouteProps) {
  const classes = useStyles();
  const {
    selectedNote,
    loading,
    saving,
    deleting,
    isDirty,
    beforeUnloadState,
    setBeforeUnloadState,
    setSelectedNote,
    addNewNote,
    handleSaveNote,
  } = usePersonalNotes();

  const confirmButtonRenderer = useCallback(
    ({ onConfirm }: { onConfirm: () => any }) => (
      <MaterialButton
        variant='outlined'
        onClick={async () => {
          await handleSaveNote();
          onConfirm();
        }}
        disabled={saving}>
        {loading ? (
          <CircularProgress size={24} color='inherit' />
        ) : (
          'Save the changes'
        )}
      </MaterialButton>
    ),
    [handleSaveNote, loading, saving],
  );

  useEffect(() => {
    if (isMobile()) {
      const body = document.querySelector('body');
      const baseLayout = document.getElementById('base-layout');

      if (body) {
        body.style.height = '100dvh';
      }

      if (baseLayout) {
        baseLayout.style.height = '100dvh';
      }
    }
  }, []);

  return (
    <BaseLayout user={user} hiddenFooter={isMobile()}>
      <Paper data-testid='notes-paper' className={classes.paper}>
        <BeforeUnload
          when={isDirty && !(saving || deleting)}
          title={'Leave the page'}
          body={
            'You are about to leave the page, all unsaved changes will be lost. Do you want to continue?'
          }
          disabled={saving || deleting}
          confirmButtonRenderer={confirmButtonRenderer}
        />
        <BeforeChange
          when={isDirty && !(saving || deleting)}
          title={'Leave the note'}
          body={
            'You are about to leave the note, all unsaved changes will be lost. Do you want to continue?'
          }
          disabled={saving || deleting}
          confirmButtonRenderer={confirmButtonRenderer}
          active={!!beforeUnloadState}
          change={() => {
            if (beforeUnloadState?.action === 'add') {
              setBeforeUnloadState(null);
              addNewNote();
            } else {
              setSelectedNote(beforeUnloadState?.note || null);
              setBeforeUnloadState(null);
            }
          }}
          cancel={() => {
            setBeforeUnloadState(null);
          }}
        />
        {isMobile() ? (
          <>
            <NotesList hidden={!!selectedNote} />
            {!!selectedNote && (
              <div className={classes.noteBlock}>
                <NoteEditor />
              </div>
            )}
          </>
        ) : (
          <div className={classes.container}>
            <NotesList />
            <div className={classes.noteBlock}>
              {selectedNote ? (
                <NoteEditor />
              ) : (
                <div className={classes.emptyState}>
                  <SpeakerNotesOffIcon className={classes.nonSelectedIcon} />
                </div>
              )}
            </div>
          </div>
        )}
      </Paper>
    </BaseLayout>
  );
}

function withPersonalNotesProvider(Component: FC<ProtectedRouteProps>) {
  return (props: ProtectedRouteProps) => (
    <PersonalNotesProvider user={props.user}>
      <Component {...props} />
    </PersonalNotesProvider>
  );
}

export default withPersonalNotesProvider(PersonalNotesPage);
